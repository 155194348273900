<div class="menu-container d-print-none">
  <div class="menu-header">
    <img class="mr-3" height="40" src="assets/images/logo-white-yellow.png" alt="logo"
      [ngClass]="isCollapsed ? 'none' : ''" *ngIf="!isCollapsed" />
    <div class="menu-action" (click)="toggleCollapsed()">
      <i class="fas fa-bars color-white"></i>
    </div>
  </div>
  <div class="menu-list">
    <commons-layout-menu-item *ngFor="let item of items" [item]="item" [small]="isCollapsed">
    </commons-layout-menu-item>

  </div>
  <div style="display: flex; justify-content: end; color: white; font-size: 13px;">{{version}}</div>
</div>