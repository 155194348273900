/**
 * This is a development environment with custom feature flags and api keys
 * that should allow the developer to develop the project.
 *
 * Please make sure that your changes keep all the environments compatible.
 */

import { Environment } from '@commons/models/environment.model';
import { FEATURE_FLAGS_ES } from './feature-flags.es';

export const environment: Environment = {
  production: false,
  paypalClientId:
    'AVYe789uaMT3vDzlIi3cu4jRlvgIptaB6OA9h-xpMSL4VSg704JCpNAr1y_wNMZ-RMnQwSLdgrIM-Zjy',
  featureFlags: FEATURE_FLAGS_ES,
  // zendeskToken: '2RoqCybAFCculvPNZFORD6EwzedbkVwDqadekCN6',
  // zendeskBaseUrl: 'https://garagecoders3190.zendesk.com/api/v2',
  zendeskToken: 'FKS8qtmbA4bO7ym4cLx0Le9krfjXrbWoH8Z2SToC',
  zendeskBaseUrl: 'https://Speeditnacionalhelp.zendesk.com/api/v2',
  // baseUrl: 'https://garagecoders.rocks:3001'
  baseUrl: ''
};
