import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { StepperService } from '@commons/services/index';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { NewOrderService } from './new-order.service';
import {
  NewOrderLanguages,
  NewOrderTranslation,
} from './new-order.translation';

@Component({
  selector: 'commons-new-order',
  templateUrl: './new-order.component.html',
  styleUrls: ['./new-order.component.scss', './form-container.scss'],
})
export class NewOrderComponent implements OnInit, OnDestroy {
  public translation: NewOrderTranslation = NewOrderLanguages[this.language];

  public currentSectionIndex = 0;

  private destroyed$ = new Subject<void>();

  private stepMap: { [key: string]: string } = {
    address: 'package',
    package: 'rate',
    rate: 'completed',
    completed: '../',
  };

  constructor(
    @Inject('LANGUAGE')
    private readonly language: keyof typeof NewOrderLanguages,
    private readonly newOrderService: NewOrderService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly stepperService: StepperService,
  ) {}

  ngOnInit(): void {
    const { duplicate, id, steptwo, items } = this.route.snapshot.queryParams;
    
    if (this.router.url.includes('rate') && duplicate && id !== undefined) {
      this.newOrderService.isDuplicate = true;
      this.newOrderService.duplicateId = Number(id);
      this.stepperService.init(this.stepMap, 'rate');
    } else if(steptwo && id !== undefined) {
      localStorage.setItem('id', id);
      this.newOrderService.contents = items;
      this.newOrderService.isDuplicate = false;
      this.stepperService.init(this.stepMap, 'package');
    } else {
      if (!this.router.url.includes('address')) {
        this.router.navigateByUrl('/orders/new/address');
      }
      this.newOrderService.isDuplicate = false;
      this.stepperService.init(this.stepMap, 'address');
    }

    this.stepperService.currentStep.subscribe((step) => {
      this.router.navigate([step], {
        relativeTo: this.route.parent,
      });
    });

    this.setCurrentSectionIndex(this.router.url);

    this.newOrderService.goToNextStep$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.stepperService.goToNextStep());

    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe(({ url }: NavigationEnd) => {
        this.setCurrentSectionIndex(url);
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.newOrderService.wipeData();
  }

  private setCurrentSectionIndex(url: string): void {
    this.currentSectionIndex = [
      'address',
      'package',
      'rate',
      'completed',
    ].indexOf(url.split('/').reverse()[0] as AvailableSections);
  }

  public saveAndContinue(): void {
    this.newOrderService.clickButton();
  }
}

type AvailableSections = 'address' | 'package' | 'rate' | 'completed';
