<div class="auth-container">
  <div class="auth-container-header">
    <img class="auth-logo" height="70" src="assets/images/logo-white.png" alt="logo" />
    <div class="flex flex-column align-items-center">
      <div class="mb-2 color-white font-weight-500 font-size-3">
        {{ translation.countrySwitcher.title }}
      </div>
      <a [href]="countrySwitcherURL" class="border-radius-10">
        <button role="button" mat-button class="btn secondary border-radius-10 wide website-switcher">
          {{ translation.countrySwitcher.button }}
        </button>
      </a>
    </div>
  </div>
  <div class="width-full flex justify-content-center align-items-center auth-container-content">
    <ng-content></ng-content>
  </div>
  <div class="auth-container-footer">
    <div
      style="font-size: 13px; color: white; display: flex; justify-content: end; align-items: baseline; padding-top: 100px; padding-right: 30px;">
      {{version}}
    </div>
  </div>
</div>