import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PickUpAvailabilityChecked } from '@commons/models/index';
import { AccountDetailsService } from '@commons/services/index';
import { SnotifyService } from 'ng-snotify';
import { Subscription } from 'rxjs';
import {
  CommonPickupsLanguages,
  CommonPickupsTranslation,
} from '../common-pickups.translation';

@Component({
  selector: 'commons-new-pickup',
  templateUrl: './new-pickup.component.html',
  styleUrls: ['./new-pickup.component.scss'],
})
export class NewPickupComponent implements OnInit, OnDestroy {
  public readonly translation: CommonPickupsTranslation =
    CommonPickupsLanguages[this.language];

  public pickupAvailability: PickUpAvailabilityChecked;

  public availableCouriers: string[] = [];

  private detailsSubscription = Subscription.EMPTY;

  constructor(
    @Inject('LANGUAGE')
    private readonly language: keyof typeof CommonPickupsLanguages,
    private readonly accountDetailsService: AccountDetailsService,
    private readonly router: Router,
    private readonly snotifyService: SnotifyService,
  ) {}

  ngOnInit(): void {
    
    
    this.detailsSubscription = this.accountDetailsService
      .get()
      .subscribe(({ settings }) => {
        if (!settings) {
          this.availableCouriers = [];
        } else {
          const { available_carriers = [] } = settings;
          this.availableCouriers = available_carriers;
        }
      });
  }

  ngOnDestroy(): void {
    this.detailsSubscription.unsubscribe();
  }

  public onVerified(checkedData: PickUpAvailabilityChecked): void {
   
    console.log(checkedData);
    
    if (checkedData === null || checkedData === undefined) {
      this.snotifyService.error(this.translation.notifications.error);
    }
    if (checkedData.error) {
      this.snotifyService.error(checkedData.error);
    } else {
      console.log('qui');
      checkedData.constrains
      const aux = {
        
        message: 'string',
        constrains: {
          at_hours: 0,
          at_minutes: 0,
          generic_address: { zip: +localStorage.getItem('zip'), admin_level_0: 'MX' },
          lower_date: 'string',
          lower_time: 'string',
          provider: localStorage.getItem('provider'),
          upper_date: 'string',
          upper_time: 'string',
  }

      }
      this.pickupAvailability = aux;
      // console.log(this.pickupAvailability);
    }
  }

  public goBack(): void {
    this.router.navigateByUrl('/pickups');
  }
}
