import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FeatureFlags } from '@commons/models/environment.model';
import { AccountDetailsService } from '@commons/services/account-details.service';
import { SnotifyService } from 'ng-snotify';
import { ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CreditsService } from './credits.service';
import { CreditsLanguages, CreditsTranslation } from './credits.translation';
import { PAYPAL_CONFIG } from './paypal.config';

@Component({
  selector: 'commons-credits',
  templateUrl: './credits.component.html',
  styleUrls: ['./credits.component.scss'],
})
export class CreditsComponent implements OnInit, OnDestroy {
  @Output() public closeModal = new EventEmitter<void>();

  private destroyed$ = new Subject<void>();

  public readonly form: FormGroup = this.fb.group({
    amount: [500, [Validators.required, Validators.min(500)]],
  });

  public paymentMethod: 'bankDeposit' | 'paypal' | 'mercadoPago' | 'billpocket';
  public payPalConfig: IPayPalConfig;
  private moneyCurrency: string;

  public translation: CreditsTranslation = CreditsLanguages[this.language];
  public billpocketUrl: string;

  constructor(
    @Inject('LANGUAGE')
    private readonly language: keyof typeof CreditsLanguages,
    @Inject('FEATURE_FLAGS')
    private readonly featureFlags: FeatureFlags,
    private readonly fb: FormBuilder,
    private readonly creditsService: CreditsService,
    private readonly snotifyService: SnotifyService,
    private readonly accountDetailsService: AccountDetailsService,
  ) { }

  ngOnInit(): void {
    this.moneyCurrency = this.featureFlags.currency;

    this.initConfig();
    this.form.setValue({ amount: 500 });
    this.accountDetailsService
      .get()
      .subscribe((e) => (this.billpocketUrl = (e.settings as any).billpocket));
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  public async doMercadoPago(): Promise<void> {
    const msg = await this.creditsService.genMercadoPago(this.form.value.amount);
    console.log(msg);
    
    if (msg.url_mercadopago) {
      window.open(msg.url_mercadopago, '_blank');
    } else {
      this.snotifyService.error(msg);
    }
  }

  public print(): void {
    window.print();
  }

  private initConfig(): void {
    this.payPalConfig = {
      ...PAYPAL_CONFIG,
      // todo use env instead
      currency: this.featureFlags.currency,
      // transaction was approved, but not authorized
      onApprove: () => { },
      // transaction was approved and authorized. Successful flow
      onClientAuthorization: (data) => {
        // this.showSuccess = true;
        this.creditsService.addCredits(data.id).subscribe(() =>
          this.snotifyService.success(
            `Se han cargado $${(this.form.value
              .amount as number).toLocaleString([], {
                currency: this.moneyCurrency,
              })} a tu cuenta exitosamente.`,
          ),
        );
        this.closeModal.next();
      },
      onInit: (data, actions) => {
        try {
          this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            const values = this.form.value;
            // todo usage env
            if (values.amount >= 500) {
              actions.enable();
            } else {
              actions.disable();
            }
          });
        } catch (e) { }
      },
      // User cancelled the transaction
      onCancel: (data, actions) => { },
      // An error ocurred
      onError: (err) => { },
      // The PayPal button was clicked
      onClick: (data, actions) => { },
      createOrderOnClient: (data): ICreateOrderRequest => {
        return {
          intent: 'CAPTURE',
          purchase_units: [
            {
              amount: {
                currency_code: this.moneyCurrency,
                value: this.form.value.amount,
                breakdown: {
                  item_total: {
                    value: this.form.value.amount,
                    currency_code: this.moneyCurrency,
                  },
                },
              },
              items: [
                {
                  name: 'Créditos Speedit',
                  quantity: '1',
                  category: 'DIGITAL_GOODS',
                  unit_amount: {
                    currency_code: this.moneyCurrency,
                    value: this.form.value.amount,
                  },
                },
              ],
            },
          ],
          application_context: {
            shipping_preference: 'NO_SHIPPING',
          },
        };
      },
    };
  }
}
