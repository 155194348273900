import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class StepperService {
  public steps: { [key: string]: string } = {};
  public currentStep: BehaviorSubject<string> = new BehaviorSubject(undefined);

  constructor() {
    this.currentStep.subscribe();
  }

  public init(steps: { [key: string]: string }, initialStep: string): void {
    this.steps = steps;
    this.currentStep.next(initialStep);
  }

  public goToNextStep(): void {
    this.currentStep.pipe(first()).subscribe((step) => {
      this.currentStep.next(this.steps[step]);
    });
  }

  public setStep(step: string): void {
    this.currentStep.next(step);
  }
}
