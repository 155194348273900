import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { AuthService } from '@commons/modules/auth/auth.service';

// Using Omit will remove the 'error' property, which is typed as any
type HandleableError = Omit<HttpErrorResponse, 'error'> & {
  error: ErrorHandler;
};

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  constructor(private readonly authService: AuthService) {}

  public throw(error: HttpErrorResponse): void {
    this.handleError(error as HandleableError);
  }

  private handleError(error: HandleableError): void {
    if (error.status === 401) {
      // This will logout the user at the first 401. Maybe we should do an observable
      // so this gets triggered when 401 happens 2-3 times in a short period of time
      this.authService.logout();
    }
    console.error(error.error);
  }
}
