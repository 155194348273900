import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FeatureFlags } from '@commons/models/environment.model';
import { AuthUserInfomation } from '@commons/models/index';
import { AuthService } from '@commons/modules/auth/auth.service';
import { CreditsService } from '@commons/modules/credits';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccountDetailsService } from '../../../services/';
import {
  LayoutHeaderLanguages,
  LayoutHeaderTranslation,
} from './layout-header.translation';

@Component({
  selector: 'commons-layout-header',
  templateUrl: './layout-header.component.html',
  styleUrls: ['./layout-header.component.scss'],
})
export class LayoutHeaderComponent implements OnInit {
  public user: AuthUserInfomation;
  public isConfigurationOpen: boolean;
  public isAccountOpen: boolean;
  public showCreditModal: boolean;

  public credits: Observable<number>;
  public showCredit: boolean;

  public translation: LayoutHeaderTranslation =
    LayoutHeaderLanguages[this.language];

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    @Inject('LANGUAGE')
    private readonly language: keyof typeof LayoutHeaderLanguages,
    private readonly accountDetailsService: AccountDetailsService,
    private readonly creditsService: CreditsService,
    @Inject('FEATURE_FLAGS')
    private readonly featureFlags: FeatureFlags,
  ) {}

  ngOnInit(): void {
    this.user = this.authService.userInfo;
    this.showCredit = this.featureFlags.inAppCredit;
    this.getCredits();

    this.creditsService.creditsWereUpdated
      .asObservable()
      .subscribe(() => this.getCredits());

    this.authService.dataWasUpdated
      .asObservable()
      .subscribe(() => (this.user = this.authService.userInfo));
  }

  private getCredits(): void {
    this.credits = this.accountDetailsService
      .get()
      .pipe(map((data) => Number(data.balance)));
  }

  public openCreditModal(): void {
    this.showCreditModal = true;
  }

  public goToProfile(): void {
    this.router.navigateByUrl('/profile');
    this.isAccountOpen = false;
  }

  public logout(): void {
    this.authService.logout();
    this.router.navigateByUrl('/auth/login');
  }

  public onClickOutside(): void {
    this.isAccountOpen = false;
  }
}
