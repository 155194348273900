<commons-sidemodal (backgroundClick)="onClose()" *ngIf="order">
  <div class="flex align-items-center mb-4">
    <h3 class="flex-fill">{{ translation.details.title }}</h3>
    <div class="cursor-pointer" (click)="onClose()">
      <i class="fas fa-times"></i>
    </div>
  </div>
  <div class="my-4 pl-2">
    <button mat-button class="btn primary" (click)="copyOrder(order)">
      {{ translation.copyOrder }}
    </button>
  </div>
  <div class="g px-2">
    <div class="flex g-12 py-4 px-2 border-bottom border-gray">
      <div class="font-weight-700 mr-2">ID:</div>
      <div>{{ orderId }}</div>
    </div>
    <div class="flex g-12 py-4 px-2 border-bottom border-gray">
      <div class="font-weight-700 mr-2">{{ translation.details.client }}:</div>
      <div>{{ order.customer_fullname }}</div>
    </div>
    <div class="flex g-12 py-4 px-2 border-bottom border-gray">
      <div class="font-weight-700 mr-2">
        {{ translation.details.provider }}:
      </div>
      <img
        width="90"
        [src]="'/assets/images/stores/' + order.source + '.png'"
        [alt]="order.source"
      />
    </div>
    <div class="flex g-12 py-4 px-2 border-bottom border-gray">
      <div class="font-weight-700 mr-2">{{ translation.details.status }}:</div>
      <div>
        {{
          order.fulfillment_status === 'fulfilled'
            ? translation.fullfillmentStatus.fulfilled
            : translation.fullfillmentStatus.unfulfilled
        }}
      </div>
    </div>
    <div class="flex flex-column g-12 py-4 px-2 border-bottom border-gray">
      <div
        class="font-weight-700 mr-2 flex cursor-pointer"
        (click)="toggle('address_from')"
      >
        <div *ngIf="!expand.address_from">
          <i class="fas fa-chevron-down"></i>
        </div>
        <div *ngIf="expand.address_from"><i class="fas fa-chevron-up"></i></div>
        <div class="flex-fill pl-2">
          {{ translation.details.source }}
        </div>
      </div>
      <ng-container *ngIf="expand.address_from">
        <div class="flex flex-column pl-4">
          <div class="flex align-items-end">
            <div class="font-weight-500 mr-2 pt-2">
              {{ translation.details.name }}:
            </div>
            <div>{{ order.address_from?.name }}</div>
          </div>
          <div class="flex align-items-end">
            <div class="font-weight-500 mr-2 pt-2">
              {{ translation.details.company }}:
            </div>
            <div>{{ order.address_from?.company }}</div>
          </div>
          <div class="flex align-items-end">
            <div class="font-weight-500 mr-2 pt-2">
              {{ translation.details.email }}:
            </div>
            <div>{{ order.address_from?.email }}</div>
          </div>
          <div class="flex align-items-end">
            <div class="font-weight-500 mr-2 pt-2">
              {{ translation.details.phone }}:
            </div>
            <div>{{ order.address_from?.phone }}</div>
          </div>
          <div class="flex align-items-end">
            <div class="font-weight-500 mr-2 pt-2">
              {{ translation.details.country }}:
            </div>
            <div>{{ order.address_from?.country }}</div>
          </div>
          <div class="flex align-items-end">
            <div class="font-weight-500 mr-2 pt-2">
              {{ translation.details.state }}:
            </div>
            <div>{{ order.address_from?.province }}</div>
          </div>
          <div class="flex align-items-end">
            <div class="font-weight-500 mr-2 pt-2">
              {{ translation.details.zip }}:
            </div>
            <div>{{ order.address_from?.zip }}</div>
          </div>
          <div class="flex align-items-end">
            <div class="font-weight-500 mr-2 pt-2">
              {{ translation.details.address1 }}:
            </div>
            <div>{{ order.address_from?.city }}</div>
          </div>
          <div class="flex align-items-end">
            <div class="font-weight-500 mr-2 pt-2">
              {{ translation.details.address2 }}:
            </div>
            <div>{{ order.address_from?.address2 }}</div>
          </div>
          <div class="flex align-items-end">
            <div class="font-weight-500 mr-2 pt-2">
              {{ translation.details.address3 }}:
            </div>
            <div>{{ order.address_from?.address1 }}</div>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="flex flex-column g-12 py-4 px-2 border-bottom border-gray">
      <div
        class="font-weight-700 mr-2 flex cursor-pointer"
        (click)="toggle('address_to')"
      >
        <div *ngIf="!expand.address_to">
          <i class="fas fa-chevron-down"></i>
        </div>
        <div *ngIf="expand.address_to"><i class="fas fa-chevron-up"></i></div>
        <div class="flex-fill pl-2">
          {{ translation.details.target }}
        </div>
      </div>
      <div class="flex flex-column px-4" *ngIf="expand.address_to">
        <div class="flex align-items-end">
          <div class="font-weight-500 mr-2 pt-2 pr-2">
            {{ translation.details.name }}:
          </div>
          <div>{{ order.address_to?.name }}</div>
        </div>
        <div class="flex align-items-end">
          <div class="font-weight-500 mr-2 pt-2">
            {{ translation.details.company }}:
          </div>
          <div>{{ order.address_to?.company }}</div>
        </div>
        <div class="flex align-items-end">
          <div class="font-weight-500 mr-2 pt-2">
            {{ translation.details.email }}:
          </div>
          <div>{{ order.address_to?.email }}</div>
        </div>
        <div class="flex align-items-end">
          <div class="font-weight-500 mr-2 pt-2">
            {{ translation.details.phone }}:
          </div>
          <div>{{ order.address_to?.phone }}</div>
        </div>
        <div class="flex align-items-end">
          <div class="font-weight-500 mr-2 pt-2">
            {{ translation.details.content }}:
          </div>
          <div>{{ order.address_to?.contents }}</div>
        </div>
        <div class="flex align-items-end">
          <div class="font-weight-500 mr-2 pt-2">
            {{ translation.details.country }}:
          </div>
          <div>{{ order.address_to?.country }}</div>
        </div>
        <div class="flex align-items-end">
          <div class="font-weight-500 mr-2 pt-2">
            {{ translation.details.state }}:
          </div>
          <div>{{ order.address_to?.province }}</div>
        </div>
        <div class="flex align-items-end">
          <div class="font-weight-500 mr-2 pt-2">
            {{ translation.details.city }}:
          </div>
          <div>{{ order.address_to?.city }}</div>
        </div>
        <div class="flex align-items-end">
          <div class="font-weight-500 mr-2 pt-2">
            {{ translation.details.zip }}:
          </div>
          <div>{{ order.address_to?.zip }}</div>
        </div>
        <div class="flex align-items-end">
          <div class="font-weight-500 mr-2 pt-2">
            {{ translation.details.address1 }}:
          </div>
          <div>{{ order.address_to?.address1 }}</div>
        </div>
        <div class="flex align-items-end">
          <div class="font-weight-500 mr-2 pt-2">
            {{ translation.details.address2 }}:
          </div>
          <div>{{ order.address_to?.address2 }}</div>
        </div>
        <div class="flex align-items-end">
          <div class="font-weight-500 mr-2 pt-2">
            {{ translation.details.reference }}:
          </div>
          <div>{{ order.address_to?.reference }}</div>
        </div>
      </div>
    </div>
    <div
      class="flex flex-column g-12 py-4 px-2 border-bottom border-gray"
      *ngFor="let shipment of order.shipments || []; let idx = index"
    >
      <div
        class="font-weight-700 mr-2 flex cursor-pointer"
        (click)="toggle('shipment' + idx)"
      >
        <div *ngIf="!expand['shipment' + idx]">
          <i class="fas fa-chevron-down"></i>
        </div>
        <div *ngIf="expand['shipment' + idx]">
          <i class="fas fa-chevron-up"></i>
        </div>
        <div class="flex-fill pl-2">
          {{ translation.details.shipment }} #{{ idx + 1 }}
        </div>
      </div>
      <div class="px-4 flex flex-column" *ngIf="expand['shipment' + idx]">
        <div class="flex align-items-end">
          <div class="font-weight-500 mr-2 pt-2">
            {{ translation.details.status }}:
          </div>
          <div>
            {{ translation.statuses[shipment.status] }}
          </div>
        </div>
        <div class="flex align-items-end">
          <div class="font-weight-500 mr-2 pt-2">
            {{ translation.details.courier }}:
          </div>
          <div class="flex flex-column">{{ getProvider(shipment) }}</div>
        </div>
        <div class="flex align-items-end">
          <div class="font-weight-500 mr-2 pt-2">
            {{ translation.details.type }}:
          </div>
          <div>{{ getType(shipment) }}</div>
        </div>
      </div>
    </div>
  </div>
</commons-sidemodal>
