<commons-modal-with-content
  *ngIf="data"
  [title]="data.title"
  (backgroundClick)="handleBackgroundClick()"
  [showCloseButton]="data.showCancelButton"
  (closeButtonClick)="data.showCancelButton ? handleBackgroundClick() : null"
>
  <p
    class="mb-8 text-center"
    *ngIf="data.description && data.description !== ''"
    [innerHTML]="data.description"
  ></p>
  <div class="width-full flex justify-content-center">
    <button
      mat-flat-button
      class="width-one-third"
      *ngIf="data.cancelMessage"
      (click)="setResponse(false)"
    >
      {{ data.cancelMessage }}
    </button>
    <button
      mat-raised-button
      color="primary"
      class="width-one-third btn secondary"
      *ngIf="data.okMessage"
      (click)="setResponse(true)"
    >
      {{ data.okMessage }}
    </button>
  </div>
</commons-modal-with-content>
