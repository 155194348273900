export enum UserRole {
  director = 'director',
}

export interface AuthSignInPayload {
  email: string;
  password: string;
  remeber_me: 0 | 1;
}

export interface ValidateCodePayload {
  phone_number: string;
  code: string;
}

export interface ResendCodePayload {
  phone_number: string;
}

export interface AuthUserInfomation {
  id: number;
  email: string;
  contact_email: string;
  provider: string;
  uid: string;
  admin: boolean;
  api_key: string;
  contact_number: string;
  full_name: string;
  source: null;
  referral_code: {
    id: number;
    code: string;
    extra: null;
    created_at: string;
    updated_at: string;
  };
  referred_by_id: null;
  referred_completed_at: null;
  referral_code_id: number;
  referred_by_code: null;
  role: UserRole;
  extra: { active: boolean; create_users: boolean };
  sap_id: null;
  type: string;
}

export interface AuthSignInResponse {
  data: AuthUserInfomation;
}

export interface AuthRegisterPayload {
  email: string;
  password: string;
  full_name: string;
  contact_number: string;
  confirm_success_url: 0 | 1;
  company: {
    name: any,
    from_send: any,
    howmany_per_month: any,
    business_type: any,
    howcan_we_help: any,
    type_service: any
  }
}

export interface AuthResetPasswordPayload {
  password: string;
  token: string;
  confirmation_password?: string;
}
