import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CustomErrorStateMatcher,
  passwordMatch,
  RegexPassword,
} from '@commons/helpers/index';
import { AuthService } from '../auth.service';
import {
  AuthResetPasswordLanguages,
  AuthResetPasswordTranslation,
} from './auth-reset-password.translation';

@Component({
  selector: 'commons-auth-reset-password',
  templateUrl: './auth-reset-password.component.html',
  styleUrls: ['./auth-reset-password.component.scss'],
})
export class AuthResetPasswordComponent implements OnInit {
  /**
   * Error matcher for form group
   */
  public matcher = new CustomErrorStateMatcher();

  /**
   * Form for setting a new password in current component
   */
  public form: FormGroup;

  /**
   * Action Code provided by firebase that bounds the new password to the related user
   */
  private actionCode = '';

  /**
   * Shows confirmation modal
   */
  public showModal: boolean;

  /**
   *
   */
  public showField = {
    password: false,
    confirmPassword: false,
  };

  /**
   * Object for the english/spanish translations
   */
  public translation: AuthResetPasswordTranslation =
    AuthResetPasswordLanguages[this.language];

  constructor(
    @Inject('LANGUAGE')
    private readonly language: keyof typeof AuthResetPasswordLanguages,
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.buildForm();

    this.actionCode = this.route.snapshot.queryParamMap.get(
      'reset_password_token',
    ) as string;

    if (!this.actionCode) {
      this.router.navigate(['auth', 'login']);
    }
  }

  /**
   * Builds form values for current component
   */
  private buildForm(): void {
    this.form = this.fb.group(
      {
        password: [
          '',
          [Validators.required, Validators.pattern(RegexPassword)],
        ],
        confirmPassword: ['', [Validators.required]],
      },
      { validator: passwordMatch },
    );
  }

  /**
   * Toggle show password according to fields name
   * @param field field to toggle it's type
   */
  public toggleShowPassword(field: 'password' | 'confirmPassword'): void {
    this.showField[field] = !this.showField[field];
  }

  /**
   * Toggle's modal
   */
  public toggleModal(): void {
    this.showModal = !this.showModal;
  }

  /**
   *
   */
  public onSubmit(): void {
    if (!this.form.valid) {
      return;
    }
    this.authService
      .resetPassword({
        password: this.form.value.password,
        confirmation_password: this.form.value.password,
        token: this.actionCode,
      })
      .subscribe(() => {
        this.goToLogin();
      });
  }

  /**
   *
   */
  private goToLogin(): void {
    this.router.navigateByUrl('/auth/login');
  }
}
