import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewOrderComponent } from './new-order.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { CommonPageModule } from '../../common-page/common-page.module';
import { NewOrderRoutingModule } from './new-order-routing.module';

@NgModule({
  declarations: [NewOrderComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSelectModule,
    CommonPageModule,
    NewOrderRoutingModule,
  ],
  exports: [NewOrderComponent],
})
export class NewOrderModule {}
