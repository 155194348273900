<commons-common-page
  [title]="translation.page.title"
  [canGoBack]="currentSectionIndex !== 0"
>
  <div
    *ngIf="currentSectionIndex !== 3"
    class="form-container py-6 border-radius-5 flex flex-row justify-content-center"
  >
    <div
      *ngFor="let step of ['store', 'additionalData', 'finish']; let i = index"
      class="step"
      [ngClass]="{ unavailable: currentSectionIndex < i }"
    >
      <div class="step-number">
        {{ i + 1 }}
      </div>
      {{ translation.steps[step] }}
    </div>
  </div>

  <router-outlet></router-outlet>

  <div
    class="width-full mt-16 flex justify-content-center"
    *ngIf="currentSectionIndex === 1"
  >
    <button
      mat-raised-button
      color="primary"
      class="btn primary wide"
      (click)="saveAndContinue()"
    >
      {{ translation.continue }}
    </button>
  </div>
</commons-common-page>
