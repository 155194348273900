import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TutorialSlidersComponent } from './tutorial-sliders/tutorial-sliders.component';

@NgModule({
  declarations: [TutorialSlidersComponent],
  exports: [TutorialSlidersComponent],
  imports: [CommonModule]
})
export class SlidersModule {}
