import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewBulkOrderComponent } from './new-bulk-order.component';
import { SlidersModule } from '@commons/modules/sliders';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [NewBulkOrderComponent],
  exports: [NewBulkOrderComponent],
  imports: [
    CommonModule,
    SlidersModule,
    MatButtonModule,
    MatProgressSpinnerModule,
  ],
})
export class NewBulkOrderModule {}
