<ng-container *ngIf="show === 'tutorial'">
  <commons-no-orders></commons-no-orders>
</ng-container>
<ng-container *ngIf="show === 'data'">
  <h1 class="mb-4">{{ translation.page.title }}</h1>
  <mat-card>
    <div class="card-header">
      {{ translation.list.title }}
    </div>
    <div class="card-body">
      <form [formGroup]="form" class="flex width-full">
        <div class="flex-fill">
          <mat-form-field class="mr-1" style="width: 150px;">
            <mat-label>{{ translation.list.storeOptionsLabel }}</mat-label>
            <mat-select formControlName="store">
              <mat-option *ngFor="let store of storeOptions" [value]="store.value">
                {{ store.text }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field style="width: 150px;">
            <mat-label>{{ translation.list.shippingOptionsLabel }}</mat-label>
            <mat-select formControlName="status">
              <mat-option *ngFor="let shipping of shippingOptions" [value]="shipping.value">
                {{ shipping.text }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill" class="g-3 px-1" style="width: 150px;">
            <mat-label>{{ translation.list.from }}</mat-label>
            <input matInput [matDatepicker]="fromPicker" formControlName="from_date" [min]="minDate" [max]="maxDate" />
            <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
            <mat-datepicker #fromPicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field appearance="fill" class="g-3 pl-1" style="width: 150px;">
            <mat-label>{{ translation.list.to }}</mat-label>
            <input matInput [matDatepicker]="toPicker" formControlName="to_date" [min]="form.value.from_date"
              [max]="maxDate" />
            <mat-datepicker-toggle matSuffix [for]=" toPicker"></mat-datepicker-toggle>
            <mat-datepicker #toPicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="flex">
          <mat-form-field>
            <mat-label>{{ translation.list.searchLabel }}</mat-label>
            <input matInput formControlName="search" />
            <!-- <span matPrefix class="mr-2"><i class="fas fa-search"></i></span> -->
            <span matSuffix class="cursor-pointer ml-2" *ngIf="!form.value.search"
              [matTooltip]="translation.searchTooltip" matTooltipClass="custom-tooltip">
              <i class="fas fa-question-circle"></i>
            </span>
            <span matSuffix class="cursor-pointer ml-2" *ngIf="form.value.search" (click)="onClearSearch()">
              <i class="fas fa-times"></i>
            </span>
          </mat-form-field>
          <div class="mx-4 search">
            <button mat-raised-button class="btn wide" (click)="searchQuery()" style="height: 50px; width: 50px;"
              [disabled]="!form.value.search" (keyup.enter)="searchQuery()">
              <i class="fas fa-search mr-2" style="color: white;"></i>
            </button>
          </div>
        </div>
      </form>
    </div>
  </mat-card>
  <div class="my-12 flex align-items-center">
    <div class="font-size-3 font-weight-3">
      {{ translation.list.createOrder }}:
    </div>
    <div class="mx-4 ">
      <button mat-raised-button class="btn wide" (click)="onCreateOrder()">
        <i class="fas fa-plus mr-2"></i>
        {{ translation.list.simpleOrder }}
      </button>
    </div>
    <div class="flex-fill flex-column">
      <button mat-raised-button class="btn wide" (click)="onCreateBulk()">
        <i class="fas fa-plus mr-2"></i>
        {{ translation.list.csvOrder }}
      </button>
    </div>
    <div>
      <!-- <a class="downloadcsv" (click)="downloadCsv()"> <i class="fas fa-download mr-1"></i> Descargar reporte</a> -->
    </div>
  </div>
  <mat-card class="flex flex-column" *ngIf="datatableInfo">
    <div class="flex px-4 pt-4 pb-2">
      <mat-slide-toggle [checked]="showHidden" (change)="onShowHiddenChanged($event)">
        {{ translation.list.showHidden }}
      </mat-slide-toggle>
    </div>
    <div class="flex px-4 pt-4 pb-2">
      <div class="flex-fill flex-column">
        <div>{{ translation.list.tableTitle }}</div>
      </div>
      <div class="flex">
        <div class="cursor-pointer mx-1" (click)="onBulkSend()" *ngIf="hasOrders">
          <i class="fas fa-paper-plane"></i>
        </div>
        <div *ngIf="!showHidden && hasOrders" class="cursor-pointer mx-1" (click)="onBulkHide()">
          <i class="fas fa-eye-slash"></i>
        </div>
        <div *ngIf="showHidden && hasOrders" class="cursor-pointer mx-1" (click)="onBulkShow()">
          <i class="fas fa-eye"></i>
        </div>
        <div class="mx-2 divider"></div>
        <div class="cursor-pointer mx-1" (click)="onBulkSync()">
          <i class="fas fa-sync mx-2"></i>
          {{ translation.syncTitle }}
        </div>
      </div>
    </div>
    <table mat-table [dataSource]="datatableInfo.data">
      <ng-container matColumnDef="checkbox">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox color="primary" [checked]="areAllSelected" (change)="onAllSelected($event)"></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox color="primary" [checked]="selectedOrders[row.id]" (change)="onOrderSelected($event, row.id)">
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>
          {{ translation.table.headers[0] }}
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.id }}
        </td>
      </ng-container>
      <ng-container matColumnDef="source">
        <th mat-header-cell *matHeaderCellDef>
          {{ translation.table.headers[1] }}
        </th>
        <td mat-cell *matCellDef="let row">
          <img [src]="'/assets/images/stores/' + row.source + '.png'" [alt]="row.source" />
        </td>
      </ng-container>
      <ng-container matColumnDef="customer_email">
        <th mat-header-cell *matHeaderCellDef>
          {{ translation.table.headers[2] }}
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.customer_email }}
        </td>
      </ng-container>
      <ng-container matColumnDef="address_to">
        <th mat-header-cell *matHeaderCellDef>
          {{ translation.table.headers[3] }}
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.address_to?.address1 }}, {{ row.address_to?.address2 }},
          {{ row.address_to?.zip }},
          {{ row.address_to?.country }}
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>
          {{ translation.table.headers[5] }}
        </th>
        <td mat-cell *matCellDef="let row" [class]="{
            fulfilled: row.fulfillment_status === 'fulfilled',
            unfulfilled: row.fulfillment_status !== 'fulfilled'
          }">
          {{
          row.fulfillment_status === 'fulfilled'
          ? translation.list.fullfillmentStatus.fulfilled
          : translation.list.fullfillmentStatus.unfulfilled
          }}
        </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>
          {{ translation.table.headers[7] }}
        </th>
        <td mat-cell *matCellDef="let row">
          {{ getType(row) }}
        </td>
      </ng-container>
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>
          {{ translation.table.headers[4] }}
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.created_at | date: 'yyyy-MM-dd h:mm a' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>
          {{ translation.table.headers[6] }}
        </th>
        <td mat-cell *matCellDef="let row">
          <commons-dropdown-menu>
            <div class="dropdown-item" (click)="onSelectOrder(row.id)">
              {{ translation.list.options.view }}
            </div>
            <div *ngIf="!showHidden" class="dropdown-item" (click)="onHide(row.id)">
              {{ translation.list.options.hide }}
            </div>
            <div *ngIf="showHidden" class="dropdown-item" (click)="onShow(row.id)">
              {{ translation.list.options.show }}
            </div>
            <div class="dropdown-item" (click)="onSend(row.id, row.items, row.address_to.contents)">
              {{ translation.list.options.send }}
            </div>
          </commons-dropdown-menu>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>
    </table>

    <div *ngIf="!datatableInfo.data.length" class="flex justify-content-center p-4">
      {{ translation.list.noResults }}
    </div>
    <mat-paginator [length]="datatableInfo.meta.total_count" [pageSize]="20"
      [pageIndex]="datatableInfo.meta.current_page - 1" (page)="onPageChange($event)"></mat-paginator>
  </mat-card>
  <commons-view-order *ngIf="selectedOrder" [orderId]="selectedOrder" (closeClicked)="onSelectOrder(null)">
  </commons-view-order>
  <div class="mt-12"></div>
</ng-container>