import { AccountDetailsService } from './../../../../services/account-details.service';
import { takeUntil, startWith, map } from 'rxjs/operators';
import { CreditsService } from './../../../credits/credits.service';
import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TutorialSlider } from '@commons/models/index';
import {
  CommonOrdersLanguages,
  CommonOrdersTranslation,
} from '../common-orders.translation';
import { Subject } from 'rxjs';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'commons-no-orders',
  templateUrl: './no-orders.component.html',
  styleUrls: ['./no-orders.component.scss'],
})
export class NoOrdersComponent implements OnInit {
  public readonly translation: CommonOrdersTranslation =
    CommonOrdersLanguages[this.language];

  public emptyValues: TutorialSlider[] = [
    {
      text: this.translation.emptyElements[0],
      url: 'assets/images/ordenes_icon_direccion.svg',
    },
    {
      text: this.translation.emptyElements[1],
      url: 'assets/images/ordenes_icon_dimensiones.svg',
    },
    {
      text: this.translation.emptyElements[2],
      url: 'assets/images/ordenes_icon_datos.svg',
    },
    {
      text: this.translation.emptyElements[3],
      url: 'assets/images/ordenes_img_paqueterias.png',
    },
    {
      text: this.translation.emptyElements[4],
      url: 'assets/images/ordenes_icon_descarga.svg',
    },
  ];

  currentBalance: any = 0;
  private destroyed$: Subject<void> = new Subject<void>();

  constructor(
    @Inject('LANGUAGE')
    private readonly language: keyof typeof CommonOrdersLanguages,
    private readonly router: Router,
    private readonly creditsService: CreditsService,
    private readonly accountDetailsService: AccountDetailsService,
    private readonly snotifyService: SnotifyService,
  ) {
    this.creditsService.creditsWereUpdated
    .pipe(
      takeUntil(this.destroyed$),
      startWith(0),
    )
    .subscribe(() => {
      console.log('aqui');
      
      this.accountDetailsService
        .get()
        .pipe(map(({ balance }) => Number(balance)))
        .subscribe(balance => (this.currentBalance = balance));
    });
  }

  ngOnInit(): void {
   
  }

  public onCreate(): void {
    console.log(this.currentBalance);
    
    if (this.currentBalance <= 0 ) {
      this.snotifyService.error(
        this.translation.notifications.simpleInsufficientFunds,
      );
      return;
    }
    localStorage.setItem('hideOrdersTutorial', 'hide');
    this.router.navigateByUrl('/orders/new');
  }

  public onSkip(): void {
    localStorage.setItem('hideOrdersTutorial', 'hide');
    this.router.navigateByUrl('/orders');
  }
}
