export class AdminUsersTranslation {
  currency: string;
  required: string;
  list: {
    noResults: string;
    title: string;
    searchTitle: string;
    shippingOptions: string[];
    storeOptionsLabel: string;
    shippingOptionsLabel: string;
    reportButtonLabel: string;
    headers: {
      id: string;
      mail: string;
      phone: string;
      balance: string;
      debt: string;
      clabe: string;
      estimates: string;
      signUpDate: string;
      action: string;
    };
    inputs: {
      search: {
        placeholder: string;
        label: string;
      };
      seeDetails: string;
    };
  };
  errors: {
    passwordRequired: string;
    passwordPattern: string;
    passwordUnmatched: string;
  };
  tabs: {
    orders: string;
    balance: string;
    rates: string;
    permissions: string;
    account: string;
  };
  form: {
    balance: {
      data: {
        header: string;
        user: string;
        balance: string;
        debt: string;
      };
      form: {
        header: string;
        deposit: string;
        type: string;
        amount: string;
        billing: string;
        bonus: string;
        comments: string;
        depositOptions: { text: string; value: string }[];
        typeOptions: {
          deposit: { text: string; value: string }[];
          withdrawal: { text: string; value: string }[];
        };
      };
    };
    orders: {
      title: string;
      store: string;
      search: string;
      status: string;
      yes: string;
      no: string;
      statuses: {
        WAITING: string;
        CANCELLED: string;
        SUCCESS: string;
        ERROR: string;
        FULFILLMENT: string;
      };
      headers: {
        source: string;
        client: string;
        address: string;
        type: string;
        status: string;
        created: string;
        action: string;
      };
      fullfillmentStatus: {
        fulfilled: string;
        unfulfilled: string;
      };
      details: {
        title: string;
        client: string;
        courier: string;
        provider: string;
        type: string;
        dimensions: string;
        insurance: string;
        created: string;
        time: string;
        estimate: string;
        content: string;
        status: string;
        source: string;
        target: string;
        name: string;
        company: string;
        email: string;
        country: string;
        phone: string;
        state: string;
        shipment: string;
        zip: string;
        city: string;
        address1: string;
        address2: string;
        address3: string;
        reference: string;
        international: string;
      };
    };
    account: {
      account: {
        header: string;
        headercomany: string;
        businnestype: string;
        fromsend: string;
        howcan: string;
        howmany: string;
        company: any;
        typeservice: string;
        access: string;
        name: string;
        phone: string;
        email: string;
        paymentReference: string;
        estimateShipping: string;
      };
      password: {
        header: string;
        password: string;
        confirmPassword: string;
      };
      api: {
        header: string;
        key: string;
        description: string;
      };
    };
    permissions: {
      permissions: {
        header: string;
        description: string;
        items: string[];
      };
      couriers: {
        header: string;
        description: string;
      };
      cancelations: {
        header: string;
        description: string;
      };
    };
  };
  buttons: {
    save: string;
    saveMyAddress: string;
    copyOrder: string;
  };
  options: string;
  searchTooltip: string;
  modalConfirm: string;
  notifications: {
    successBalance: string;
    successAccount: string;
    errorBalance: string;
    errorAccount: string;
  };
}

const ES: AdminUsersTranslation = {
  currency: 'MXN',
  required: '* Campo obligatorio',
  list: {
    noResults: 'No se encontraron resultados',
    title: 'Usuarios',
    searchTitle: 'Realizar búsqueda',
    storeOptionsLabel: 'Tienda',
    shippingOptionsLabel: 'Envíos',
    shippingOptions: ['Todos', 'Pendientes', 'Enviados'],
    reportButtonLabel: 'Generar reporte',
    headers: {
      id: 'Id',
      mail: 'Correo',
      phone: 'Teléfono',
      balance: 'Saldo actual',
      debt: 'Deuda',
      clabe: 'Clabe STP',
      estimates: 'Envíos estimados',
      signUpDate: 'Fecha de alta',
      action: 'Acción',
    },
    inputs: {
      search: {
        placeholder: 'Buscar por...',
        label: 'Buscar por...',
      },
      seeDetails: 'Ver detalle',
    },
  },
  errors: {
    passwordRequired: `El campo Contraseña es requerido.`,
    passwordUnmatched: `Las contraseñas no coinciden.`,
    passwordPattern: `El campo contraseña debe poseer de 8 a 24 caracteres, al menos 1
          Mayuscula, 1 Número y 1 Simbolo.`,
  },
  tabs: {
    orders: 'Órdenes',
    balance: 'Saldo',
    rates: 'Tarifas',
    permissions: 'Permisos',
    account: 'Cuenta',
  },
  form: {
    balance: {
      data: {
        header: 'Datos de usuario',
        user: 'Usuario',
        balance: 'Saldo Actual',
        debt: 'Deuda',
      },
      form: {
        header: 'Administrar saldo',
        deposit: 'Seleccionar movimiento',
        type: 'Seleccionar Tipo',
        amount: 'Cantidad',
        billing: 'Facturable',
        bonus: 'Abono a sobrepeso',
        comments: 'Comentarios',
        depositOptions: [
          { text: 'Depósito', value: 'deposit' },
          { text: 'Retiro', value: 'withdrawal' },
        ],
        typeOptions: {
          deposit: [
            { text: 'Depósito bancario SpeedIT', value: 'TSKY' },
            { text: 'BillPocket', value: 'BILLPOCKET' },
            { text: 'PAYPAL', value: 'PAYPAL' },
            { text: 'MERCADOPAGO', value: 'MERCADOPAGO' },
            { text: 'Crédito', value: 'CREDITO' },
            { text: 'Cancelación FEDEX', value: 'CF' },
            { text: 'Cancelación REDPACK', value: 'CR' },
            { text: 'Cancelación ESTAFETA', value: 'CE' },
            { text: 'Cancelación DHL', value: 'CD' },
            { text: 'Cancelación UPS', value: 'CU' },
            { text: 'Cancelación PAQUETEXPRESS', value: 'CP' },
            { text: 'Indemnización', value: 'INDEM' },
            { text: 'Indemnización por incumplimiento', value: 'INDEM2' },
            { text: 'Cliente confianza', value: 'CREDITOS' },
            { text: 'Factura fuera de tiempo', value: 'FFTD' },
            { text: 'Otros', value: 'OTROS' },
            { text: 'Pruebas', value: 'PRUEBAS' },
            { text: 'Uso Interno', value: 'USO_INTERNO' },
            { text: 'Error al generar guía', value: 'ERROR_LABEL' },
            { text: 'Error al generar guía(s)', value: 'ERROR_LABEL_BATCH' },
            {
              text: 'Error al generar guía (seguro)',
              value: 'ERROR_LABEL_INSURANCE',
            },
            {
              text: 'Error al generar guía (zona extendida)',
              value: 'ERROR_LABEL_OUT_OF_AREA',
            },
            {
              text: 'Cancelación de guía (seguro)',
              value: 'CANCEL_LABEL_INSURANCE',
            },
            {
              text: 'Cancelación de guía (zona extendida)',
              value: 'CANCEL_LABEL_OUT_OF_AREA',
            },
            { text: 'Rembolso por sobrepeso', value: 'RSBP' }
          ],
          withdrawal: [
            { text: 'Guía internacional', value: 'GUIAI' },
            { text: 'Redirecciones', value: 'REDIRECCION' },
            { text: 'Ajustes/Abono Erróneo', value: 'OTRO' },
            { text: 'Factura fuera de tiempo', value: 'FFTR' },
            { text: 'Guía DHL', value: 'GUIAD' },
            { text: 'Guía FEDEX', value: 'GUIAF' },
            { text: 'Guía ESTAFETA', value: 'GUIAE' },
            { text: 'Guía REDPACK', value: 'GUIAR' },
            { text: 'Guía PAQUETEXPRESS', value: 'GUIAPE' },
            { text: 'Guía UPS', value: 'GUIAUP' },
            { text: 'Guía FEDEX Seguro', value: 'GFS' },
            { text: 'Guía DHL Seguro', value: 'GSA' },
            { text: 'Guía ESTAFETA Seguro', value: 'GES' },
            { text: 'Guía UPS Seguro', value: 'GUS' },
            { text: 'Guía REDPACK Seguro', value: 'GRS' },
            { text: 'Guía PAQUETEXPRESS Seguro', value: 'GPES' },
            { text: 'Tarimas', value: 'LTL' },
            { text: 'Sobrepesos', value: 'SBP' },
            { text: 'Seguro de guía', value: 'INSURANCE' },
            { text: 'Insumos / Consumibles', value: 'SUPPLIES' },
          ],
        },
      },
    },
    orders: {
      title: 'Realizar búsqueda',
      store: 'Tienda',
      search: 'Buscar por...',
      status: 'Estatus',
      yes: 'Si',
      no: 'No',
      statuses: {
        WAITING: 'Pendiente',
        CANCELLED: 'Cancelado',
        SUCCESS: 'Exitoso',
        ERROR: 'Error',
        FULFILLMENT: 'Completado',
      },
      headers: {
        source: 'Origen',
        client: 'Cliente',
        address: 'Dirección',
        type: 'Envío',
        status: 'Estatus',
        created: 'Fecha de creación',
        action: 'Acción',
      },
      fullfillmentStatus: {
        fulfilled: 'Enviado',
        unfulfilled: 'Pendiente',
      },
      details: {
        title: 'Detalle de orden',
        client: 'Cliente',
        courier: 'Paquetería',
        provider: 'Proveedor de paquetería',
        type: 'Tipo de envío',
        dimensions: 'Dimensiones del paquete',
        insurance: 'Seguro de envío',
        created: 'Fecha de generación de guía',
        time: 'Duración de entrega',
        estimate: 'Fecha de entrega estimada',
        content: 'Contenido',
        status: 'Estatus',
        source: 'Dirección de origen',
        target: 'Dirección de destino',
        name: 'Nombre',
        company: 'Compañía',
        email: 'Correo electrónico',
        shipment: 'Paquete',
        phone: 'Teléfono',
        country: 'País',
        city: 'Ciudad',
        state: 'Estado',
        zip: 'C.P',
        address1: 'Delegación o Municipio',
        address2: 'Colonia',
        address3: 'Calle y número',
        reference: 'Referencia',
        international: 'Envio internacional',
      },
    },
    account: {
      account: {
        header: 'Cuenta de usuario',
        headercomany: 'Compañia',
        businnestype: 'Tipo de negocio',
        fromsend: 'Origen de envíos',
        howcan: 'Como te podemos ayudar',
        howmany: 'Envíos por mes',
        company: 'Nombre de la empresa',
        typeservice: 'Tipo de negocio',
        access: 'Acceso a la plataforma',
        name: 'Nombre completo',
        phone: 'Teléfono',
        email: 'Email de contacto',
        paymentReference: 'Referencia de pago',
        estimateShipping: 'Envios estimados',
      },
      password: {
        header: 'Contraseña',
        password: 'Contraseña',
        confirmPassword: 'Confirmar contraseña',
      },
      api: {
        header: 'API',
        key: 'API Key para ambiente: ',
        description:
          'Para conocer del API, solicite la información al administrador.',
      },
    },
    permissions: {
      permissions: {
        header: 'Permisos del usuario',
        description: 'Seleccione los permisos otorgados a este usuario.',
        items: [
          'Activar cobro de zona extendida',
          'Permitir envíos internacionales',
          'Permitir acceso',
          'Permitir crear usuarios secundarios',
          'Hacer administrador',
          'Hacer Vendedor de Marketplace'
        ],
      },
      couriers: {
        header: 'Paqueterías activadas',
        description:
          'Seleccione las paqueterías con las que este usuario podrá generar guías',
      },
      cancelations: {
        header: 'Permiso para cancelar',
        description:
          'Seleccione las paqueterías con las que este usuario podrá solicitar cancelaciones',
      },
    },
  },
  buttons: {
    save: 'Guardar',
    saveMyAddress: 'Guardar en Mis Direcciones',
    copyOrder: 'Copiar orden',
  },
  options: 'Opciones',
  searchTooltip: 'Buscar por email...',
  modalConfirm: '¿Está seguro de realizar esta acción?',
  notifications: {
    errorBalance: 'Ocurrió un error al intentar actualizar el saldo del usuario. Por favor intente de nuevo.',
    errorAccount: 'Ocurrió un error al intentar actualizar el usuario. Por favor intente de nuevo.',
    successBalance: 'El saldo del usuario fueron actualizadas exitosamente.',
    successAccount: 'El usuario ha sido actualizado exitosamente.',
  },
};

const EN: AdminUsersTranslation = {
  currency: 'USD',
  required: '* Required field',
  list: {
    noResults: 'No results',
    title: 'Users',
    searchTitle: 'Search',
    storeOptionsLabel: 'Store',
    shippingOptionsLabel: 'Shipping',
    shippingOptions: ['All', 'Pending', 'Sent'],
    reportButtonLabel: 'Generate report',
    headers: {
      id: 'Id',
      mail: 'Mail',
      phone: 'Phone',
      balance: 'Credits',
      debt: 'Debt',
      clabe: 'Clabe STP',
      estimates: 'Estimate shippings',
      signUpDate: 'Fecha de alta',
      action: 'Action',
    },
    inputs: {
      search: {
        placeholder: 'Search by...',
        label: 'Search by...',
      },
      seeDetails: 'See details',
    },
  },
  errors: {
    passwordRequired: `Password field is required.`,
    passwordUnmatched: `Password fields doesn't match.`,
    passwordPattern: `password field must present 8 to 24 characters, at least 1
          Uppercase, 1 Number and 1 Symbol.`,
  },
  tabs: {
    orders: 'Orders',
    balance: 'Balance',
    rates: 'Rates',
    permissions: 'Permissions',
    account: 'Account',
  },
  form: {
    balance: {
      data: {
        header: 'User information',
        user: 'User',
        balance: 'Balance',
        debt: 'Debt',
      },
      form: {
        header: 'Manage balance',
        deposit: 'Select Deposit',
        type: 'Select Type',
        amount: 'Amount',
        billing: 'Billable',
        bonus: 'Overweight bonus',
        comments: 'Comments',
        depositOptions: [
          { text: 'Deposit', value: 'deposit' },
          { text: 'Withdraw', value: 'withdrawal' },
        ],
        typeOptions: {
          deposit: [
            { text: 'Deposit Pak2Go 300', value: 'TSKY' },
            { text: 'Deposit Pak2Go 94', value: 'TSK' },
            { text: 'Deposit 02 222', value: 'T02' },
            { text: 'Deposit Pak2go 222', value: 'TKRAKEN' },
            { text: 'Deposit AFIRME 117', value: 'TAFIRME' },
            { text: 'BillPocket', value: 'BILLPOCKET' },
            { text: 'PAYPAL', value: 'PAYPAL' },
            { text: 'Crédito', value: 'CREDITO' },
            { text: 'Compropago', value: 'COMPROPAGO' },
            { text: 'Referred de 100', value: 'R1' },
            { text: 'Referred de 200', value: 'R200' },
            { text: 'Cancel FEDEX', value: 'CF' },
            { text: 'Cancel REDPACK', value: 'CR' },
            { text: 'Cancel ESTAFETA', value: 'CE' },
            { text: 'Cancel DHL', value: 'CD' },
            { text: 'Cancel UPS', value: 'CU' },
            { text: 'Cancel PAQUETEXPRESS', value: 'CP' },
            { text: 'Cancel SENDEX', value: 'CS' },
            { text: 'Cancel CARSSA', value: 'CC' },
            { text: 'Cancel ENVIA', value: 'CEN' },
            { text: 'Compensation', value: 'INDEM' },
            { text: 'Compensation for unfulfillment', value: 'INDEM2' },
            { text: 'Client trust', value: 'CREDITOS' },
            { text: 'Bill out of time', value: 'FFTD' },
            { text: 'Others', value: 'OTROS' },
            { text: 'Trial', value: 'PRUEBAS' },
            { text: 'Internal use', value: 'USO_INTERNO' },
            { text: 'Error creating guide', value: 'ERROR_LABEL' },
            { text: 'Error creating guide(s)', value: 'ERROR_LABEL_BATCH' },
            {
              text: 'Error creating guide (insurance)',
              value: 'ERROR_LABEL_INSURANCE',
            },
            {
              text: 'Error creating guide (out of zone)',
              value: 'ERROR_LABEL_OUT_OF_AREA',
            },
            {
              text: 'Cancelled guide (insurance)',
              value: 'CANCEL_LABEL_INSURANCE',
            },
            {
              text: 'Cancelled guide (zona extendida)',
              value: 'CANCEL_LABEL_OUT_OF_AREA',
            },
            { text: 'Overweight refund', value: 'RSBP' },
          ],
          withdrawal: [
            { text: 'Internacional guide', value: 'GUIAI' },
            { text: 'DHL guide', value: 'GUIAD' },
            { text: 'Redirection', value: 'REDIRECCION' },
            { text: 'Erroneus adjustment', value: 'OTRO' },
            { text: 'Out of time bill', value: 'FFTR' },
            { text: 'FEDEX guide', value: 'GUIAF' },
            { text: 'ESTAFETA guide', value: 'GUIAE' },
            { text: 'REDPACK guide', value: 'GUIAR' },
            { text: 'PAQUETEXPRESS guide', value: 'GUIAPE' },
            { text: 'UPS guide', value: 'GUIAUP' },
            { text: 'SENDEX guide', value: 'GUIASE' },
            { text: 'FEDEX Insurance guide', value: 'GFS' },
            { text: 'DHL Insurance guide', value: 'GSA' },
            { text: 'ESTAFETA Insurance guide', value: 'GES' },
            { text: 'UPS Insurance guide', value: 'GUS' },
            { text: 'REDPACK Insurance guide', value: 'GRS' },
            { text: 'PAQUETEXPRESS Insurance guide', value: 'GPES' },
            { text: 'Pallets', value: 'LTL' },
            { text: 'Overweight', value: 'SBP' },
            { text: 'Insurance guide', value: 'INSURANCE' },
            { text: 'Supplies', value: 'SUPPLIES' },
            { text: `Listify's membership`, value: 'LISTIFY' },
          ],
        },
      },
    },
    orders: {
      title: 'Search order',
      store: 'Store',
      search: 'Search by...',
      status: 'Status',
      yes: 'Yes',
      no: 'No',
      statuses: {
        WAITING: 'Waiting',
        CANCELLED: 'Cenceled',
        SUCCESS: 'Success',
        ERROR: 'Error',
        FULFILLMENT: 'Fullfillment',
      },
      headers: {
        source: 'Source',
        client: 'Client',
        address: 'Address',
        type: 'Shipping type',
        status: 'Estatus',
        created: 'Created at',
        action: 'Action',
      },
      fullfillmentStatus: {
        fulfilled: 'Sent',
        unfulfilled: 'Pending',
      },
      details: {
        title: 'Order details',
        client: 'Client',
        courier: 'Courier',
        provider: 'Shipping provider',
        type: 'Shipping type',
        dimensions: 'Package dimensions',
        insurance: 'Shipping Insurance',
        created: '',
        time: 'Delivery time',
        estimate: 'Estimate delivery',
        content: 'Content',
        status: 'Status',
        source: 'Source address',
        target: 'Shipping address',
        name: 'Name',
        company: 'Company',
        email: 'Email',
        phone: 'Phone',
        country: 'Country',
        state: 'State',
        zip: 'Zip',
        shipment: 'Shipment',
        address1: 'Municipality',
        city: 'City',
        address2: 'Neighborhood',
        address3: 'Street & Numbers',
        reference: 'Reference',
        international: 'International Shipping',
      },
    },
    account: {
      account: {
        header: 'Account info',
        headercomany: 'Company',
        businnestype: 'Tipo de negocio',
        fromsend: 'Origen de envíos',
        howcan: 'Como te podemos ayudar',
        howmany: 'Envíos por mes',
        company: 'Nombre de la empresa',
        typeservice: 'Tipo de negocio',
        access: 'Access Email',
        name: 'Full name',
        phone: 'Phone',
        email: 'Contact Email',
        paymentReference: 'Payment reference',
        estimateShipping: 'Estimate shippings',
      },
      password: {
        header: 'Password',
        password: 'Password',
        confirmPassword: 'Confirm password',
      },
      api: {
        header: 'API',
        key: 'API Key for environment: ',
        description: 'To know more about APIs contact your administrator.',
      },
    },
    permissions: {
      permissions: {
        header: "User's permissions",
        description: "Select user's permissions.",
        items: [
          'Enable out of area billing',
          'Allow international shipping',
          'Allow access',
          "Allow secondary user's creation",
          'Promote to admin',
        ],
      },
      couriers: {
        header: 'Enabled couriers',
        description: 'Select couriers used to generate shipping guides.',
      },
      cancelations: {
        header: 'Cancelation permissions',
        description: 'Select couriers for user to cancel shipments.',
      },
    },
  },
  buttons: {
    save: 'Save',
    saveMyAddress: 'Save in My Addresses',
    copyOrder: 'Copy order',
  },
  options: 'Options',
  searchTooltip: "Search by client's email...",
  modalConfirm: 'Are you sure to do this?',
  notifications: {
    errorAccount: 'An error occurred while trying to update the user. Please try again.',
    errorBalance: 'An error occurred while trying to update the user\'s balance. Please try again.',
    successBalance: 'The user\'s balance was successfully updated.',
    successAccount: 'The user has been updated successfully.'
  },
};

export const AdminUsersLanguages = {
  EN,
  ES,
};
