import {
  Component,
  EventEmitter,
  Inject,
  Injectable,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { CanDeactivate, Router } from '@angular/router';
import { CustomErrorStateMatcher } from '@commons/helpers/index';
import { BillingInfoPayload } from '@commons/models/index';
import { SnotifyService } from 'ng-snotify';
import { Subscription } from 'rxjs';
import { CommonBalancesService } from '../../common-balances/common-balances.service';
import {
  ClientProfileLanguages,
  ClientProfileTranslation,
} from '../client-profile.translation';

@Injectable({ providedIn: 'root' })
export class PristineGuard
  implements CanDeactivate<ClientProfileBillingComponent> {
  canDeactivate(): boolean {
    return false;
  }
}

@Component({
  selector: 'commons-client-profile-billing',
  templateUrl: './client-profile-billing.component.html',
  styleUrls: ['./client-profile-billing.component.scss'],
})
export class ClientProfileBillingComponent implements OnInit, OnDestroy {
  @Input() billingForm: FormGroup;

  @Input() automaticInvoiceForm: FormGroup;

  @Output() storeFormInfo = new EventEmitter<void>();

  @Input() data: BillingInfoPayload;

  public readonly translation: ClientProfileTranslation =
    ClientProfileLanguages[this.language];

  /**
   *
   */
  public matcher = new CustomErrorStateMatcher();

  public initialData: BillingInfoPayload;

  private submitDataSubscription = Subscription.EMPTY;

  private getDataSubscription = Subscription.EMPTY;

  private automaticSubscription = Subscription.EMPTY;

  constructor(
    @Inject('LANGUAGE')
    private readonly language: keyof typeof ClientProfileLanguages,
    private readonly commonBalancesService: CommonBalancesService,
    private readonly snotifyService: SnotifyService,
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.getDataSubscription.unsubscribe();
    this.submitDataSubscription.unsubscribe();
    this.automaticSubscription.unsubscribe();
  }

  public onAutomaticBillingChanged({ checked }: MatSlideToggleChange): void {
    this.automaticInvoiceForm.controls.automaticInvoice.setValue(checked);
  }

  public onSubmit(): void {
    const method = !this.data
      ? 'createBillingInfo'
      : 'updateBillingInfo';
      
    this.submitDataSubscription = this.commonBalancesService[method](
      this.billingForm.value,
    ).subscribe(
      () => {
        this.automaticSubscription = this.commonBalancesService
          .setAutomaticBilling(this.automaticInvoiceForm.value.automaticInvoice)
          .subscribe(
            () => {
              this.storeFormInfo.emit();
              this.snotifyService.success(
                this.translation.snotify.submittedBilling,
              );
            },
            () =>
              this.snotifyService.error(
                this.translation.snotify.errorSubmittingBilling,
              ),
          );
      },
      () =>
        this.snotifyService.error(
          this.translation.snotify.errorSubmittingBilling,
        ),
    );
  }
}
