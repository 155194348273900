<div
  class="dropdown-container"
  commonsClickOutsideMenu
  (clickOutside)="onClickOutside()"
>
  <button mat-button (click)="onToggle()">
    <i class="fas fa-ellipsis-v pointer"></i>
  </button>
  <div class="dropdown dropdown-right" *ngIf="isOpen">
    <ng-content></ng-content>
  </div>
</div>
