<mat-card class="width-max-content">
  <div class="px-12 px-xs-2 pt-8">
    <div class="flex justify-content-center mb-8">
      <h1>
        {{ translation.title }}
      </h1>
    </div>
    <form class="flex flex-column" [formGroup]="form" (ngSubmit)="onSubmit()">
      <!-- <mat-form-field>
        <mat-label>
          {{ translation.labels.telephone }}
        </mat-label>
        <input
          matInput
          formControlName="phone_number"
          required
          [errorStateMatcher]="matcher"
          name="phone_number"
          min="10"
          max="100"
        />
        <mat-error
          class="mb-2"
          *ngIf="
            form.controls.phone_number.errors && form.controls.phone_number.errors.pattern
          "
        >
          {{ translation.errors.telephoneValid }}
        </mat-error>
        <mat-error
          class="mb-2"
          *ngIf="
            form.controls.phone_number.errors && form.controls.phone_number.errors.required
          "
        >
          {{ translation.errors.telephoneRequired }}
        </mat-error>
      </mat-form-field> -->
      <mat-form-field>
        <mat-label>
          {{ translation.labels.code }}
        </mat-label>
        <input
          matInput
          required
          formControlName="code"
          [errorStateMatcher]="matcher"
          min="4"
          max="24"
          name="code"
        />
        <!-- <div
          matSuffix
          class="input-icon color-grey-light-1 pointer"
          (click)="toggleShowPassword()"
          *ngIf="!showPassword"
        >
          <i class="cursor-pointer fas fa-eye pr-2"></i>
        </div> -->
        <!-- <div
          matSuffix
          class="input-icon color-grey-light-1 pointer"
          (click)="toggleShowPassword()"
          *ngIf="showPassword"
        >
          <i class="cursor-pointer fas fa-eye-slash pr-2"></i>
        </div> -->
        <mat-error
          class="mb-2"
          *ngIf="
            form.controls.code.errors &&
            form.controls.code.errors.required
          "
        >
          {{ translation.errors.codeRequired }}
        </mat-error>
        <mat-error
          class="mb-2"
          *ngIf="
            form.controls.code.errors &&
            form.controls.code.errors.pattern
          "
        >
          {{ translation.errors.codeValid }}
        </mat-error>
      </mat-form-field>
      <div class="flex align-items-center mt-4 mb-8">
        <div class="mx-5">
          <a class="action" (click)="resendCode()">
            {{ translation.links.resendCode}}
          </a>
        </div>
      </div>
      <div class="mb-4">
        <button
          mat-raised-button
          class="width-full btn secondary"
          type="submit"
          [disabled]="form.valid && !disableButton ? null : true"
        >
          {{ translation.buttons.signIn }}
        </button>
      </div>
      <!-- <div class="flex justify-content-center mb-8">
        <a class="action" routerLink="/auth/register">
          {{ translation.links.signUp }}
        </a>
      </div> -->
    </form>
  </div>
</mat-card>
