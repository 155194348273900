import {
  Component,
  Inject,
  Injectable,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Location } from '@angular/common';
import {
  ClientProfileLanguages,
  ClientProfileTranslation,
} from './client-profile.translation';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { passwordMatch } from '@commons/helpers/form.helper';
import {
  RegexEmail,
  RegexPassword,
  RegexRFC,
} from '@commons/helpers/regexp.helper';
import { ConfirmationModalService } from '@commons/modules/modals';
import {
  ActivatedRoute,
  CanDeactivate,
  NavigationStart,
  Router,
} from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  MatTab,
  MatTabChangeEvent,
  MatTabGroup,
  MatTabHeader,
} from '@angular/material/tabs';
import { CommonBalancesService } from '../common-balances/common-balances.service';
import { ClientProfileService } from './client-profile.service';

@Injectable({ providedIn: 'root' })
export class PristineGuard implements CanDeactivate<ClientProfileComponent> {
  canDeactivate(): boolean {
    return false;
  }
}

@Component({
  selector: 'commons-client-profile',
  templateUrl: './client-profile.component.html',
  styleUrls: ['./client-profile.component.scss'],
})
export class ClientProfileComponent implements OnInit, OnDestroy {
  @ViewChild('tabGroup') tabs: MatTabGroup;

  public readonly translation: ClientProfileTranslation =
    ClientProfileLanguages[this.language];

  public initialData = {
    billingData: {
      business_name: '',
      tax_id: '',
      payment_way: '',
      tax_regime: '',
      street1: '',
      street2: '',
      zip: '',
      city: '',
      province: '',
      country: '',
      phone: '',
      email: '',
    },
    automaticInvoice: { automaticInvoice: false },
    passwordData: { password: '', confirmPassword: '' },
    infoData: {
      email: '',
      full_name: '',
      contact_number: '',
      sap_id: null,
    },
  };

  data: any;

  public dataForm: FormGroup;

  public passwordForm: FormGroup;

  public billingForm: FormGroup;

  public automaticInvoiceForm: FormGroup;

  public selectedTabIndex = 0;

  private routerSubscription = Subscription.EMPTY;

  private billingDataSubscription = Subscription.EMPTY;

  private infoDataSubscription = Subscription.EMPTY;

  private modalSubscription = Subscription.EMPTY;

  private get isPristine(): boolean {
    if (!this.selectedTabIndex) {
      return this.dataForm.pristine && this.passwordForm.pristine;
    }
    return this.billingForm.pristine && this.automaticInvoiceForm.pristine;
  }

  constructor(
    @Inject('LANGUAGE')
    private readonly language: keyof typeof ClientProfileLanguages,
    private readonly location: Location,
    private readonly fb: FormBuilder,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly confirmationModalService: ConfirmationModalService,
    private readonly commonBalancesService: CommonBalancesService,
    private readonly clientProfileService: ClientProfileService,
  ) {}

  ngOnInit(): void {
    this.buildDataForm();
    this.buildPasswordForm();
    this.buildBillingForm();
    this.buildAutomaticInvoiceForm();
    this.loadBillingData();
    this.loadInfoData();
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        this.modalSubscription.unsubscribe();
        if (this.isPristine) {
          return;
        }
        this.route.routeConfig.canDeactivate = [PristineGuard];
        this.modalSubscription = this.confirmationModalService
          .cancelOrConfirm({
            title: this.translation.confirmTitle,
            description: this.translation.confirmDescription,
          })
          .subscribe(challengeValue => {
            this.modalSubscription.unsubscribe();
            if (challengeValue) {
              this.route.routeConfig.canDeactivate = undefined;
              this.selectedTabIndex
                ? this.resetBillingForm()
                : this.resetDataForm();
              this.router.navigateByUrl(event.url);
            }
            return this.confirmationModalService.close();
          });
      });
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
    this.modalSubscription.unsubscribe();
    this.billingDataSubscription.unsubscribe();
    this.infoDataSubscription.unsubscribe();
  }

  public loadInfoData(): void {
    this.infoDataSubscription = this.clientProfileService
      .getProfile()
      .subscribe(data => {
        console.log(data);
        
        this.initialData.infoData = data;
        this.dataForm.setValue(data);
        this.data = this.initialData;
      });
      console.log(this.initialData);
      
  }

  public loadBillingData(): void {
    this.billingDataSubscription = this.commonBalancesService
      .getBillingInfo()
      .subscribe(({ data, automatic_invoice }) => {
        this.tabs._handleClick = this.validateOnChange.bind(this);
        if (!data) {
          return;
        }
        const { business_name, tax_id, payment_way, tax_regime, street1, street2, zip,  city, province, country, phone, email} = data;
        this.initialData.billingData = {
          business_name,
          tax_id,
          payment_way,
          tax_regime,
          street1,
          street2,
          zip,
          city,
          province,
          country,
          phone,
          email
        };
        this.initialData.automaticInvoice = {
          automaticInvoice: automatic_invoice === '1',
        };
        this.billingForm.setValue({  business_name, tax_id, payment_way, tax_regime, street1, street2, zip,  city, province, country, phone, email });
        this.automaticInvoiceForm.setValue({
          automaticInvoice: automatic_invoice === '1',
        });
      });
  }

  public buildBillingForm(): void {
    this.billingForm = this.fb.group({
      business_name: ['', [Validators.required]],
      tax_id: ['', [Validators.required,  Validators.required,
        Validators.maxLength(13),
        Validators.minLength(12),
        Validators.pattern(RegexRFC),]],
      tax_regime: ['', Validators.required],
      payment_way: ['', [Validators.required]],
      street1: ['', [Validators.required]],
      street2: ['', [Validators.required]],
      zip: ['', [Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(5)]],
      city: ['', [Validators.required]],
      province: ['', [Validators.required]],
      country: ['', [Validators.required]],
      phone: ['', [Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(10)]],
      email: ['', [Validators.required, Validators.email, Validators.pattern(/\S+@\S+\.\S+/)]],
    });
  }

  public buildAutomaticInvoiceForm(): void {
    this.automaticInvoiceForm = this.fb.group({
      automaticInvoice: [false, [Validators.required]],
    });
  }

  public buildDataForm(): void {
    this.dataForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(RegexEmail)]],
      full_name: [
        '',
        [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(50),
        ],
      ],
      contact_number: [
        '',
        [
          Validators.required,
          Validators.pattern(/^\d{10}$/),
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
      sap_id: [null],
    });
  }

  public buildPasswordForm(): void {
    this.passwordForm = this.fb.group(
      {
        password: [
          '',
          [Validators.required, Validators.pattern(RegexPassword)],
        ],
        confirmPassword: ['', [Validators.required]],
      },
      { validator: passwordMatch },
    );
  }

  private resetBillingForm(): void {
    this.billingForm.setValue(this.initialData.billingData);
    this.automaticInvoiceForm.setValue(this.initialData.automaticInvoice);
    this.billingForm.markAsPristine();
    this.automaticInvoiceForm.markAsPristine();
  }

  private resetDataForm(): void {
    this.dataForm.setValue(this.initialData.infoData);
    this.passwordForm.setValue(this.initialData.passwordData);
    this.dataForm.markAsPristine();
    this.passwordForm.markAsPristine();
  }

  private validateOnChange(
    tab: MatTab,
    tabHeader: MatTabHeader,
    idx: number,
  ): void {
    this.modalSubscription.unsubscribe();
    if (this.isPristine) {
      this.selectedTabIndex = idx;
      return;
    }
    this.modalSubscription = this.confirmationModalService
      .cancelOrConfirm({
        title: this.translation.confirmTitle,
        description: this.translation.confirmDescription,
      })
      .subscribe(challengeValue => {
        this.modalSubscription.unsubscribe();
        if (challengeValue) {
          this.selectedTabIndex
            ? this.resetBillingForm()
            : this.resetDataForm();
          this.selectedTabIndex = idx;
        }
        return this.confirmationModalService.close();
      });
  }

  public onGoBack(): void {
    this.location.back();
  }

  public onDataStored(): void {
    this.initialData.infoData = this.dataForm.value;
    this.resetDataForm();
  }

  public onBillingStored(): void {
    this.initialData.billingData = this.billingForm.value;
    this.initialData.automaticInvoice = this.automaticInvoiceForm.value;
    this.resetBillingForm();
  }
}
