import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownMenuComponent } from './dropdown-menu.component';
import { ClickOutsideHeaderActionsDirective } from './click-outside-directive';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [DropdownMenuComponent, ClickOutsideHeaderActionsDirective],
  exports: [DropdownMenuComponent],
  imports: [CommonModule, MatButtonModule],
})
export class DropdownMenuModule {}
