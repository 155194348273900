import { HttpParams } from '@angular/common/http';
import { BaseEntityFields } from './common.model';
import { DatatableMeta, DatatableResponse } from './datatable.model';
import * as moment from 'moment';

export interface ParcelsFormType {
  length: number;
  width: number;
  height: number;
  weight: number;
  quantity: number;
  unit: string;
  contents: string;
  product_type: string;
}

export interface Parcel {
  weight: number;
  distance_unit: string;
  mass_unit: string;
  height: number;
  width: number;
  length: number;
  unit: string;
  contents: string;
  product_type: string;
}

export interface Label {
  company: string;
  shipmentType: string;
  days: number;
  rate: number;
  checkbox: any;
  insurance: boolean;
  id: string;
  label_url: string | null;
  tracking_number: string;
  tracking_url_provider: string;
  error: { message: string }[];
}

export type LabelState = 'loading' | 'success' | 'error';

export interface AddressFrom {
  province: string;
  city: string;
  name: string;
  zip: string;
  country: string;
  address1: string;
  company: string;
  address2: string;
  phone: string;
  email: string;
  type_of?: 'from' | 'to';
  alias_name?: string;
}

export type AddressTo = AddressFrom & {
  reference: string;
  contents: string;
};

export type GenericAddress = AddressTo | AddressFrom;

export interface LabelsResponse {
  shipment: Shipment;
}

interface Shipment {
  id: number;
  status: string;
  created_at: string;
  updated_at: string;
  declared_cost?: any;
  is_international: boolean;
  rates: Rate[];
  address_to: Addressto;
  address_from: Addressfrom;
  batch_label?: any;
  labels: any[];
}

interface Addressfrom {
  id: number;
  name: string;
  company: string;
  address1: string;
  address2: string;
  city: string;
  province: string;
  zip: string;
  country: string;
  phone: string;
  email: string;
  created_at: string;
  updated_at: string;
  reference?: any;
  province_code: string;
  contents?: any;
}

interface Addressto {
  id: number;
  name: string;
  company: string;
  address1: string;
  address2: string;
  city: string;
  province: string;
  zip: string;
  country: string;
  phone: string;
  email: string;
  created_at: string;
  updated_at: string;
  reference: string;
  province_code: string;
  contents: string;
}

export interface Rate {
  id: number;
  created_at: string;
  updated_at: string;
  amount_local: string;
  currency_local: string;
  provider: string;
  service_level_name: string;
  service_level_code: string;
  service_level_terms?: any;
  days: number;
  duration_terms?: any;
  zone?: any;
  arrives_by?: any;
  self_insurance: boolean;
  insurance_currency: string;
  insurance_deductible: string;
  insurance_commission: string;
  declared_cost?: number;
  out_of_area: boolean;
  out_of_area_pricing: string;
  total_pricing: string;
  insurance_amount: number | string;

  // used locally
  checked?: any;
  checkbox?: any;

}

interface Addressto {
  data: Datum;
}

interface Datum {
  id: string;
  type: string;
}

export interface AddressAttributes {
  name: string;
  company: string;
  address1: string;
  address2: string;
  city: string;
  province: string;
  zip: string;
  country: string;
  phone: string;
  email: string;
  created_at: string;
  updated_at: string;
  reference: string;
  province_code: string;
  contents: string;
}

export interface RateAttributes {
  created_at: string;
  updated_at: string;
  amount_local: string;
  currency_local: string;
  provider: string;
  service_level_name: string;
  service_level_code: string;
  service_level_terms?: any;
  days: number;
  duration_terms?: any;
  zone?: any;
  arrives_by?: any;
  out_of_area: boolean;
  out_of_area_pricing: string;
  total_pricing: string;
}

export interface PlaceShipmentResponse {
  data: PlaceShipmentResponseData;
}

export interface PlaceShipmentResponseData {
  id: string;
  type: string;
  attributes: ReadyShipmentLabel;
}

export interface ReadyShipmentLabel {
  label_id: number | string;
  created_at: string;
  updated_at: string;
  status?: any;
  tracking_number: string;
  tracking_status?: any;
  label_url: string;
  tracking_url_provider: string;
  rate_id: number;
}

export interface OrderAddress extends BaseEntityFields {
  address1: string;
  address2: string;
  city: string;
  company: string;
  contents: string;
  country: string;
  created_at: string;
  email: string;
  name: string;
  phone: string;
  province: string;
  province_code: string;
  reference: string;
  updated_at: string;
  zip: string;
}

export interface OrderShipment extends BaseEntityFields {
  created_at: string;
  updated_at: string;
  declared_cost: number;
  is_international: boolean;
  status: string;
  address_from: OrderAddress;
  address_to: OrderAddress;
}

export interface DataOrder extends BaseEntityFields {
  updated_at: string;
  created_at: string;
  customer_email: string;
  customer_fullname: string;
  fulfillment_status: string;
  hidden: boolean;
  shipping: boolean;
  source: string;
  address_from?: OrderAddress;
  address_to?: OrderAddress;
  shipments?: OrderShipment[];
  relationships: {
    address_from: OrderAddress;
    address_to: OrderAddress;
    items: any[];
    shipments: OrderShipment;
  };
}

export interface BatchLabel {
  id?: number;
  number: number;
  label_file: { url: string };
  status?: string;
  step?: string;
  messages?: {
    message: string;
    shipment_id: number
  }[];
  shipments: {
    id: number;
    order: DataOrder;
    status: string;
    rates: Rate[];
  }[];
}

export interface BulkOrder {
  status: string;
  batch_label: BatchLabel;
}

export class DatatableOrders implements DatatableResponse {
  meta: DatatableMeta;
  data: DataOrder[];
}

export interface OrderSearchParams {
  store?: string;
  status?: number;
  search?: string;
  from_date?: string;
  to_date?: string;
  hidden_status?: string;
  exportfile?: string;
}

export const ParseOrderParams = (
  page: number,
  { store, status, search, from_date, to_date, hidden_status, exportfile  }: OrderSearchParams,
): HttpParams => {
  let params = new HttpParams();
  if (!!store && store.trim() !== '') {
    params = params.set(`${store.toLowerCase()}_status`, 'true');
  }

  if (!!status && status !== 0) {
    params = params.set(
      'fulfillment_status',
      `${status === 1 ? 'unfulfilled' : 'fulfilled'}`,
    );
  }

  if (!!search && search.trim() !== '') {
    params = params.set('q', search);
  }

  if (hidden_status) {
    params = params.set('hidden_status', 'true');
  }
  if (exportfile) {
    params = params.set('export', 'true');
  }

  if (from_date ) {
    params = params.set('from_date', moment(from_date).format('DD/MM/YYYY'));
    
  }
  if ( to_date) {
   
    params = params.set('to_date', moment(to_date).format('DD/MM/YYYY'));
  }

  if (page && page !== 1) {
    params = params.set('page', page.toString());
  }

  return params;
};

export interface ZipCodeData {
  state?: string;
  city?: string;
  neighborhoods?: string[];
  state_abbreviation?: string;
  success: string;
}

export interface IAddressesResponse {
  data: SavedAddress[];
  meta: DatatableMeta;
}

export interface SavedAddress {
  id: number;
  alias_name?: string;
  email: string;
  name: string;
  street1: string;
  street2?: string;
  city: string;
  company: string;
  province: string;
  country: string;
  zip: string;
  created_at: string;
  phone: string;
  reference?: any;
  residential: boolean;
  is_warehouse: boolean;
  default_origin: boolean;
  default_destination: boolean;
  default_warehouse: boolean;
  type_of: string;
}
