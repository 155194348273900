import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  APIRoutes,
  AuthRegisterPayload,
  AuthResetPasswordPayload,
  AuthSignInPayload,
  AuthSignInResponse,
  AuthUserInfomation,
  ValidateCodePayload,
  ResendCodePayload
} from '@commons/models/index';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private routes = new APIRoutes();
  public dataWasUpdated: Subject<void> = new Subject<void>();
  public shared_phone_number: string;

  constructor(private readonly http: HttpClient, private readonly router: Router) { }

  public login(payload: AuthSignInPayload): Observable<AuthUserInfomation> {
    return this.http
      .post<AuthSignInResponse>(this.routes.Auth.Custom('sign_in'), payload)
      .pipe(
        tap(({ data }: AuthSignInResponse) => {
          localStorage.setItem('session', JSON.stringify(data));
        }),
        map(({ data }: AuthSignInResponse) => {
          return data;
        }),
      );
  }

  public validate_code(payload: ValidateCodePayload): Observable<void> {
    return this.http.post<any>(this.routes.ValidateCode.All, payload);
  }

  public resend_code(payload: ResendCodePayload): Observable<void> {
    return this.http.post<any>(this.routes.ResendCode.All, payload);
  }


  public update(): void {
    this.http
      .get(this.routes.Profile.All)
      .pipe(
        tap((data: AuthSignInResponse) => {
          localStorage.setItem('session', JSON.stringify(data));
        }),
      )
      .subscribe(() => {
        this.dataWasUpdated.next();
      });
  }

  public register(payload: AuthRegisterPayload): Observable<void> {
    return this.http.post<any>(this.routes.Auth.All, payload);
  }

  public recoverPassword({
    email,
    redirect_url,
  }: {
    [key: string]: string;
  }): Observable<void> {
    redirect_url = 'https://admin.speedit.mx/auth/reset-password';
    return this.http.post<any>(this.routes.RecoverPassword, {
      email,
      redirect_url,
    });
  }

  public resetPassword(payload: AuthResetPasswordPayload): Observable<void> {
    return this.http.post<any>(this.routes.ResetPassword, payload);
  }

  public logout(): void {
    localStorage.setItem('session', JSON.stringify(null));
    if (!this.router.isActive('/auth/login', true)) {
      this.router.navigate(['/auth/login']);
    }
  }

  public get userInfo(): AuthUserInfomation {
    return JSON.parse(localStorage.getItem('session')) as AuthUserInfomation;
  }

  public get isLogged(): boolean {
    const session = JSON.parse(
      localStorage.getItem('session'),
    ) as AuthUserInfomation;
    return !!session && !!session.api_key;
  }

  public get isAdmin(): boolean {
    const session = JSON.parse(
      localStorage.getItem('session'),
    ) as AuthUserInfomation;
    return !!session && !!session.admin;
  }

  get apiKey(): string {
    const session = JSON.parse(
      localStorage.getItem('session'),
    ) as AuthUserInfomation;
    return !!session && session.api_key;
  }
}
