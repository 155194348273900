import { Component, Inject, OnInit } from '@angular/core';
import { versions } from 'projects/client/src/environments/versions';
import {
  AuthContainerLanguages,
  AuthContainerTranslation,
} from './auth-container-translation';
@Component({
  selector: 'commons-auth-container',
  templateUrl: './auth-container.component.html',
  styleUrls: ['./auth-container.component.scss'],
})
export class AuthContainerComponent implements OnInit {
  public readonly translation: AuthContainerTranslation =
    AuthContainerLanguages[this.language];

  public countrySwitcherURL: string;

  readonly version = versions.toString();

  constructor(
    @Inject('LANGUAGE')
    private readonly language: keyof typeof AuthContainerLanguages,
  ) {}

  ngOnInit(): void {
    this.countrySwitcherURL =
      this.language === 'ES'
        ? 'https://usaspeedit.garagecoders.rocks/'
        : 'https://speedit.garagecoders.rocks/';
  }
}
