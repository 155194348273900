export class CommonPickupsTranslation {
  page: {
    title: string;
    emtpyPickups: string;
    emtpyPickupsDescription: string;
    create: string;
  };
  disclaimer: string;
  currency: string;
  form: {
    title: string;
    verifyTitle: string;
    infoTitle: string;
    packageTitle: string;
    labels: {
      courier: string;
      guide: string;
      zip: string;
      date: string;
      time: string;
      weight: string;
      packages: string;
      name: string;
      email: string;
      phone: string;
      state: string;
      county: string;
      location: string;
      street: string;
      interior: string;
      exterior: string;
      references: string;
      company: string;
    };
    errors: {
      zipRequired: string;
      carrierRequired: string;
      weightRequired: string;
      packageCountRequired: string;
      nameRequired: string;
      emailRequired: string;
      phoneRequired: string;
      stateRequired: string;
      countyRequired: string;
      locationRequired: string;
      streetRequired: string;
      exteriorRequired: string;
      interiorRequired: string;
      companyRequired: string;
      zipLength: string;
      phoneLength: string;
      phonePattern: string;
      emailValid: string;
    };
    placeholders: {
      zip: string;
      weight: string;
      packages: string;
    };
    buttons: {
      validate: string;
      save: string;
    };
  };
  list: {
    title: string;
    createPickup: string;
  };
  table: {
    headers: string[];
  };
  emptyElements: string[];
  notifications: {
    error: string;
  };
}

const EN: CommonPickupsTranslation = {
  page: {
    title: 'My Pick ups',
    emtpyPickups: "You don't have any pending pick ups",
    emtpyPickupsDescription: 'Click on the arrows to check the tutorial',
    create: 'Create pick up',
  },
  disclaimer:
    "Pick up service's duration is not included on delivery time. Delivery guarantee must be fulfilled even without having requested a pick up service,",
  list: {
    title: 'Pick ups List',
    createPickup: 'Add Pick up',
  },
  currency: 'USD',
  form: {
    title: 'Create pick up',
    verifyTitle: 'Pickup Address',
    infoTitle: 'Pick up info',
    packageTitle: 'Package description',
    labels: {
      courier: 'Courier',
      guide: '# guide label',
      zip: 'Zip Code',
      date: 'Estimated Date',
      time: 'Estimated Time',
      weight: 'Weight in Kg',
      packages: "Package's Amount",
      name: 'Person Delivering',
      email: 'Email',
      phone: 'Phone (10 Digits)',
      state: 'Province',
      county: 'County',
      location: 'Sector',
      street: 'Street',
      interior: 'Interior #',
      exterior: 'Exterior #',
      references: 'Reference',
      company: 'Receiving company/person',
    },
    errors: {
      zipRequired: 'Zip Code field is required.',
      carrierRequired: 'Carrier field is required.',
      weightRequired: 'Weight field is required.',
      packageCountRequired: "Package's Amount field is required.",
      nameRequired: 'Name field is required.',
      emailRequired: 'Email field is required.',
      phoneRequired: 'Phone field is required.',
      stateRequired: 'Province field is required.',
      countyRequired: 'County field is required.',
      locationRequired: 'Sector field is required.',
      streetRequired: 'Street field is required.',
      exteriorRequired: 'Exterior # field is required.',
      interiorRequired: 'Interior # field is required.',
      companyRequired: 'Receiving company/person field is required.',
      zipLength: 'Zip Code field must have between 4 and 6 digits.',
      phoneLength: `Phone field can only have 10 numbers`,
      phonePattern: `Phone field can only contain numbers.`,
      emailValid: `Please insert a valid Email address.`,
    },
    placeholders: {
      zip: '11800',
      weight: '0.5',
      packages: '4',
    },
    buttons: {
      validate: 'Verify',
      save: 'Program Pick up',
    },
  },
  table: {
    headers: [
      'Source',
      'Confirmation #',
      'Address',
      'Pick up estimated date',
      'Status',
    ],
  },
  emptyElements: [
    'Select a courier and fill your Zip Code and Country.',
    'Complete the data needed to program your pick up.',
    'Finish your pick up.',
  ],
  notifications: {
    error: 'An unexpected error ocurred. Please try again later.',
  },
};
const ES: CommonPickupsTranslation = {
  page: {
    title: 'Mis recolecciones',
    emtpyPickups: 'No cuentas con recolecciones',
    emtpyPickupsDescription: 'Haz clic en las flechas para conocer el tutorial',
    create: 'Crear recolección',
  },
  currency: 'MXN',
  disclaimer:
    'La duración del servicio de recolección no está incluida en el tiempo de entrega. La garantía de entrega se debe cumplir, aún sin haber solicitado el servicio de recolección.',
  list: {
    title: 'Lista de recolecciones',
    createPickup: 'Agregar recolecciones',
  },
  form: {
    title: 'Crear recolección',
    verifyTitle: 'Dirección de recolección',
    infoTitle: 'Información de recolección',
    packageTitle: 'Descripción del paquete',
    labels: {
      courier: 'Paquetería',
      guide: 'Número de guía',
      zip: 'Código Postal',
      date: 'Fecha estimada',
      time: 'Hora estimada',
      weight: 'Peso en Kilogramos',
      packages: 'Número total de paquetes',
      name: 'Nombre de la persona que entrega',
      email: 'Correo Electrónico',
      phone: 'Teléfono (10 Digitos)',
      state: 'Estado',
      county: 'Delegación o Municipio',
      location: 'Colonia',
      street: 'Calle',
      interior: '# Interior',
      exterior: '# Exterior',
      references: 'Referencia',
      company: 'Compañía o Persona que recibe',
    },
    errors: {
      zipRequired: 'El campo Código Postal es requerido.',
      carrierRequired: 'El campo Paquetería es requerido.',
      weightRequired: 'El campo Peso en Kilogramos es requerido.',
      packageCountRequired: 'El campo Número total de paquetes es requerido.',
      nameRequired: 'El campo Nombre total de paquetes es requerido.',
      emailRequired: 'El campo Correo Electrónico es requerido.',
      phoneRequired: 'El campo Teléfono es requerido.',
      stateRequired: 'El campo Estado es requerido.',
      countyRequired: 'El campo Delegación o Municipio es requerido.',
      locationRequired: 'El campo Colonia es requerido.',
      streetRequired: 'El campo Calle es requerido.',
      exteriorRequired: 'El campo # Exterior es requerido.',
      interiorRequired: 'El campo # Interior es requerido.',
      companyRequired: 'El campo Compañía o persona que recibe es requerido.',
      zipLength:
        'El campo Código Postal debe tener una cantidad de cifras entre 4 y 6.',
      phoneLength: `El campo Teléfono debe poseer 10 números.`,
      phonePattern: `El campo Teléfono acepta solo números.`,
      emailValid: `Por favor introduzca una dirección de Correo electrónico válida.`,
    },
    placeholders: {
      zip: '11800',
      weight: '0.5',
      packages: '4',
    },
    buttons: {
      validate: 'Verificar',
      save: 'Programar Recolección',
    },
  },
  table: {
    headers: [
      'Origen',
      '#Confirmación',
      'Dirección',
      'Fecha estimada de recolección',
      'Estatus',
    ],
  },
  emptyElements: [
    'Selecciona una paquetería e indica tu C.P y País.',
    'Completa los datos para la programación de tu recolección.',
    'Finaliza tu recolección.',
  ],
  notifications: {
    error: 'Ocurrió un error inesperado. Intente de nuevo más tarde.',
  },
};

export const CommonPickupsLanguages = {
  EN,
  ES,
};
