<ng-container *ngIf="datatableInfo && !datatableInfo.data">
  <commons-no-pickups></commons-no-pickups>
</ng-container>
<ng-container *ngIf="datatableInfo && datatableInfo.data">
  <h1 class="mb-4">{{ translation.list.title }}</h1>
  <div class="mt-12 mb-4 flex justify-content-end">
    <button mat-flat-button class="btn primary" (click)="onCreatePickup()">
      <div class="px-4">
        {{ translation.list.createPickup }}
        <i class="ml-2 fas fa-plus"></i>
      </div>
    </button>
  </div>
  <mat-card *ngIf="datatableInfo">
    <table mat-table [dataSource]="datatableInfo.data">
      <ng-container matColumnDef="source">
        <th mat-header-cell *matHeaderCellDef>
          {{ translation.table.headers[0] }}
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.provider }}
        </td>
      </ng-container>
      <ng-container matColumnDef="confirmation">
        <th mat-header-cell *matHeaderCellDef>
          {{ translation.table.headers[1] }}
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.confirmation_number }}
        </td>
      </ng-container>
      <ng-container matColumnDef="address_to">
        <th mat-header-cell *matHeaderCellDef>
          {{ translation.table.headers[2] }}
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.address_to?.street1 }}, {{ row.address_to?.admin_level_3 }},
          {{ row.address_to?.zip }},
          {{ row.address_to?.admin_level_0 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>
          {{ translation.table.headers[3] }}
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.pickup_datetime | date: 'yyyy-MM-dd h:mm a' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>
          {{ translation.table.headers[4] }}
        </th>
        <td mat-cell *matCellDef="let row">
          {{ row.status }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>
    </table>

    <mat-paginator
      [length]="datatableInfo.meta.total_count"
      [pageSize]="20"
      [pageIndex]="datatableInfo.meta.current_page - 1"
      (page)="onPageChange($event)"
    ></mat-paginator>
  </mat-card>
</ng-container>
