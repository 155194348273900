import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfirmationModalComponent } from './confirmation-modal.component';
import { ModalWithContentModule } from '../modal-with-content';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [ConfirmationModalComponent],
  exports: [ConfirmationModalComponent],
  imports: [CommonModule, ModalWithContentModule, MatButtonModule],
})
export class ConfirmationModalModule {}
