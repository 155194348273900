import {
  Component,
  Inject,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomErrorStateMatcher } from '@commons/helpers/index';
import { PickUpAvailabilityChecked } from '@commons/models/index';
import { Subscription } from 'rxjs';
import { CommonPickupsService } from '../common-pickups.service';
import {
  CommonPickupsLanguages,
  CommonPickupsTranslation,
} from '../common-pickups.translation';

@Component({
  selector: 'commons-pickup-verify',
  templateUrl: './pickup-verify.component.html',
  styleUrls: ['./pickup-verify.component.scss'],
})
export class PickupVerifyComponent implements OnInit, OnDestroy {
  @Output() verified = new EventEmitter<PickUpAvailabilityChecked>();

  @Input() availableCouriers: string[] = [];

  public readonly translation: CommonPickupsTranslation =
    CommonPickupsLanguages[this.language];

  /**
   *
   */
  public matcher = new CustomErrorStateMatcher();

  /**
   *
   */
  form: FormGroup;

  private verifySubscription = Subscription.EMPTY;

  constructor(
    @Inject('LANGUAGE')
    private readonly language: keyof typeof CommonPickupsLanguages,
    private readonly pickupsService: CommonPickupsService,
    private readonly fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.buildForm();
  }

  ngOnDestroy(): void {
    this.verifySubscription.unsubscribe();
  }

  /**
   *
   */
  public buildForm(): void {
    this.form = this.fb.group({
      provider: ['', [Validators.required]],
      zip: [
        '',
        [Validators.required, Validators.minLength(4), Validators.maxLength(6), Validators.max(1_000_000)],
      ],
    });
  }

  public getProviderName(name: string){
    if(name == 'Nnminutos'){
      return '99Minutos'
    } else {
      return name
    }
  }

  public onSubmit(): void {
    if (!this.form.valid) {
      return;
    }
    const { provider, zip } = this.form.value;
    localStorage.setItem('provider', provider);
    localStorage.setItem('zip', zip);
    this.verifySubscription = this.pickupsService
      .verify({ provider, generic_address: { zip: +zip } })
      .subscribe(value => {
        this.verified.emit(value);
      });
  }
}
