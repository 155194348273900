export class AuthContainerTranslation {
  countrySwitcher: {
    title: string;
    button: string;
  };
}

const ES: AuthContainerTranslation = {
  countrySwitcher: {
    title: 'Are you in USA?',
    button: 'GO',
  },
};

const EN: AuthContainerTranslation = {
  countrySwitcher: {
    title: '¿Estás en México?',
    button: 'Ir',
  },
};
export const AuthContainerLanguages = {
  EN,
  ES,
};
