import { HttpParams } from '@angular/common/http';
import { BaseEntityFields } from './common.model';
import { DatatableMeta, DatatableResponse } from './datatable.model';

export interface PickUpAvailabilityChecked {
  error?: string;
  message: string;
  constrains: {
    at_hours: number;
    at_minutes: number;
    generic_address: { zip: number; admin_level_0: string };
    lower_date: string;
    lower_time: string;
    provider: string;
    upper_date: string;
    upper_time: string;
  };
}

export interface PickupPayload {
  pickup: {
    pickup_datetime: string;
    store_closetime: string;
    weight: number;
    package_count: number;
    provider: string;
    tracking_number: string;
    generic_address: {
      name: string;
      company: string;
      admin_level_0: string;
      admin_level_1: string;
      admin_level_2: string;
      admin_level_3: string;
      num_ext: string;
      num_int: string;
      street1: string;
      street2: string;
      zip: string;
      phone: string;
      email: string;
    };
    extra: {
      total_packages_amount: number;
      total_parcel_weight: number;
      references: string;
    };
  };
}

export interface PickUpAvailabilityCheck {
  provider: string;
  generic_address: {
    zip: number;
  };
}

export interface PickupListItem extends BaseEntityFields {
  cancelled_at: string;
  confirmation_number: number;
  package_count: number;
  pickup_datetime: string;
  provider: string;
  status: string;
  store_closetime: string;
  weight: number;
}

export class DatatablePickups implements DatatableResponse {
  meta: DatatableMeta;
  data: PickupListItem[];
}

export interface PickupsLabel {
  id: number;
  tracking_number: string;
}

export interface PickupsLabels {
  data: PickupsLabel[];
}

export const ParsePickupsParams = (page: number): HttpParams => {
  let params = new HttpParams();

  if (page && page !== 1) {
    params = params.set('page', page.toString());
  }

  return params;
};
