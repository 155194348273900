<div class="menu-item" [ngClass]="isOpen ? 'is-open' : ''">
  <div class="flex align-items-center">
    <a class="link flex-fill"
      [ngClass]="(currentUl == '/cancellations/0' || currentUl == '/cancellations/1') && item.text == 'Mis Cancelaciones' ? 'is-active' : ''"
      [routerLink]="item.url" [routerLinkActive]="item.url !== '' ? 'is-active' : ''"
      [routerLinkActiveOptions]="{exact: true}" *ngIf="item.url !== ''">
      <div class="icon">
        <i [ngClass]="item.icon"></i>
      </div>
      <div *ngIf="!small" class="text flex-fill">
        {{ item.text }}
      </div>
    </a>

    <a class="link flex-fill" [ngClass]="small ? 'small' : ''" *ngIf="item.url === ''" (click)="clickAction(item)">
      <div class="icon">
        <i [ngClass]="item.icon"></i>
      </div>
      <div *ngIf="!small" class="text flex-fill">
        {{ item.text }}
      </div>
    </a>
    <div class="cursor-pointer color-white px-2" *ngIf="hasChildren && !isOpen" (click)="showChildren(true)">
      <i class="fas fa-chevron-down"></i>
    </div>
    <div class="cursor-pointer color-white px-2" *ngIf="hasChildren && isOpen" (click)="showChildren(false)">
      <i class="fas fa-chevron-up"></i>
    </div>
  </div>
  <div class="menu-item-children" *ngIf="hasChildren && isOpen">
    <a class="link" [ngClass]="small ? 'small' : ''" [routerLink]="child.url" routerLinkActive="is-active"
      *ngFor="let child of item.children">
      <div class="icon">
        <i [ngClass]="child.icon"></i>
      </div>
      <div *ngIf="!small" class="text">
        <div>{{ child.text }}</div>
      </div>
    </a>
  </div>
</div>