import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@commons/modules/overlay/overlay.module';
import { ModalWithContentComponent } from './modal-with-content.component';

@NgModule({
  declarations: [ModalWithContentComponent],
  exports: [ModalWithContentComponent],
  imports: [CommonModule, OverlayModule],
})
export class ModalWithContentModule {}
