import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxZendeskWebwidgetService } from 'ngx-zendesk-webwidget';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'commons-zendesk',
  template: '',
})
export class ZendeskComponent implements OnInit, OnDestroy {
  constructor(
    private readonly authService: AuthService,
    private zendeskService: NgxZendeskWebwidgetService,
  ) {}

  async ngOnInit(): Promise<void> {
    const { full_name: name, contact_email: email } = this.authService.userInfo;

    await this.zendeskService.initZendesk();
    this.zendeskService.zE('webWidget', 'identify', {
      name,
      email,
    });

    this.zendeskService.zE('webWidget', 'show');
  }

  ngOnDestroy(): void {
    this.zendeskService.zE('webWidget', 'logout');
    this.zendeskService.zE('webWidget', 'hide');
  }
}
