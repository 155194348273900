import { Component, OnInit } from '@angular/core';
import { SettingsService } from '@commons/services/settings.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'admin-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Admin Console';

  constructor(private readonly settingsService: SettingsService) {}

  ngOnInit(): void {
    this.settingsService.language = environment.featureFlags.language;
  }
}
