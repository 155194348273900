<mat-card class="width-max-content">
  <div class="px-12 px-xs-2 pt-8">
    <div class="flex justify-content-center mb-8">
      <h1>
        {{ translation.title }}
      </h1>
    </div>
    <form class="flex flex-column" [formGroup]="form" (ngSubmit)="onSubmit()">
      <mat-form-field>
        <mat-label>
          {{ translation.labels.email }}
        </mat-label>
        <input
          matInput
          formControlName="email"
          required
          [errorStateMatcher]="matcher"
          name="email"
          type="email"
          min="4"
          max="100"
        />
        <mat-error
          class="mb-2"
          *ngIf="
            form.controls.email.errors && form.controls.email.errors.pattern
          "
        >
          {{ translation.errors.emailValid }}
        </mat-error>
        <mat-error
          class="mb-2"
          *ngIf="
            form.controls.email.errors && form.controls.email.errors.required
          "
        >
          {{ translation.errors.emailRequired }}
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>
          {{ translation.labels.password }}
        </mat-label>
        <input
          matInput
          required
          formControlName="password"
          [type]="showPassword ? 'text' : 'password'"
          [errorStateMatcher]="matcher"
          min="8"
          max="24"
          name="password"
        />
        <div
          matSuffix
          class="input-icon color-grey-light-1 pointer"
          (click)="toggleShowPassword()"
          *ngIf="!showPassword"
        >
          <i class="cursor-pointer fas fa-eye pr-2"></i>
        </div>
        <div
          matSuffix
          class="input-icon color-grey-light-1 pointer"
          (click)="toggleShowPassword()"
          *ngIf="showPassword"
        >
          <i class="cursor-pointer fas fa-eye-slash pr-2"></i>
        </div>
        <mat-error
          class="mb-2"
          *ngIf="
            form.controls.password.errors &&
            form.controls.password.errors.required
          "
        >
          {{ translation.errors.passwordRequired }}
        </mat-error>
        <mat-error
          class="mb-2"
          *ngIf="
            form.controls.password.errors &&
            form.controls.password.errors.pattern
          "
        >
          {{ translation.errors.passwordPattern }}
        </mat-error>
      </mat-form-field>
      <div class="flex align-items-center mt-4 mb-8">
        <div class="mx-5">
          <a class="action" routerLink="/auth/recover-password">
            {{ translation.links.recoverPassword }}
          </a>
        </div>
      </div>
      <div class="mb-4">
        <button
          mat-raised-button
          class="width-full btn secondary"
          type="submit"
          [disabled]="form.valid && !disableButton ? null : true"
        >
          {{ translation.buttons.signIn }}
        </button>
      </div>
      <div class="flex justify-content-center mb-8">
        <a class="action" routerLink="/auth/register">
          {{ translation.links.signUp }}
        </a>
      </div>
    </form>
  </div>
</mat-card>
