import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutHeaderComponent } from './layout-header.component';
import { MatButtonModule } from '@angular/material/button';
import { ClickOutsideHeaderActionsDirective } from './click-outside-directive';
import { RouterModule } from '@angular/router';
import { CreditsModule } from '@commons/modules/credits';

@NgModule({
  declarations: [LayoutHeaderComponent, ClickOutsideHeaderActionsDirective],
  exports: [LayoutHeaderComponent],
  imports: [CommonModule, MatButtonModule, RouterModule, CreditsModule],
})
export class LayoutHeaderModule {}
