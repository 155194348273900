import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import { CustomErrorStateMatcher, RegexEmail } from '@commons/helpers/index';
import { SnotifyService } from 'ng-snotify';
import { AuthService } from '../auth.service';
import { LANG, Translation } from './auth-validate.translation';
import { ConfirmationModalService } from '@commons/modules/modals';
import { first, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'commons-auth-validate',
  templateUrl: './auth-validate.component.html',
  styleUrls: ['./auth-validate.component.scss'],
})
export class AuthValidateComponent implements OnInit {
  /**
   *
   */
  public matcher = new CustomErrorStateMatcher();

  /**
   *
   */
  form: FormGroup;

  /**
   * Flag to show or hide password
   */
  public showPassword = false;

  public translation: Translation;

  public disableButton: boolean;

  public tempPhoneNumber: string;
  public tempCode: string;
  private destroyed$ = new Subject<void>();


  constructor(
    private readonly router: Router,
    private readonly fb: FormBuilder,
    private readonly authService: AuthService,
    @Inject('LANGUAGE') readonly language: string,
    private readonly snotifyService: SnotifyService,
    private readonly confirmationModalService: ConfirmationModalService,
  ) {
    this.translation = LANG[language];
  }

  ngOnInit(): void {
    this.buildForm();
    console.log(this.authService.shared_phone_number);
  }

  /**
   *
   */
  public buildForm(): void {
    this.form = this.fb.group({
      // phone_number: ['', [Validators.required]],
      code: ['', [Validators.required]],
      // remember_me: [0],
    });
  }

  /**
   *
   */
  public onSubmit(): void {
    if (!this.form.valid) {
      return;
    }
    this.disableButton = true;
    const json = {
      phone_number: this.authService.shared_phone_number,
      code: this.form.value.code
    };

    this.authService.validate_code(json).subscribe(
      () => {
        this.confirmationModalService
              .understandModal(
                this.translation.noApprovalModal,
              )
              .pipe(takeUntil(this.destroyed$), first())
              .subscribe(() => {
                this.disableButton = false;
                this.confirmationModalService.close();
                this.disableButton = false;
                this.goToDashboard();
              });
      },
      ({ error }) => {
        this.disableButton = false;
        this.snotifyService.error(
          error.error,
        );
      },
    );
  }

  /**
   *
   */
  private goToDashboard(): void {
    this.router.navigateByUrl('/');
  }

  public resendCode(): void {
    this.disableButton = true;
    const data = {
      phone_number: this.authService.shared_phone_number,
    };

    this.authService.resend_code(data).subscribe(
      () => {
        this.confirmationModalService
              .understandModal(
                this.translation.resendCode,
              )
              .pipe(takeUntil(this.destroyed$), first())
              .subscribe(() => {
                this.disableButton = false;
                this.confirmationModalService.close();
              });
      },
      ({ error }) => {
        this.disableButton = false;
        this.snotifyService.error(
          error.error,
        );
      },
    );
  }

  /**
   *
   */
  public toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }
}
