import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { PipesModule } from '@commons/modules/pipes';
import { AuthRecoverPasswordComponent } from './auth-recover-password.component';

@NgModule({
  declarations: [AuthRecoverPasswordComponent],
  exports: [AuthRecoverPasswordComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    PipesModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
  ],
})
export class AuthRecoverPasswordModule {}
