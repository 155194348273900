import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingBarService } from '@commons/modules/loading-bar/loading-bar.service';
import { OverlayService } from '@commons/modules/overlay/overlay.service';
import { ErrorHandlerService } from '@commons/services/index';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';

/**
 * This Interceptor is the first handler in the interception barrel
 *
 * This handles errors, and client-facing interactions, such as:
 *  - The loading bar,
 *  - The HTTP error handling
 */
@Injectable({
  providedIn: 'root',
})
export class EventHandlerInterceptor implements HttpInterceptor {
  constructor(
    private readonly loadingBarService: LoadingBarService,
    private readonly overlayService: OverlayService,
    private readonly errorHandlerService: ErrorHandlerService,
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (!request.headers.get('isSilent')) {
      this.loadingBarService.show();
      this.overlayService.show();
    }

    return next.handle(request).pipe(
      map((event) => {
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        this.errorHandlerService.throw(error);
        return throwError(error);
      }),
      finalize(() => {
        this.overlayService.hide();
        this.loadingBarService.hide();
      }),
    );
  }
}
