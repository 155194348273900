import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAddressesResponse } from '@commons/models/orders.model';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { APIRoutes, GenericAddress, RoutesGroup, ZipCodeData } from '../models';
import { environment } from '../../../../admin/src/environments/environment.es'

@Injectable({
  providedIn: 'root',
})
export class AddressesService {
  private route: RoutesGroup;

  public addresBeingEdited: GenericAddress;

  constructor(private readonly http: HttpClient) {
    this.route = new APIRoutes().AddressBooks;
  }

  public getAll(queryData?: {
    [key: string]: string | number;
  }): Observable<IAddressesResponse> {
    let params = new HttpParams();

    if (queryData) {
      params = Object.entries(queryData).reduce(
        (acc, [key, val]) => (val ? acc.append(key, String(val)) : acc),
        params,
      );
    }

    return this.http.get<IAddressesResponse>(this.route.All, {
      params,
    });
  }

  public getId(id: number): Observable<GenericAddress> {
    return this.http
      .get<{ data: GenericAddress }>(this.route.Entity(id))
      .pipe(pluck('data'));
  }

  public create(address: GenericAddress): Observable<unknown> {
    return this.http.post(this.route.All, address);
  }

  public update(id: number, address: GenericAddress): Observable<unknown> {
    return this.http.patch(this.route.Entity(id), address);
  }

  public delete(id: number): Observable<unknown> {
    return this.http.delete(this.route.Entity(id));
  }

  public getCountries(): Observable<[name: string, id: string]> {
    return this.http.get<[name: string, id: string]>(
      environment.baseUrl + '/api/v1/addresses/countries',
    );
  }

  public getPostalCodeData(zip: number | string): Observable<ZipCodeData> {
    return this.http.get<ZipCodeData>(environment.baseUrl + `/api/v1/postal_codes/${zip}`);
  }

  public getStates(): Observable<{
    [key: string]: [name: string, id: string][];
  }> {
    return this.http.get<{ [key: string]: [name: string, id: string][] }>(
      environment.baseUrl + '/api/v1/addresses/states',
    );
  }
}
