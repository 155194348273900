import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import { CustomErrorStateMatcher, RegexEmail } from '@commons/helpers/index';
import { SnotifyService } from 'ng-snotify';
import { AuthService } from '../auth.service';
import { LANG, Translation } from './auth.login.translation';

@Component({
  selector: 'commons-auth-login',
  templateUrl: './auth-login.component.html',
  styleUrls: ['./auth-login.component.scss'],
})
export class AuthLoginComponent implements OnInit {
  /**
   *
   */
  public matcher = new CustomErrorStateMatcher();

  /**
   *
   */
  form: FormGroup;

  /**
   * Flag to show or hide password
   */
  public showPassword = false;

  public translation: Translation;

  public disableButton: boolean;

  constructor(
    private readonly router: Router,
    private readonly fb: FormBuilder,
    private readonly authService: AuthService,
    @Inject('LANGUAGE') readonly language: string,
    private readonly snotifyService: SnotifyService,
  ) {
    this.translation = LANG[language];
  }

  ngOnInit(): void {
    this.buildForm();
  }

  /**
   *
   */
  public buildForm(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(RegexEmail)]],
      password: ['', [Validators.required]],
      remember_me: [0],
    });
  }

  /**
   *
   */
  public onSubmit(): void {
    if (!this.form.valid) {
      return;
    }
    this.disableButton = true;
    this.authService.login(this.form.value).subscribe(
      () => {
        this.disableButton = false;
        this.goToDashboard();
      },
      ({ error }) => {
        this.disableButton = false;
        if (error.errors['email o password']) {
          this.snotifyService.error(
            this.translation.notifications.invalidEmailOrPassword,
          );
        } else {
          this.snotifyService.error(
            this.translation.notifications.genericError,
          );
        }
      },
    );
  }

  /**
   *
   */
  private goToDashboard(): void {
    this.router.navigateByUrl('/');
  }

  /**
   *
   */
  public toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }
}
