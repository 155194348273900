<div>
  <mat-card>
    <div class="p-3">
      <h3>{{ translation.form.verifyTitle }}</h3>
    </div>
    <div class="divider-h is-gray"></div>
    <div class="p-3">
      <form class="g" [formGroup]="form" (ngSubmit)="onSubmit()">
        <mat-form-field class="g-6 mb-8 pr-3">
          <mat-label>
            {{ translation.form.labels.courier }}
          </mat-label>
          <mat-select
            formControlName="provider"
            required
            name="provider"
            [errorStateMatcher]="matcher"
          >
            <mat-option
              *ngFor="let option of availableCouriers"
              [value]="option"
            >
              {{ getProviderName(option) }}
            </mat-option>
          </mat-select>
          <mat-error
            class="mb-2"
            *ngIf="
              form.controls.provider.errors &&
              form.controls.provider.errors.required
            "
          >
            {{ translation.form.errors.carrierRequired }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="g-6 mb-8 pl-3">
          <mat-label>
            {{ translation.form.labels.zip }}
          </mat-label>
          <input
            matInput
            formControlName="zip"
            required
            [errorStateMatcher]="matcher"
            [placeholder]="translation.form.placeholders.zip"
            type="number"
            max="1000000"
          />
          <mat-error
            class="mb-2"
            *ngIf="
              form.controls.zip.errors && form.controls.zip.errors.required
            "
          >
            {{ translation.form.errors.zipRequired }}
          </mat-error>
          <mat-error
            class="mb-2"
            *ngIf="
              form.controls.zip.errors &&
              (form.controls.zip.errors.minLength || form.controls.zip.errors.max ||
                form.controls.zip.errors.maxLength)
            "
          >
            {{ translation.form.errors.zipLength }}
          </mat-error>
        </mat-form-field>
        <button
          mat-raised-button
          class="width-full g-2 btn primary"
          type="submit"
          [disabled]="form.valid ? null : true"
        >
          {{ translation.form.buttons.validate }}
        </button>
      </form>
    </div>
  </mat-card>
</div>
