import { MatPaginatorIntl } from '@angular/material/paginator';

const PARSER = {
  EN: {
    itemsPerPageLabel: 'Items per page',
    nextPageLabel: 'Next page',
    previousPageLabel: 'Previous page',
    connector: 'of',
  },
  ES: {
    itemsPerPageLabel: 'Registros por página:',
    nextPageLabel: 'Próxima página',
    previousPageLabel: 'Página anterior',
    connector: 'de',
  },
};

export const getPaginatorI18nIntl = (language: string) => {
  const paginatorIntl = new MatPaginatorIntl();

  paginatorIntl.itemsPerPageLabel = PARSER[language].itemsPerPageLabel;
  paginatorIntl.nextPageLabel = PARSER[language].nextPageLabel;
  paginatorIntl.previousPageLabel = PARSER[language].previousPageLabel;
  paginatorIntl.getRangeLabel = (
    page: number,
    pageSize: number,
    length: number,
  ) => {
    if (length === 0 || pageSize === 0) {
      return `0 ${PARSER[language].connector} ${length}`;
    }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} ${
      PARSER[language].connector
    } ${length}`;
  };

  return paginatorIntl;
};
