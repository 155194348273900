import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingBarService } from './loading-bar.service';

@Component({
  selector: 'commons-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss']
})
export class LoadingBarComponent implements OnInit, OnDestroy {
  /**
   * Loading's flag
   */
  public loading = false;

  private subscription = Subscription.EMPTY;

  constructor(private readonly loadingBarService: LoadingBarService) {}

  ngOnInit(): void {
    this.subscription = this.loadingBarService.loadingStatus$.subscribe(
      status => (this.loading = status)
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
