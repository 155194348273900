/**
 * This file:
 *
 *  - is only for development purposes
 *  - should not have anything different than any of the .es or .en environments.
 *  - should only be modified to test any of the available environments locally
 *
 */

import { environment as myCustomEnvironment } from './environment.es';
// import { environment as myCustomEnvironment } from './environment.en';
import { Environment } from '@commons/models/environment.model';

export const environment: Environment = myCustomEnvironment;
