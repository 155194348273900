import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidemodalComponent } from './sidemodal.component';
import { OverlayModule } from '@commons/modules/overlay/overlay.module';

@NgModule({
  declarations: [SidemodalComponent],
  exports: [SidemodalComponent],
  imports: [CommonModule, OverlayModule],
})
export class SidemodalModule {}
