import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { DatatablePickups, ParsePickupsParams } from '@commons/models/index';
import { Subscription } from 'rxjs';
import { CommonPickupsService } from './common-pickups.service';
import {
  CommonPickupsLanguages,
  CommonPickupsTranslation,
} from './common-pickups.translation';

@Component({
  selector: 'commons-common-pickups',
  templateUrl: './common-pickups.component.html',
  styleUrls: ['./common-pickups.component.scss'],
})
export class CommonPickupsComponent implements OnInit, OnDestroy {
  public readonly translation: CommonPickupsTranslation =
    CommonPickupsLanguages[this.language];

  public datatableInfo: DatatablePickups;

  private page = 1;

  public tableColumns = [
    'source',
    'confirmation',
    'address_to',
    'date',
    'status',
  ];

  public tableSubscription = Subscription.EMPTY;

  constructor(
    @Inject('LANGUAGE')
    private readonly language: keyof typeof CommonPickupsLanguages,
    private readonly router: Router,
    private readonly commonPickupsService: CommonPickupsService,
  ) {}

  ngOnInit(): void {
    this.onLoad();
  }

  ngOnDestroy(): void {
    this.tableSubscription.unsubscribe();
  }

  private onLoad(): void {
    this.tableSubscription = this.commonPickupsService
      .paginate(ParsePickupsParams(this.page))
      .subscribe(datatableInfo => {
        this.datatableInfo = datatableInfo;
      });
  }

  public onCreatePickup(): void {
    this.router.navigateByUrl('/pickups/new');
  }

  public onPageChange({ pageIndex }: PageEvent): void {
    this.page = pageIndex + 1;
    this.onLoad();
  }
}
