<div>
  <div class="card-header">
    <div class="font-weight-700">{{ translation.form.data.title }}</div>
  </div>
  <div class="card-body">
    <small class="color-red">{{ translation.labels.required }}</small>
    <form [formGroup]="dataForm" *ngIf="dataForm" class="mt-6 g" (ngSubmit)="onDataSubmit()">
      <mat-form-field class="mb-4 pr-4 g-4">
        <mat-label>
          {{ translation.labels.data.email }}
        </mat-label>
        <input matInput required readonly type="email" [errorStateMatcher]="matcher" formControlName="email" />
        <mat-error class="mb-2" *ngIf="
            dataForm.controls.email.errors &&
            dataForm.controls.email.errors.pattern
          ">
          {{ translation.errors.data.emailValid }}
        </mat-error>
        <mat-error class="mb-2" *ngIf="
            dataForm.controls.email.errors &&
            dataForm.controls.email.errors.required
          ">
          {{ translation.errors.data.emailRequired }}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="mb-4 px-4 g-4">
        <mat-label>
          {{ translation.labels.data.name }}
        </mat-label>
        <input matInput required minlength="2" maxlength="50" [errorStateMatcher]="matcher"
          formControlName="full_name" />
        <mat-error class="mb-2" *ngIf="
            dataForm.controls.full_name.errors &&
            dataForm.controls.full_name.errors.required
          ">
          {{ translation.errors.data.nameRequired }}
        </mat-error>
        <mat-error class="mb-2" *ngIf="
            dataForm.controls.full_name.errors &&
            (dataForm.controls.full_name.errors.minlength ||
              dataForm.controls.full_name.errors.maxlength)
          ">
          {{ translation.errors.data.nameLength }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="mb-4 pl-4 g-4">
        <mat-label>
          {{ translation.labels.data.phone }}
        </mat-label>
        <input matInput required minlength="10" maxlength="10" [errorStateMatcher]="matcher"
          formControlName="contact_number" />
        <mat-error class="mb-2" *ngIf="
            dataForm.controls.contact_number.errors &&
            dataForm.controls.contact_number.errors.required
          ">
          {{ translation.errors.data.phoneRequired }}
        </mat-error>
        <mat-error class="mb-2" *ngIf="
            dataForm.controls.contact_number.errors &&
            dataForm.controls.contact_number.errors.pattern
          ">
          {{ translation.errors.data.phonePattern }}
        </mat-error>
        <mat-error class="mb-2" *ngIf="
            dataForm.controls.contact_number.errors &&
            (dataForm.controls.contact_number.errors.minlength ||
              dataForm.controls.contact_number.errors.maxlength)
          ">
          {{ translation.errors.data.phoneLength }}
        </mat-error>
      </mat-form-field>
      <div class="pr-4">
        <button mat-raised-button color="primary" class="width-full btn primary" type="submit"
          [disabled]="dataForm.valid ? null : true">
          {{ translation.buttons.save }}
        </button>
      </div>
    </form>
  </div>
  <div class="card-header mt-12">
    <div class="font-weight-700">{{ translation.form.data.passwordTitle }}</div>
  </div>
  <div class="card-body">
    <small class="color-red">{{ translation.labels.required }}</small>
    <form [formGroup]="passwordForm" *ngIf="passwordForm" class="g" (ngSubmit)="onPasswordSubmit()">
      <mat-form-field class="mb-8 g-4 pr-4">
        <mat-label> {{ translation.labels.data.password }} </mat-label><input matInput required
          formControlName="password" [type]="showPassword ? 'text' : 'password'" [errorStateMatcher]="matcher" min="8"
          max="24" name="password" />
        <div matSuffix class="input-icon color-grey-light-1 pointer" (click)="toggleShowPassword()"
          *ngIf="!showPassword">
          <i class="cursor-pointer fas fa-eye pr-2"></i>
        </div>
        <div matSuffix class="input-icon color-grey-light-1 pointer" (click)="toggleShowPassword()"
          *ngIf="showPassword">
          <i class="cursor-pointer fas fa-eye-slash pr-2"></i>
        </div>
        <mat-error class="mb-2" *ngIf="
            passwordForm.controls.password.errors &&
            passwordForm.controls.password.errors.required
          ">
          {{ translation.errors.data.passwordRequired }}
        </mat-error>
        <mat-hint class="mb-2">
          {{ translation.errors.data.passwordPattern }}
        </mat-hint>
      </mat-form-field>
      <mat-form-field class="mb-8 g-4 px-4">
        <mat-label>
          {{ translation.labels.data.confirmPassword }}
        </mat-label>
        <input matInput required [type]="showConfirmPassword ? 'text' : 'password'" [errorStateMatcher]="matcher"
          type="password" name="confirmPassword" formControlName="confirmPassword" />
        <div matSuffix class="input-icon color-grey-light-1 pointer" (click)="toggleShowConfirmPassword()"
          *ngIf="!showConfirmPassword">
          <i class="cursor-pointer fas fa-eye pr-2"></i>
        </div>
        <div matSuffix class="input-icon color-grey-light-1 pointer" (click)="toggleShowConfirmPassword()"
          *ngIf="showConfirmPassword">
          <i class="cursor-pointer fas fa-eye-slash pr-2"></i>
        </div>
      </mat-form-field>
      <div class="g-4"></div>
      <div class="g-4"></div>
      <div class="g-4 pl-4">
        <mat-error class="custom-error" style="margin-top: -46px" *ngIf="passwordForm.hasError('isPasswordUnmatched')"
          [ngClass]="
            passwordForm.hasError('isPasswordUnmatched') ? 'mb-4' : null
          ">
          {{ translation.errors.data.passwordUnmatched }}
        </mat-error>
      </div>
      <div class="g-4"></div>
      <div class="g-4"></div>
      <small class="g-12 mb-2">{{ translation.logout }}</small>
      <div class="g-12 g">
        <div class="pr-4">
          <button mat-raised-button color="primary" class="width-full btn primary" type="submit"
            [disabled]="passwordForm.valid ? null : true">
            {{ translation.buttons.save }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>