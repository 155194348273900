<commons-common-page
  [title]="translation.page.title"
  [subtitle]="translation.page.emtpyPickups"
  [description]="translation.page.emtpyPickupsDescription"
></commons-common-page>
<div class="wrap">
  <div class="mb-2">{{ translation.disclaimer }}</div>
</div>
<commons-tutorial-sliders [elements]="emptyValues"></commons-tutorial-sliders>
<div class="flex justify-content-center mt-16">
  <a routerLink="/pickups/new">
    <button mat-raised-button class="btn primary" type="button">
      <div class="px-4">{{ translation.page.create }}</div>
    </button>
  </a>
</div>
