import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  AddressFrom,
  AddressTo,
  APIRoutes,
  GenericAddress,
  Label,
  LabelsResponse,
  LabelState,
  Parcel,
  ParcelsFormType,
  Rate,
} from '@commons/models/index';
import { CreditsService } from '@commons/modules/credits';
import { AddressesService } from '@commons/services/addresses.service';
import { SnotifyService } from 'ng-snotify';
import {
  BehaviorSubject,
  Observable,
  of,
  Subject,
  throwError,
  zip,
} from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import {
  NewOrderLanguages,
  NewOrderTranslation,
} from './new-order.translation';
import { environment } from '../../../../../../../admin/src/environments/environment.es';

@Injectable({
  providedIn: 'root',
})
export class NewOrderService {
  private readonly route = new APIRoutes().Orders;

  public readonly continueButton$ = new Subject<any>();
  public readonly click$ = new Subject<any>();

  public readonly goToNextStep$ = new Subject<void>();

  public label$ = new BehaviorSubject<
    (Label & { state: LabelState }) | { state: LabelState; error?: any }
  >(null);

  public addresses: {
    address_from: AddressFrom;
    address_to: AddressTo;
  } = null;
  private parcels: Parcel[];
  private labels: Rate[];

  private translation: NewOrderTranslation = NewOrderLanguages[this.language];
  public isDuplicate: boolean;
  public duplicateId: number;
  public shipmentId: number;

  public sentParcels: any;
  public contents: any;

  declared_cost: any;

  constructor(
    private readonly http: HttpClient,
    private readonly creditsService: CreditsService,
    @Inject('LANGUAGE')
    private readonly language: keyof typeof NewOrderLanguages,
    private readonly addressesService: AddressesService,
    private readonly snotifyService: SnotifyService,
  ) { }

  /**
   * Handler for when the main form button is clicked
   */
  public clickButton(): void {
    this.click$.next(null);
  }

  /**
   * Store the addresses to obtain prices later
   * @param addresses Contains the from and to addresses
   */
  public saveAddresses(addresses: {
    address_from: AddressFrom;
    address_to: AddressTo;
  }): void {
    this.addresses = addresses;
  }

  public saveToMyAddresses(
    addressesToSave: GenericAddress[],
  ): Observable<{ type_of: GenericAddress['type_of']; success: boolean }[]> {
    return zip(
      ...addressesToSave.map((address) =>
        this.addressesService.create(address).pipe(
          map(() => ({ success: true })),
          catchError(({ error }) => {
            const formName = this.translation.formNames[
              address.type_of === 'to' ? 'destination' : 'origin'
            ];
            if (error?.errors) {
              for (const key in error.errors) {
                if (error.errors.hasOwnProperty(key)) {
                  error.errors[key].forEach((specificError) =>
                    this.snotifyService.error(`${formName}: ${specificError}`),
                  );
                }
              }
            } else {
              this.snotifyService.error(
                this.translation.notifications.error(formName),
              );
            }
            return of({ success: false });
          }),

          map(({ success }) => ({ type_of: address.type_of, success })),
        ),
      ),
    );
  }

  /**
   * Update the parcels and request the available rates for the addresses and package
   * @param parcels Items that will be shipped
   */
  public saveParcels(
    parcels: ParcelsFormType,
    saveToMyDimensions = false,
    declared_cost: any,
    
  ): void {
    this.parcels = [];
    this.declared_cost = declared_cost;
    for (let i = 0; i < parcels.quantity; i++) {
      const { length, height, weight, width, unit, product_type, contents} = parcels;
      this.parcels.push({
        length: +length,
        height: +height,
        weight: +weight,
        width: +width,
        distance_unit: this.translation.units.distance.toUpperCase(),
        mass_unit: this.translation.units.mass.toUpperCase(),
        unit: unit,
        contents: this.addresses.address_to.contents,
        product_type: product_type
      });
    }

    if (saveToMyDimensions) {
      this.saveToMyDimensions(parcels).subscribe();
    }

    this.getLabels().subscribe((shipment) => {
      shipment.rates.forEach((s: any) => {
        switch (s.service_level_name) {
          case 'International Economy':
            s.service_level_name = 'International Economy'
            s.days = '5'
            break;
          case 'International Priority':
            s.service_level_name = 'International Priority'
            s.days = '3'
            break;
          case 'Fedex Express Saver':
            s.service_level_name = 'Envío Ecónomico'
            s.days = '3 a 5'
            break;
          case 'Terrestre':
              s.service_level_name = 'Envío Ecónomico'
              s.days = '3 a 5'
              break;
          case 'Standard Overnight':
            s.service_level_name = 'Envío Express'
            s.days = '1 a 3'
            break;
          case 'Ecoexpress':
            s.service_level_name = 'Envío Ecónomico'
            s.days = '3 a 5'
            break;
          case 'UPS Express':
            s.service_level_name = 'Envío Ecónomico'
            s.days = '3 a 5'
            break;
          case 'Servicio Express':
            s.service_level_name = 'Envío Express'
            s.days = '1 a 3'
            break;
          case 'Express':
            s.service_level_name = 'Envío Express'
            s.days = '1 a 3'
            break;
          case '99MINUTOS':
            s.service_level_name = '99Minutos'
            break;
          case 'PROXIMO DIA':
            s.service_level_name = 'Próximo día'
            break;
          
          default:
            break;
        }
      })
      this.labels = shipment.rates;
      this.shipmentId = shipment.id;
      this.goToNextStep$.next();
    });
  }

  /**insurance: false,
   * Return the labels for the address and parcel
   */
  public getAvailableRates(): Rate[] {
    return this.labels;
  }

  /**
   * Obtain the available shipment labels for the accumulated addresses and parcel
   */
  public getLabels(): Observable<LabelsResponse['shipment']> {
    return this.http
      .post<LabelsResponse>(environment.baseUrl +'/api/v1/shipments', {
        ...this.addresses,
        parcels: this.parcels,
        ...this.declared_cost,
        has_insurance: this.declared_cost ? true : ''
      })
      .pipe(map((e) => e.shipment));
  }

  /**
   * Create a shipment with the existent accumulated data
   */
  public placeShipment(rate_id: number, declared_cost = 0): Observable<any> {
    this.label$.next({ state: 'loading' });
    this.goToNextStep$.next();

    return (
      this.http
        // TODO: Remove ANY
        // QUE ES ESTO DAVID
        .post<{ label: Label }>(environment.baseUrl + '/api/v1/labels/', {
          rate_id,
          label_format: 'pdf',
          declared_cost,
        })
        .pipe(
          tap(({ label, errors }: { label: Label; errors: any }): void => {
            if (errors) {
              this.label$.next({
                state: 'error',
                error: { error: { message: errors } },
              });
              throwError(errors);
              return;
            }
            this.label$.next({
              ...label,
              state: 'success',
            });
            this.creditsService.creditsWereUpdated.next();
          }),
          catchError((error) => {
            this.label$.next({ state: 'error', error });
            console.log('catching');
            return throwError(error);
          }),
        )
    );
  }

  public duplicateShipment(id: number, body?: any): Observable<Rate[]> {
    return this.http
      .post<{ shipment: { rates: Rate[]; id: number } }>(
        this.route.Custom([id.toString(), 'duplicate']),
        body,
      )
      .pipe(
        tap((response) => (this.shipmentId = response.shipment.id)),
        map((response) => response.shipment.rates),
      );
  }

  public saveToMyDimensions({
    quantity,
    ...dimensions
  }: ParcelsFormType): Observable<unknown> {
    const name = this.buildDimensionsName(dimensions);

    return this.http.post(environment.baseUrl + '/api/v1/package_templates/', {
      ...dimensions,
      name,
      category: 'custom',
      is_default: true,
      distance_unit: this.translation.units.distance.toUpperCase(),
      mass_unit: this.translation.units.mass.toUpperCase(),
    });
  }

  private buildDimensionsName({
    length,
    width,
    height,
    weight,
  }: Partial<ParcelsFormType>): string {
    const { distance: d, mass: m } = this.translation.unitStrings;
    return `${d(length)} x ${d(width)} x ${d(height)} · ${m(weight)}`;
  }

  /**
   * Removes the existent progress in the form to prevent old values from persisting
   */
  public wipeData(): void {
    this.addresses = null;
    this.parcels = [];
    this.labels = [];
  }
}
