import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  APIRoutes,
  DatatablePickups,
  PickUpAvailabilityCheck,
  PickUpAvailabilityChecked,
  PickupPayload,
  PickupListItem,
  PickupsLabels,
} from '@commons/models/index';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommonPickupsService {
  private readonly routes = new APIRoutes();

  constructor(private readonly http: HttpClient) {}

  public paginate(params: HttpParams): Observable<DatatablePickups> {
    return this.http.get<DatatablePickups>(this.routes.Pickups.All, { params });
  }

  public verify(
    payload: PickUpAvailabilityCheck,
  ): Observable<PickUpAvailabilityChecked> {
    return this.http.post<PickUpAvailabilityChecked>(
      this.routes.PickupAvailability.Custom('check'),
      payload,
    );
  }

  public store(payload: PickupPayload): Observable<void> {
    return this.http.post<void>(this.routes.Pickups.All, payload);
  }

  public labels(carrier: string): Observable<PickupsLabels> {
    return this.http.get<PickupsLabels>(this.routes.Labels.Custom(carrier.toUpperCase()));
  }

  public detail(id_pickup: number | string): Observable<PickupListItem> {
    return this.http.get<PickupListItem>(this.routes.Pickups.Custom(`${id_pickup}/show`));
  }
}
