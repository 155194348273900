<commons-common-page [title]="translation.page.title" subtitle=""
  [description]="translation.page.emptyOrdersDescription"></commons-common-page>
<div class="flex justify-content-center mt-16" *ngIf="currentBalance != 0">
  <button mat-raised-button class="btn primary" type="button" (click)="onCreate()">
    <div class="px-4">{{ translation.page.create }}</div>
  </button>
</div>
<div class="flex justify-content-center mt-2">
  <button class="underlined" type="button" (click)="onSkip()">
    <div class="px-4">{{ translation.page.skip }}</div>
  </button>
</div>
<commons-tutorial-sliders [elements]="emptyValues"></commons-tutorial-sliders>