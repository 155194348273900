import { DOCUMENT } from '@angular/common';
import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Output,
} from '@angular/core';

/** @dynamic */
@Directive({
  selector: '[commonsClickOutside]',
})
export class ClickOutsideHeaderActionsDirective {
  @Output() clickOutside = new EventEmitter<void>();

  constructor(
    private elementRef: ElementRef,
    @Inject(DOCUMENT) private document: Document
  ) {}

  @HostListener('document:click', ['$event.target'])
  public onClick(target: any): void {
    const clickedOutside =
      !this.elementRef.nativeElement.contains(target) &&
      this.document.contains(target);
    if (!clickedOutside) {
      return;
    }
    this.clickOutside.emit();
  }
}
