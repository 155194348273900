import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoPickupsComponent } from './no-pickups/no-pickups.component';
import { CommonPickupsComponent } from './common-pickups.component';
import { CommonPageModule } from '../common-page/common-page.module';
import { SlidersModule } from '@commons/modules/sliders';
import { NewPickupComponent } from './new-pickup/new-pickup.component';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PickupVerifyComponent } from './pickup-verify/pickup-verify.component';
import { PickupFormComponent } from './pickup-form/pickup-form.component';

@NgModule({
  declarations: [
    NoPickupsComponent,
    CommonPickupsComponent,
    NewPickupComponent,
    PickupVerifyComponent,
    PickupFormComponent,
  ],
  imports: [
    CommonModule,
    CommonPageModule,
    FormsModule,
    ReactiveFormsModule,
    SlidersModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatCardModule,
  ],
  exports: [NoPickupsComponent, CommonPickupsComponent, NewPickupComponent],
})
export class CommonPickupsModule {}
