import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import {
  CustomErrorStateMatcher,
  passwordMatch,
  RegexEmail,
  RegexPassword,
} from '@commons/helpers/index';
import { FeatureFlags } from '@commons/models/environment.model';
import { ConfirmationModalService } from '@commons/modules/modals';
import { SnotifyService } from 'ng-snotify';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import {
  AuthRegisterLanguages,
  AuthRegisterTranslation,
} from './auth-register.translation';

@Component({
  selector: 'commons-auth-register',
  templateUrl: './auth-register.component.html',
  styleUrls: ['./auth-register.component.scss'],
})
export class AuthRegisterComponent implements OnInit, OnDestroy {
  /**
   * Error matcher for form group
   */
  public matcher = new CustomErrorStateMatcher();

  /**
   * Form for setting a new password in current component
   */
  public form: FormGroup;

  /**
   * This gets emitted when the component gets destroyed and closes subscriptions
   */
  private destroyed$ = new Subject<void>();

  /**
   * Flag to show or hide password
   */
  public showPassword = false;

  /**
   * Object for the english/spanish translations
   */
  public translation: AuthRegisterTranslation =
    AuthRegisterLanguages[this.language];

  public showConfirmation: boolean;

  public disableButton: boolean;
  public termsUrl: string;

  step:any = 'data';

  constructor(
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly confirmationModalService: ConfirmationModalService,
    @Inject('LANGUAGE')
    private readonly language: keyof typeof AuthRegisterLanguages,
    @Inject('FEATURE_FLAGS')
    private readonly featureFlags: FeatureFlags,
    private readonly snotifyService: SnotifyService,
  ) {}

  ngOnInit(): void {
    this.buildForm();

    this.termsUrl =
      this.language === 'EN'
        ? 'https://speedit.mx/#/aviso-privacidad'
        : 'https://speedit.mx/#/aviso-privacidad';
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  /**
   * Builds form values for current component
   */
  private buildForm(): void {
    this.form = this.fb.group(
      {
        password: [
          '',
          [Validators.required, Validators.pattern(RegexPassword)],
        ],
        confirmPassword: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.pattern(RegexEmail)]],
        full_name: ['', [Validators.required]],
        contact_number: [
          '',
          [
            Validators.pattern(/^\d{10}$/),
            Validators.minLength(10),
            Validators.maxLength(10),
          ],
        ],
        confirm_success_url: [0, [Validators.min(1), Validators.max(1)]],
        company: this.fb.group({
          name: ['',Validators.required],
          from_send: ['', Validators.required],
          howmany_per_month: ['', Validators.required],
          business_type: ['', Validators.required],
          howcan_we_help: ['' ,Validators.required],
          type_service: ['', Validators.required]
        })
      },
      { validator: passwordMatch },
    );
  }

  /**
   * Saves the KeepMeLogged value
   * @param event Event that supports the terms slider value have changed
   */
  public onTermsAgreedChanged({ checked }: MatSlideToggleChange): void {
    this.form.get('confirm_success_url').setValue(checked ? 1 : 0);
  }

  /**
   *
   */
  public onSubmit(e: Event): void {
    e.stopPropagation();
    e.preventDefault();
    if (!this.form.valid) {
      return;
    }
    this.createNoticeModal();
  }

  next(option: any) {
    this.step = option;
    this.form.get('confirm_success_url').setValue(1);
  }

  /**
   *
   */
  private goToLogin(): void {
    this.router.navigateByUrl('/auth/login');
  }

  private goToValidate(): void{
    this.router.navigateByUrl('/auth/validate');
  }

  private createNoticeModal(): void {
    this.disableButton = true;
    if (this.featureFlags.registrationRequiresAdminValidation) {
      this.confirmationModalService
        .understandModal(AuthRegisterLanguages[this.language].modal)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.confirmationModalService.close();
          const {
            email,
            password,
            full_name,
            contact_number,
            confirm_success_url,
            company: {
              name,
              from_send,
              howmany_per_month,
              business_type,
              howcan_we_help,
              type_service
            }
          } = this.form.value;
          this.authService
            .register({
              email,
              password,
              full_name,
              contact_number,
              confirm_success_url,
              company: {
                name,
                from_send,
                howmany_per_month,
                business_type,
                howcan_we_help,
                type_service
              }
            })
            .subscribe(() => {
              this.goToLogin();
            });
        });
    } else {
      const {
        email,
        password,
        full_name,
        contact_number,
        confirm_success_url,
        company: {
          name,
          from_send,
          howmany_per_month,
          business_type,
          howcan_we_help,
          type_service
        }
      } = this.form.value;

      this.authService
        .register({
          email,
          password,
          full_name,
          contact_number,
          confirm_success_url,
          company: {
              name,
              from_send,
              howmany_per_month,
              business_type,
              howcan_we_help,
              type_service
            }
        })
        .subscribe(
          () => {
            this.confirmationModalService
              .understandModal(
                AuthRegisterLanguages[this.language].noApprovalModal,
              )
              .pipe(takeUntil(this.destroyed$), first())
              .subscribe(() => {
                this.disableButton = false;
                this.confirmationModalService.close();
                this.authService.shared_phone_number = contact_number;
                this.goToValidate();
              });
          },
          ({ error }) => {
            this.disableButton = false;
            if (error.errors?.full_messages) {
              error.errors.full_messages.forEach((err) =>
                this.snotifyService.error(err),
              );
            }
          },
        );
    }
  }

  /**
   *
   */
  public toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }

  /**
   *
   */
  public toggleShowConfirmation(): void {
    this.showConfirmation = !this.showConfirmation;
  }
}
