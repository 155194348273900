import { DatatableMeta, DatatableResponse } from './datatable.model';
import { Label, Rate } from './orders.model';

export interface ShipmentDetails {
  id: string;
  type: string;
  status: string;
  created_at: string;
  updated_at: string;
  declared_cost?: any;
  is_international: boolean;
  parcels: null[];
  rates: Rate[];
  address_to: Addressto;
  address_from: Addressfrom;
  order?: any;
  chosen_rate: Rate;
  batch_label?: any;
  labels: Label[];
  cancel_request?: CancelRequest;
  user: any
}

interface CancelRequest {
  id: string;
  created_at: string;
  reason: string;
  status: 'reviewing' | 'accepted' | 'denied';
  updated_at: string;
}

interface Addressfrom {
  id: string;
  type: string;
  name: string;
  company: string;
  address1: string;
  address2: string;
  city: string;
  province: string;
  zip: string;
  country: string;
  phone: string;
  email: string;
  created_at: string;
  updated_at: string;
  reference?: any;
  province_code: string;
  contents?: any;
}

interface Addressto {
  id: string;
  type: string;
  name: string;
  company: string;
  address1: string;
  address2: string;
  city: string;
  province: string;
  zip: string;
  country: string;
  phone: string;
  email: string;
  created_at: string;
  updated_at: string;
  reference: string;
  province_code: string;
  contents: string;
}

export class DatatableShipments implements DatatableResponse {
  meta: DatatableMeta;
  data: ShipmentDetails[];
}
