import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { APIRoutes } from '../../models/';
import { environment } from '../../../../../admin/src/environments/environment.es'

@Injectable({
  providedIn: 'root',
})
export class CreditsService {
  private readonly routes = new APIRoutes();

  public creditsWereUpdated: Subject<void> = new Subject<void>();

  constructor(private readonly http: HttpClient) { }

  public getCredits(): Observable<any> {
    return this.http
      .get<any>(this.routes.Credits.All)
      .pipe(map(({ data }) => data));
  }

  public addCredits(paymentId: string): Observable<any> {
    return this.http
      .post(
        environment.baseUrl + '/api/v1/webhooks/payments?tokenpayment=eyJhbGciOiJIUzI1NiJ9.eyJwcm92aWRlciI6InBheXBhbCJ9.mz0zxjRv0dVz_8DkDWRXGS5KELfnEEdC_9G-WXUpfBk',
        { payment_id: paymentId },
      )
      .pipe(tap(() => this.creditsWereUpdated.next()));
  }

  public async genMercadoPago(credit_amount: number): Promise<{ url_mercadopago: string } | any> {
    const result = await this.http
      .post<{ message: any }>(
        environment.baseUrl + '/api/v1/webhooks/payments?tokenpayment=eyJhbGciOiJIUzI1NiJ9.eyJwcm92aWRlciI6Ik1lcmNhZG9QYWdvIn0.ItXJPraENpC6toZsY6tF-q-MAnMX5JU55TIPgrxYjJw',
        { credit_amount },
      ).toPromise();

    return result.message;
  }
}
