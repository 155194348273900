export class AuthRegisterTranslation {
  buttons: {
    signUp: string;
    next: string;
    back: string
  };
  labels: {
    email: string;
    password: string;
    confirmPassword: string;
    name: string;
    phone: string;
    terms: string;
    namecompany: string;
  };
  errors: {
    emailValid: string;
    emailRequired: string;
    password: string;
    passwordRequired: string;
    passwordPattern: string;
    passwordUnmatched: string;
    nameRequired: string;
    phoneRequired: string;
    phonePattern: string;
    phoneLength: string;
  };
  links: {
    terms: string;
    goBack: string;
  };
  modal: {
    title: string;
    description: string;
  };
  noApprovalModal: {
    title: string;
    description: string;
  };
  title: string;
  accountSubtitle: string;
  contactSubtitle: string;
  stepTwo: string;
}

const ES: AuthRegisterTranslation = {
  buttons: {
    signUp: `Crear Cuenta`,
    next: 'Siguiente',
    back: 'Regresar'
  },
  labels: {
    email: `Correo electrónico`,
    password: `Contraseña`,
    confirmPassword: `Confirmar contraseña`,
    name: `Nombre completo`,
    phone: `Teléfono (10 dígitos)`,
    terms: `Acepto los`,
    namecompany: 'Nombre de la empresa'
  },
  errors: {
    emailValid: `Por favor introduzca una dirección de Correo electrónico válida.`,
    emailRequired: `El campo Correo electrónico es requerido.`,
    password: `El campo nueva contraseña debe poseer de 8 a 24 caracteres, al menos 1 mayúscula, 1 número y 1 símbolo.`,
    passwordRequired: `El campo Contraseña es requerido.`,
    passwordUnmatched: `Las contraseñas no coinciden.`,
    passwordPattern: `El campo contraseña debe poseer de 8 a 24 caracteres, al menos 1
        mayúscula, 1 número y 1 símbolo.`,

    nameRequired: `El campo Nombre Completo es requerido.`,
    phoneRequired: `El campo Teléfono es requirido.`,
    phonePattern: `El campo Teléfono acepta solo números.`,
    phoneLength: `El campo Teléfono debe poseer 10 números.`,
  },
  links: {
    terms: `Términos y Políticas`,
    goBack: `Volver a Iniciar sesión`,
  },
  title: `Registrarse`,
  accountSubtitle: 'Cuenta',
  contactSubtitle: 'Contacto',
  stepTwo: 'Cuéntanos de tu empresa',
  modal: {
    title: 'Solicitud pendiente de aprobación',
    description:
      'Un administrador te contactará para confirmar que tu registro dentro de la plataforma haya sido exitoso.',
  },
  noApprovalModal: {
    title: 'Validación de cuenta',
    description:
      'Se enviará un código por SMS.<br><br>Ingresalo en la siguiente pantalla.',
  },
};

const EN: AuthRegisterTranslation = {
  buttons: {
    signUp: 'Create Account',
    next: 'Next',
    back: 'Go back'
  },
  labels: {
    email: 'Email',
    password: 'Password',
    confirmPassword: 'Confirm password',
    name: 'Full name',
    phone: 'Phone (10 digits)',
    terms: 'I agree the',
    namecompany: 'Company name'
  },
  errors: {
    emailValid: `Please insert a valid Email address.`,
    emailRequired: `Email field is required.`,
    password: `New password field must have 8 to 24 characters, at least 1 uppercase letter, 1 number and 1 special character.`,
    passwordRequired: `Password field is required.`,
    passwordUnmatched: `Password fields doesn't match.`,
    passwordPattern: `password field must present 8 to 24 characters, at least 1
        Uppercase, 1 Number and 1 Symbol.`,
    nameRequired: `Full name field is required.`,
    phoneRequired: `Phone field is required.`,
    phonePattern: `Phone field can only contain numbers.`,
    phoneLength: `Phone field can only have 10 numbers`,
  },
  links: {
    terms: 'Terms and Privacy',
    goBack: `Go to Sign In`,
  },
  title: 'Register',
  accountSubtitle: 'Account',
  contactSubtitle: 'Contact',
  stepTwo: 'About your company',
  modal: {
    title: 'Request pending of approval',
    description:
      'An administrator will contact you to confirm that your registration within the platform has been successful.',
  },
  noApprovalModal: {
    title: 'Successful Registration',
    description:
      'Thank you for creating your account with Speedit.<br><br>Your account has been created.',
  },
};

export const AuthRegisterLanguages = {
  ES,
  EN,
};
