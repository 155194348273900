import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private applicationLanguage: string;

  public get language(): string {
    return this.applicationLanguage;
  }

  public set language(language: string) {
    this.applicationLanguage = language;
  }

  constructor() {}
}
