import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { StepperService } from '@commons/services/index';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { CommonIntegrationsService } from './common-integrations.service';
import {
  CommonIntegrationsLanguages,
  CommonIntegrationsTranslation,
} from './common-integrations.translation';

@Component({
  selector: 'commons-common-integrations',
  templateUrl: './common-integrations.component.html',
  styleUrls: ['./common-integrations.component.scss', './form-container.scss'],
})
export class CommonIntegrationsComponent implements OnInit, OnDestroy {
  public currentSectionIndex = 0;

  public readonly translation: CommonIntegrationsTranslation =
    CommonIntegrationsLanguages[this.language];

  private destroyed$ = new Subject<void>();

  private stepMap: { [key in AvailableSections]: string } = {
    stores: 'add/url',
    'add/url': 'add/finish',
    'add/finish': 'stores',
  };

  constructor(
    @Inject('LANGUAGE')
    private readonly language: keyof typeof CommonIntegrationsLanguages,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly commonIntegrationsService: CommonIntegrationsService,
    private readonly stepperService: StepperService,
  ) {}

  ngOnInit(): void {
    const { code, source_type } = this.route.snapshot.queryParams;

    if (code && source_type) {
      this.stepperService.init(this.stepMap, 'add/finish');
      this.commonIntegrationsService.storeToConnect = source_type;
      this.commonIntegrationsService
        .confirmStore({ code, source_type })
        .subscribe();
    } else {
      console.log(this.router.url);
      
      this.stepperService.init(this.stepMap, 'stores');
    }

    this.stepperService.currentStep
      .pipe(takeUntil(this.destroyed$))
      .subscribe((step) => {
        this.router.navigate([step], {
          relativeTo: this.route.parent,
        });
      });

    this.commonIntegrationsService.goToNextStep$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.stepperService.goToNextStep());

    this.router.events
      .pipe(takeUntil(this.destroyed$))
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe(({ url }: NavigationEnd) => {
        this.setCurrentSectionIndex(url);
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  private setCurrentSectionIndex(url: string): void {
    this.currentSectionIndex = ['stores', 'url', 'finish'].indexOf(
      url.split('/').reverse()[0] as AvailableSections,
    );
    if (this.currentSectionIndex === -1) {
      this.router.navigateByUrl('settings/integrations/stores');
      this.currentSectionIndex = 0;
      this.stepperService.setStep('stores');
    }
    this.stepperService.setStep(
      ['stores', 'add/url', 'add/finish'][this.currentSectionIndex],
    );
  }

  public saveAndContinue(): void {
    this.commonIntegrationsService.clickButton();
  }
}

type AvailableSections = 'stores' | 'add/url' | 'add/finish';
