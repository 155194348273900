export class CommonOrdersTranslation {
  page: {
    title: string;
    emptyOrders: string;
    emptyOrdersDescription: string;
    create: string;
    skip: string;
  };
  list: {
    noResults: string;
    tableTitle: string;
    title: string;
    createOrder: string;
    simpleOrder: string;
    csvOrder: string;
    searchLabel: string;
    storeOptionsLabel: string;
    shippingOptionsLabel: string;
    shippingOptions: string[];
    from: string,
    to: string,
    fullfillmentStatus: {
      fulfilled: string;
      unfulfilled: string;
    };
    options: {
      view: string;
      hide: string;
      show: string;
      send: string;
    };
    showHidden: string;
    showActive: string;
  };
  table: {
    headers: string[];
  };
  bulk: {
    title: string;
    important: {
      title: string;
      items: string[];
    };
    question: string;
    questionDescription: string;
    items: string[];
    action: string;
    download: {
      text: string;
      link: string;
    };
  };
  emptyElements: string[];
  notifications: {
    shipment: string;
    hidden: string;
    visible: string;
    visibleOrders: string;
    hiddenOrders: string;
    simpleInsufficientFunds: string;
    bulkInsufficientFunds: string;
    sync: {
      start: string;
      complete: string;
      error: string;
      inprogress: string;
    };
  };

  fullfillmentStatus: {
    fulfilled: string;
    unfulfilled: string;
  };
  details: {
    title: string;
    client: string;
    courier: string;
    provider: string;
    type: string;
    dimensions: string;
    insurance: string;
    created: string;
    time: string;
    estimate: string;
    content: string;
    status: string;
    source: string;
    target: string;
    name: string;
    company: string;
    email: string;
    country: string;
    phone: string;
    state: string;
    shipment: string;
    zip: string;
    city: string;
    address1: string;
    address2: string;
    address3: string;
    reference: string;
    international: string;
  };
  prefillBulk: {
    title: string;
    shipmentTitle: string;
    description: string;
    shipmentDescription: string;
    shipmentDescriptionSuccess: string;
    target: string;
    name: string;
    content: string;
    postpone: string;
    selectRate: string;
    postponed: string;
    courier: string;
    type: string;
    estimate: string;
    rate: string;
    extra: string;
    total: string;
    save: string;
    day: string;
    insurance: string;
    outOfArea: string;
  };
  filledBulk: {
    title: string;
    description: string;
    target: string;
    name: string;
    content: string;
    pending: string;
    downloadAll: string;
    download: string;
    downloadErrorAll: string;
    downloadError: string;
  };
  statuses: {
    WAITING: string;
    CANCELLED: string;
    SUCCESS: string;
    ERROR: string;
    FULFILLMENT: string;
  };
  yes: string;
  no: string;
  saveAddress: string;
  copyOrder: string;
  currency: string;
  searchTooltip: string;
  syncTitle: string;
  modalTitle: string;
  modalDescription: string;
  prefillLoading: string;
  filledLoading: string;
  prefillShipmentLoading: string;
  back: string;
  loading: string;
  downloadErrors: {
    FEDEX_UNAVAILABLE: string;
    REDPACK_UNAVAILABLE: string;
    UPS_UNAVAILABLE: string;
    ESTAFETA_UNAVAILABLE: string;
    insufficient_credits: string;
    system_error: string;
    insufficient_permissions: string;
    assurance_error: string;
  };
}

const EN: CommonOrdersTranslation = {
  page: {
    title: 'My Orders',
    emptyOrders: "You don't have any orders",
    emptyOrdersDescription: 'Click on the arrows to check the tutorial',
    create: 'Create order',
    skip: 'Skip',
  },
  fullfillmentStatus: {
    fulfilled: 'Sent',
    unfulfilled: 'Pending',
  },
  details: {
    title: 'Order details',
    client: 'Client',
    courier: 'Courier',
    provider: 'Shipping provider',
    type: 'Shipping type',
    dimensions: 'Package dimensions',
    insurance: 'Shipping Insurance',
    created: '',
    time: 'Delivery time',
    estimate: 'Estimate delivery',
    content: 'Content',
    status: 'Status',
    source: 'Source address',
    target: 'Shipping address',
    name: 'Name',
    company: 'Company',
    email: 'Email',
    phone: 'Phone',
    country: 'Country',
    state: 'State',
    zip: 'Zip',
    shipment: 'Shipment',
    address1: 'Municipality',
    city: 'City',
    address2: 'Neighborhood',
    address3: 'Street & Numbers',
    reference: 'Reference',
    international: 'International Shipping',
  },
  list: {
    noResults: 'No results',
    title: 'Search',
    tableTitle: 'Select more than one order to send or hide them.',
    createOrder: 'Create order',
    simpleOrder: 'Simple',
    csvOrder: 'From CSV',
    storeOptionsLabel: 'Store',
    shippingOptionsLabel: 'Shipping',
    from: 'From',
    to: 'To',
    searchLabel: 'Search by...',
    shippingOptions: ['All', 'Pending', 'Sent'],
    fullfillmentStatus: {
      fulfilled: 'Sent',
      unfulfilled: 'Pending',
    },
    options: {
      view: 'View details',
      hide: 'Hide order',
      show: 'Show order',
      send: 'Send order',
    },
    showHidden: 'Show only hidden orders',
    showActive: 'Show only visible orders',
  },
  table: {
    headers: [
      'ID',
      'Source',
      'Client',
      'Address',
      'Date',
      'Status',
      'Action',
      'Type',
    ],
  },
  bulk: {
    title: 'Generate courier tags in a batch using a CSV file',
    important: {
      title: 'IMPORTANT',
      items: [
        '*Follow the same order as its shown in the tutorial',
        '*Weights and measures must be in centimeters (cm) and kilograms (Kg).',
        "*Shippings are bound to courier's coverage.",
        '*Remember to fill every field with the exception of Order Number',
        "*Insurance field is an opcional value and must be filled only if you wish to secure your shipment. Add the field you'll be using (min $ 100).",
        '*Billing will be the total of selected shipments.',
        '*You can postpone and finish shipments in any moment.',
        '*Select the rate it suits you the most for every loaded order. Only correctly loaded orders will be listed.',
        '*If you want to postpone guides generation click "Postpone" button. Billing will be done by the sum of selected shipments.',
      ],
    },
    question: 'How it works?',
    questionDescription:
      "Ideal for users with several pending shippings and haven't synced with us.",
    items: [
      'Download the CSV template below. (The template provides the specific formatting)',
      'Fill the CSV template with the shipping data you wish to generate.',
      'Click the Select File button.',
      'Choose your preferred courier.',
      'Download your courier tags.',
      "Insurance field is optional and should be filled only if you want to add insurance for your shipment. Add the value you'll be paying to secure your package (min $5 USD).",
      'Final billing will depend on selected total.',
      'Shipments can be postponed and be finished at any time.',
    ],
    action: 'Select File',
    download: {
      text: 'Download the template',
      link: 'here',
    },
  },
  emptyElements: [
    'Add origin and destination addresses.',
    'Tell package dimensions.',
    'Confirm your data.',
    'Select your preferred courier.',
    'Download your tag.',
  ],
  notifications: {
    shipment: 'Order has been marked for shipment',
    hidden: 'Order has been marked as hidden',
    visible: 'Order has been marked as visible',
    hiddenOrders: 'Orders has been marked as hidden',
    visibleOrders: 'Orders has been marked as visible',
    simpleInsufficientFunds:
      "You don't have credits. Add some to create an order.",
    bulkInsufficientFunds:
      "You don't have enough credits. Add more or delay an order.",
    sync: {
      start:
        "The sync has started. We'll let you know when it's ready. You can continue using the app normally.",
      complete: 'The orders sync is complete.',
      error: "We couldn't sync the orders. Please try again later.",
      inprogress: 'A syncronization is already in progress.',
    },
  },
  prefillBulk: {
    title: 'CSV Load',
    shipmentTitle: 'Bulk Shipment',
    description: "Select the courier you'll be using in each order",
    shipmentDescription:
      "Seleccione the carrier you'll be using for every shipment.",
    shipmentDescriptionSuccess:
      "Review the result for your selected orders. Orders with errors won't be charged.",
    target: 'Destination',
    name: 'Name',
    content: 'Content',
    postpone: 'Postpone',
    selectRate: 'Select rate',
    postponed: 'Postponed',
    courier: 'Courier',
    type: 'Shipping type',
    estimate: 'Estimate',
    rate: 'Rate',
    extra: 'Extra cost',
    total: 'Total',
    save: 'Save',
    day: 'Working Day',
    insurance: 'Insurance',
    outOfArea: 'Extended Zone',
  },
  filledBulk: {
    title: 'CSV Load',
    description: "Select the courier you'll be using in each order",
    target: 'Destination',
    name: 'Name',
    content: 'Content',
    pending: 'Pending',
    downloadAll: 'Download all guides',
    download: 'Download guide',
    downloadErrorAll: 'Error generating guides',
    downloadError: 'Error generating guide',
  },
  statuses: {
    WAITING: 'Waiting',
    CANCELLED: 'Cenceled',
    SUCCESS: 'Success',
    ERROR: 'Error',
    FULFILLMENT: 'Fullfillment',
  },
  yes: 'Yes',
  no: 'No',
  saveAddress: 'Save in My Addresses',
  copyOrder: 'Copy order',
  currency: 'USD',
  searchTooltip: "Search by client's email...",
  syncTitle: 'Sync',
  modalTitle: 'Do you want to proceed?',
  modalDescription:
    "It seems like you haven't selected a rate or save it for later",
  prefillLoading: 'We are loading your orders, please wait.',
  filledLoading: 'Your labels are being generated. please wait.',
  prefillShipmentLoading:
    'Your selected orders are being prepared for shipment. please wait.',

  back: 'Go back to My Orders',
  loading: 'Loading...',
  downloadErrors: {
    FEDEX_UNAVAILABLE: 'Fedex is unavailable',
    REDPACK_UNAVAILABLE: 'Redpack is unavailable',
    UPS_UNAVAILABLE: 'UPS is unavailable',
    ESTAFETA_UNAVAILABLE: 'ESTAFETA is unavailable',
    insufficient_credits: 'Insufficiente credits',
    system_error: 'System error',
    insufficient_permissions: 'Insufficient permissions',
    assurance_error: 'Assurance error',
  },
};
const ES: CommonOrdersTranslation = {
  page: {
    title: 'Mis órdenes',
    emptyOrders: 'No cuentas con órdenes',
    emptyOrdersDescription: 'Haz clic en las flechas para conocer el tutorial',
    create: 'Crear orden',
    skip: 'Saltar',
  },
  list: {
    noResults: 'No se encontraron resultados',
    title: 'Realizar búsqueda',
    tableTitle:
      'Selecciona más de una orden para realizar envíos u ocultar de forma múltiple.',
    createOrder: 'Crear Orden',
    simpleOrder: 'Simple',
    csvOrder: 'Por CSV',
    searchLabel: 'Buscar por...',
    storeOptionsLabel: 'Tienda',
    shippingOptionsLabel: 'Envíos',
    from: 'Desde',
    to: 'Hasta',
    shippingOptions: ['Todos', 'Pendientes', 'Enviados'],
    fullfillmentStatus: {
      fulfilled: 'Enviado',
      unfulfilled: 'Pendiente',
    },
    options: {
      view: 'Ver detalles',
      hide: 'Ocultar',
      show: 'Mostrar',
      send: 'Enviar',
    },
    showHidden: 'Mostrar solo órdenes ocultas',
    showActive: 'Mostrar solo órdenes visibles',
  },
  table: {
    headers: [
      'ID',
      'Origen',
      'Cliente',
      'Dirección',
      'Fecha',
      'Estatus',
      'Acción',
      'Envío',
    ],
  },
  fullfillmentStatus: {
    fulfilled: 'Enviado',
    unfulfilled: 'Pendiente',
  },
  details: {
    title: 'Detalle de orden',
    client: 'Cliente',
    courier: 'Paquetería',
    provider: 'Proveedor de paquetería',
    type: 'Tipo de envío',
    dimensions: 'Dimensiones del paquete',
    insurance: 'Seguro de envío',
    created: 'Fecha de generación de guía',
    time: 'Duración de entrega',
    estimate: 'Fecha de entrega estimada',
    content: 'Contenido',
    status: 'Estatus',
    source: 'Dirección de origen',
    target: 'Dirección de destino',
    name: 'Nombre',
    company: 'Compañía',
    email: 'Correo electrónico',
    shipment: 'Paquete',
    phone: 'Teléfono',
    country: 'País',
    city: 'Ciudad',
    state: 'Estado',
    zip: 'C.P',
    address1: 'Delegación o Municipio',
    address2: 'Colonia',
    address3: 'Calle y número',
    reference: 'Referencia',
    international: 'Envio internacional',
  },
  bulk: {
    title: 'Generar guías en serie desde CSV',
    important: {
      title: 'IMPORTANTE',
      items: [
        '*Sigue el mismo orden que se muestra en el tutorial',
        '*Pesos y medidas deben ser especificados centímetros (cm) y kilogramos (Kg).',
        '*Los envíos están sujetos a la cobertura de la paquetería.',
        '*Recuerda llenar los campos a excepción del Número de Orden',
        '*El campo de aseguramiento es un valor opcional y solo se debe llenar si desea asegurar el envío. Agregue el campo por el que se asegurará el envío (mínimo $ 100).',
        '*Si desea posponer la generación de la guía para una orden, presione el botón “Posponer“.',
        '*El cobro se hará por la suma de las guías en las que se seleccionó una tarifa.',
      ],
    },
    question: '¿Cómo funciona la generación de guías en serie?',
    questionDescription:
      'Ideal para usuarios que tengan muchos envíos pendientes y aún no sincronizan sus plataformas con nosotros.',
    items: [
      'Descarga la plantilla CSV abajo. (La plantilla provee un formato específico)',
      'Llena la plantilla con los datos de las órdenes que deseas generar.',
      'Has click en el botón Seleccionar Archivo.',
      'Seleccione la tarifa que más le convenga para cada una de las órdenes cargadas. Solo se enlistarán las órdenes cargadas correctamente.',
      'Descarga tu(s) guías generada(s).',
    ],
    action: 'Seleccionar Archivo',
    download: {
      text: 'Descarga la plantilla',
      link: 'aquí',
    },
  },
  emptyElements: [
    'Agrega la dirección de origen y dirección de destino.',
    'Indica las dimensiones del paquete.',
    'Corrobora tus datos.',
    'Selecciona la paquetería de tu preferencia.',
    'Descarga tu etiqueta.',
  ],
  notifications: {
    shipment: 'Se ha marcado la orden para envío',
    hidden: 'Se ha marcado la orden como oculta',
    hiddenOrders: 'Se han marcado las órdenes como ocultas',
    visible: 'Se ha marcado la orden como visible',
    visibleOrders: 'Se han marcado las órdenes como visibles',
    simpleInsufficientFunds:
      'No cuentas con créditos. Añade créditos para comenzar a crear órdenes',
    bulkInsufficientFunds:
      'No tiene suficientes créditos. Cargue créditos o posponga alguna orden',
    sync: {
      start:
        'La sincronización de órdenes se ha iniciado. Te haremos saber en cuanto esté terminada. Puedes usar la aplicación con normalidad.',
      complete: 'La sincronización de órdenes ha terminado.',
      error:
        'No pudimos terminar de sincronizar las órdenes. Por favor intenta de nuevo.',
      inprogress: 'Ya hay una sincronización en progreso.',
    },
  },
  prefillBulk: {
    title: 'Carga de CSV',
    shipmentTitle: 'Envío por lote',
    description:
      'Seleccione la paquetería con la que realizará cada orden cargada del archivo CSV',
    shipmentDescription:
      'Seleccione la paquetería con la que realizará cada orden de este envío por lote.',
    shipmentDescriptionSuccess:
      'Visualiza el resultado de las órdenes seleccionadas. Las órdenes con error no se cobrarán de sus créditos.',
    target: 'Destino',
    name: 'Nombre',
    content: 'Contenido',
    postpone: 'Posponer orden',
    selectRate: 'Seleccionar tarifa',
    postponed: 'Orden pospuesta',
    courier: 'Paquetería',
    type: 'Tipo de envío',
    estimate: 'Tiempo estimado de entrega',
    rate: 'Tarifa',
    extra: 'Costo extra',
    total: 'Total',
    save: 'Guardar',
    day: 'Días hábiles',
    insurance: 'Seguro',
    outOfArea: 'Zona extendida',
  },
  filledBulk: {
    title: 'Carga de CSV',
    description:
      'Seleccione la paquetería con la que realizará cada orden cargada del archivo CSV',
    target: 'Destino',
    name: 'Nombre',
    content: 'Contenido',
    pending: 'Pendiente',
    downloadAll: 'Descargar todas las guías',
    download: 'Descargar guía',
    downloadErrorAll: 'Error generando guías',
    downloadError: 'Error generando guía',
  },
  statuses: {
    WAITING: 'Pendiente',
    CANCELLED: 'Cencelado',
    SUCCESS: 'Exitoso',
    ERROR: 'Error',
    FULFILLMENT: 'Completado',
  },
  yes: 'Si',
  no: 'No',
  saveAddress: 'Guardar en Mis direcciones',
  copyOrder: 'Copiar orden',
  currency: 'MXN',
  searchTooltip: 'Buscar por email...',
  syncTitle: 'Sincronizar',
  modalTitle: '¿Desea continuar?',
  modalDescription:
    'Parece que no ha seleccionado una tarifa o la ha guardado para después.',
  prefillLoading: 'Se está cargando su archivo CSV. Por favor espere.',
  filledLoading: 'Sus etiquetas se están generando. Por favor espere.',
  prefillShipmentLoading:
    'Se están cargando sus órdenes seleccionadas. Por favor espere.',
  back: 'Volver a Mis órdenes',
  loading: 'Cargando...',
  downloadErrors: {
    FEDEX_UNAVAILABLE: 'Fedex sin disponibilidad',
    REDPACK_UNAVAILABLE: 'Redpack sin disponibilidad',
    UPS_UNAVAILABLE: 'UPS sin disponibilidad',
    ESTAFETA_UNAVAILABLE: 'ESTAFETA sin disponibilidad',
    insufficient_credits: 'Crédito insuficiente',
    system_error: 'Error de sistema',
    insufficient_permissions: 'Permisos insuficientes',
    assurance_error: 'Error asegurando',
  },
};

export const CommonOrdersLanguages = {
  EN,
  ES,
};
