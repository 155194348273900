export class CommonIntegrationsTranslation {
  page: {
    title: string;
  };
  steps: {
    store: string;
    additionalData: string;
    finish: string;
  };
  continue: string;
}

const ES: CommonIntegrationsTranslation = {
  page: {
    title: 'Integraciones con plataformas e-commerce',
  },
  steps: {
    store: 'Elegir tienda',
    additionalData: 'Datos adicionales',
    finish: 'Finalizar Conexión',
  },
  continue: 'Continuar',
};

const EN: CommonIntegrationsTranslation = {
  page: {
    title: 'E-commerce platforms integrations',
  },
  steps: {
    store: 'Store selection',
    additionalData: 'Additional Data',
    finish: 'Finish connection',
  },
  continue: 'Continue',
};

export const CommonIntegrationsLanguages = {
  EN,
  ES,
};
