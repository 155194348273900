import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OverlayService {
  private active = false;

  public loadingStatus$: Subject<boolean> = new Subject<boolean>();

  public get isActive(): boolean {
    return this.active;
  }

  private set loading(value: boolean) {
    this.active = value;
    this.loadingStatus$.next(this.active);
  }

  constructor() {}

  public show(): void {
    this.loading = true;
  }

  public hide(): void {
    this.loading = false;
  }
}
