import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'commons-modal-with-content',
  templateUrl: './modal-with-content.component.html',
  styleUrls: ['./modal-with-content.component.scss'],
})
export class ModalWithContentComponent
  implements OnDestroy, AfterViewInit, OnInit {
  /**
   * Title for the modal
   */
  @Input() public title: string;
  @Input() public customWidth: string;
  @Input() public customStyle: Record<string, string> = {};

  /**
   * If the modal should be wide
   */
  @Input() public wide?: boolean;

  @Output() public backgroundClick = new EventEmitter<void>();

  @Input() public showCloseButton: boolean;
  @Output() public closeButtonClick = new EventEmitter<void>();

  /**
   * This points to the overlay component, and it's the element we listen
   * the click events on
   */
  @ViewChild('background', { static: true, read: ElementRef })
  private background: ElementRef;

  /**
   * This emits a value when the component is destroyed to close subscriptions
   */
  private destroyed$ = new Subject<void>();

  ngOnInit(): void {
    if (this.customWidth) {
      this.customStyle.width = this.customWidth;
    }
  }
  ngOnDestroy(): void {
    // Destroy subscriptions that listened with takeUntil
    this.destroyed$.next();
  }

  ngAfterViewInit(): void {
    this.listenToBackgroundClick();
  }

  /**
   * Create an RxJS click event listener on the modal background
   */
  private listenToBackgroundClick(): void {
    fromEvent(this.background.nativeElement, 'click')
      // Destroy subscription when destroyed$ emits a value
      .pipe(takeUntil(this.destroyed$))
      .subscribe((event: Event) => {
        this.handleClickEvent(event);
      });
  }

  /**
   * Determine if a click event was **exactly** on the modal background
   * @param event Click event on **any** element in the whole modal
   */
  private handleClickEvent(event: Event): void {
    event.stopPropagation();
    // The background was clicked when the clicked element is the same
    // as the element that the event listener was attached to.
    if (
      // target is the element that was clicked, and can be **any**
      // element inside the modal
      event.target ===
      // currentTarget is the element that listened to the event, alas,
      // our overlay component. Since the clicked element will be the firstChild
      // of the OverlayComponent, we compara againsta that element.
      (event.currentTarget as HTMLElement).firstChild
    ) {
      this.backgroundClick.next();
    }
  }
}
