<mat-card>
  <div class="px-12 px-xs-2 pt-8">
    <div class="flex justify-content-center mb-8">
      <h1 class="uppercase">{{ translation.title }}</h1>
    </div>
    <!-- <form [formGroup]="form" (ngSubmit)="onSubmit($event)"> -->
    <form [formGroup]="form">
      <div class="flex flex-column min-width-two-quarters" *ngIf="step == 'data'">
        <h2 class="form-subtitle">{{ translation.accountSubtitle }}</h2>
        <mat-form-field class="mb-4">
          <mat-label>
            {{ translation.labels.email }}
          </mat-label>
          <input matInput required type="email" [errorStateMatcher]="matcher" formControlName="email" />
          <mat-error class="mb-2" *ngIf="
            form.controls.email.errors && form.controls.email.errors.pattern
          ">
            {{ translation.errors.emailValid }}
          </mat-error>
          <mat-error class="mb-2" *ngIf="
            form.controls.email.errors && form.controls.email.errors.required
          ">
            {{ translation.errors.emailRequired }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="mb-4">
          <mat-label> {{ translation.labels.password }} </mat-label><input matInput required formControlName="password"
            [type]="showPassword ? 'text' : 'password'" [errorStateMatcher]="matcher" min="8" max="24"
            name="password" />
          <div matSuffix class="input-icon color-grey-light-1 pointer" (click)="toggleShowPassword()"
            *ngIf="!showPassword">
            <i class="cursor-pointer fas fa-eye pr-2"></i>
          </div>
          <div matSuffix class="input-icon color-grey-light-1 pointer" (click)="toggleShowPassword()"
            *ngIf="showPassword">
            <i class="cursor-pointer fas fa-eye-slash pr-2"></i>
          </div>
          <mat-error class="mb-2" *ngIf="
            form.controls.password.errors &&
            form.controls.password.errors.required
          ">
            {{ translation.errors.passwordRequired }}
          </mat-error>
          <mat-hint>
            {{ translation.errors.passwordPattern }}
          </mat-hint>
        </mat-form-field>

        <mat-form-field class="mb-4 mt-2">
          <mat-label>
            {{ translation.labels.confirmPassword }}
          </mat-label>
          <input matInput required [errorStateMatcher]="matcher" [type]="showConfirmation ? 'text' : 'password'"
            type="password" name="confirmPassword" formControlName="confirmPassword" />
          <div matSuffix class="input-icon color-grey-light-1 pointer" (click)="toggleShowConfirmation()"
            *ngIf="!showConfirmation">
            <i class="cursor-pointer fas fa-eye pr-2"></i>
          </div>
          <div matSuffix class="input-icon color-grey-light-1 pointer" (click)="toggleShowConfirmation()"
            *ngIf="showConfirmation">
            <i class="cursor-pointer fas fa-eye-slash pr-2"></i>
          </div>
        </mat-form-field>

        <mat-error class="custom-error" *ngIf="form.hasError('isPasswordUnmatched')"
          [ngClass]="form.hasError('isPasswordUnmatched') ? 'mb-4' : null">
          {{ translation.errors.passwordUnmatched }}
        </mat-error>

        <h2 class="form-subtitle">{{ translation.contactSubtitle }}</h2>

        <mat-form-field class="mb-4">
          <mat-label>
            {{ translation.labels.name }}
          </mat-label>
          <input matInput required [errorStateMatcher]="matcher" formControlName="full_name" />
          <mat-error class="mb-2" *ngIf="
            form.controls.full_name.errors &&
            form.controls.full_name.errors.required
          ">
            {{ translation.errors.nameRequired }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="mb-4">
          <mat-label>
            {{ translation.labels.phone }}
          </mat-label>
          <input matInput [errorStateMatcher]="matcher" formControlName="contact_number" />
          <mat-error class="mb-2" *ngIf="
            form.controls.contact_number.errors &&
            form.controls.contact_number.errors.required
          ">
            {{ translation.errors.phoneRequired }}
          </mat-error>
          <mat-error class="mb-2" *ngIf="
            form.controls.contact_number.errors &&
            form.controls.contact_number.errors.pattern
          ">
            {{ translation.errors.phonePattern }}
          </mat-error>
          <mat-error class="mb-2" *ngIf="
            form.controls.contact_number.errors &&
            (form.controls.contact_number.errors.minLength ||
              form.controls.contact_number.errors.maxLength)
          ">
            {{ translation.errors.phoneLength }}
          </mat-error>
        </mat-form-field>
        <div class="mr-4 mb-8 flex justify-content-center">
          <mat-slide-toggle formControlName="confirm_success_url" (change)="onTermsAgreedChanged($event)">
            <div class="flex align-items-center">
              <div class="mr-1">
                {{ translation.labels.terms }}
              </div>
              <a [href]="termsUrl" target="_blank">
                {{ translation.links.terms }}
              </a>
            </div>
          </mat-slide-toggle>
        </div>

        <div class="mb-4">
          <button mat-raised-button color="primary" class="width-full btn secondary" (click)="next('company')"
            [disabled]="form.controls.email.valid  && 
                      form.controls.password.valid && 
                      form.controls.confirmPassword.valid && 
                      form.controls.full_name.valid && 
                      form.controls.contact_number.valid && 
                      form.controls.confirm_success_url.valid && 

                      !disableButton ? null : true">
            {{ translation.buttons.next }}
          </button>
        </div>

      </div>

      <div *ngIf="step == 'company'">
        <h2 class="form-subtitle">{{ translation.stepTwo }}</h2>

        <div class="flex flex-column min-width-two-quarters" formGroupName="company">
          <mat-form-field class="mb-4">
            <mat-label>
              {{ translation.labels.namecompany }}
            </mat-label>
            <input matInput required [errorStateMatcher]="matcher" maxlength="50" formControlName="name" />
            <mat-error class="mb-2" *ngIf="
              form.controls.full_name.errors &&
              form.controls.full_name.errors.required
            ">
              {{ translation.errors.nameRequired }}
            </mat-error>
          </mat-form-field>

          <mat-form-field class="demo-full-width" appearance="fill">
            <mat-label>¿Desde dónde te interesa hacer envíos?</mat-label>
            <select matNativeControl formControlName="from_send" [errorStateMatcher]="matcher">
              <option value="México">México</option>
              <option value="USA" selected>USA</option>

            </select>
            <!-- <mat-error *ngIf="nativeSelectFormControl.hasError('required')">You must make a selection</mat-error>
            <mat-error *ngIf="nativeSelectFormControl.hasError('pattern') && !nativeSelectFormControl.hasError('required')">
              Your selection is invalid
            </mat-error> -->
          </mat-form-field>

          <mat-form-field class="demo-full-width" appearance="fill">
            <mat-label>¿Cuántos envíos realizas por mes?</mat-label>
            <select matNativeControl formControlName="howmany_per_month" [errorStateMatcher]="matcher">
              <option value="1-50">1-50</option>
              <option value="51-100" selected>51-100</option>
              <option value="101-500" selected>101-500</option>
              <option value="501-1000" selected>501-1000</option>

            </select>
            <!-- <mat-error *ngIf="nativeSelectFormControl.hasError('required')">You must make a selection</mat-error>
            <mat-error *ngIf="nativeSelectFormControl.hasError('pattern') && !nativeSelectFormControl.hasError('required')">
              Your selection is invalid
            </mat-error> -->
          </mat-form-field>

          <mat-form-field class="demo-full-width" appearance="fill">
            <mat-label>¿Qué tipo de negocio tienes?</mat-label>
            <select matNativeControl formControlName="business_type" [errorStateMatcher]="matcher">
              <option value="No tengo negocio">No tengo negocio</option>
              <option value="Estoy comenzando" selected>Estoy comenzando</option>
              <option value="E-commerce/Market place" selected>E-commerce/Market place</option>
              <option value="Vendedor/comerciante" selected>Vendedor/comerciante</option>

            </select>
            <!-- <mat-error *ngIf="nativeSelectFormControl.hasError('required')">You must make a selection</mat-error>
            <mat-error *ngIf="nativeSelectFormControl.hasError('pattern') && !nativeSelectFormControl.hasError('required')">
              Your selection is invalid
            </mat-error> -->
          </mat-form-field>

          <mat-form-field class="demo-full-width" appearance="fill">
            <mat-label>¿Cómo te podemos ayudar?</mat-label>
            <select matNativeControl formControlName="howcan_we_help" [errorStateMatcher]="matcher">
              <option value="No tengo negocio">No tengo negocio</option>
              <option value="Ayudame a iniciar" selected>Ayudame a iniciar</option>
              <option value="Guias (carriers, precios, cotizaciones)" selected>Guias (carriers, precios, cotizaciones)
              </option>
              <option value="Integraciones" selected>Integraciones</option>
              <option value="Otro" selected>Otro</option>

            </select>
            <!-- <mat-error *ngIf="nativeSelectFormControl.hasError('required')">You must make a selection</mat-error>
            <mat-error *ngIf="nativeSelectFormControl.hasError('pattern') && !nativeSelectFormControl.hasError('required')">
              Your selection is invalid
            </mat-error> -->
          </mat-form-field>

          <mat-form-field class="demo-full-width" appearance="fill">
            <mat-label>¿Qué tipo de servicio estás buscando?</mat-label>
            <select matNativeControl formControlName="type_service" [errorStateMatcher]="matcher">
              <option value="Local">Local</option>
              <option value="Internacional" selected>Internacional</option>
              <option value="Nacional" selected>Nacional</option>
            </select>
            <!-- <mat-error *ngIf="nativeSelectFormControl.hasError('required')">You must make a selection</mat-error>
            <mat-error *ngIf="nativeSelectFormControl.hasError('pattern') && !nativeSelectFormControl.hasError('required')">
              Your selection is invalid
            </mat-error> -->
          </mat-form-field>
        </div>



        <div class="mb-4">
          <button mat-raised-button color="primary" class="width-full btn secondary" type="submit" [disabled]="form.get('company.name').valid &&
                        form.get('company.from_send').valid &&
                        form.get('company.howmany_per_month').valid &&
                        form.get('company.business_type').valid &&
                        form.get('company.howcan_we_help').valid &&
                        form.get('company.type_service').valid &&
                        !disableButton ? null : true" (click)="onSubmit($event)">
            {{ translation.buttons.signUp }}
          </button>
        </div>
        <div class="mb-4">
          <button mat-raised-button color="primary" class="width-full button btn.primary" (click)="next('data')">
            {{ translation.buttons.back }}
          </button>
        </div>
      </div>


      <div class="mb-10 flex justify-content-center">
        <a class="action" routerLink="/auth/login">
          <i class="fas fa-arrow-left"></i>
          <div class="ml-2">{{ translation.links.goBack }}</div>
        </a>
      </div>
    </form>
  </div>
</mat-card>