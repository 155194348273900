class RepoInfo {
    readonly version = '0.2.18';
    readonly branch = 'develop';

    constructor() {}

    toString() { 
        return `v: ${this.version}`;
    }
};

export const versions = new RepoInfo();