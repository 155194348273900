export class LayoutHeaderTranslation {
  buttons: {
    addCredit: string;
    logout: string;
    profile: string;
  };
  currency: string;
  greeting: string;
}

const ES: LayoutHeaderTranslation = {
  buttons: {
    addCredit: 'Crédito',
    logout: 'Salir',
    profile: 'Mi Perfil',
  },
  currency: 'MXN',
  greeting: 'Hola',
};

const EN: LayoutHeaderTranslation = {
  buttons: {
    addCredit: 'Credit',
    logout: 'Logout',
    profile: 'Profile',
  },
  currency: 'USD',
  greeting: 'Hello',
};

export const LayoutHeaderLanguages = {
  EN,
  ES,
};
