<div class="mb-8">
  <h1>
    <i class="cursor-pointer pr-4 fas fa-chevron-left" (click)="goBack()"></i>
    {{ translation.form.title }}
  </h1>
</div>
<commons-pickup-verify *ngIf="!pickupAvailability" [availableCouriers]="availableCouriers"
  (verified)="onVerified($event)"></commons-pickup-verify>

<commons-pickup-form *ngIf="pickupAvailability" [pickupAvailability]="pickupAvailability"
  [availableCouriers]="availableCouriers"></commons-pickup-form>