import * as R from 'ramda';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  APIRoutes,
  DatatableBalances,
  BillingInfoPayload,
  BillingInfoResponse,
  DatatableInvoices,
  NewBillings,
  Invoice,
} from '@commons/models/index';
import { DatatableInfo } from '@commons/models/datatable.model';
import { Observable, Subject, timer } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class CommonBalancesService {
  private invoiceStatus = new Subject<any>();

  private exportStatus = new Subject<any>();

  private readonly routes = new APIRoutes();

  constructor(private readonly http: HttpClient) {}

  public paginateHistory(params: HttpParams): Observable<DatatableBalances> {
    return this.http.get<DatatableInfo>(this.routes.Credits.All, { params });
  }

  public paginateInvoices(params: HttpParams): Observable<DatatableInvoices> {
    return this.http.get<DatatableInfo>(this.routes.Invoices.All, {
      params,
    });
  }

  public createInvoices(payload: {
    deposit_id: number[];
  }): Observable<{ id: number }> {
    return this.http.post<{ id: number }>(this.routes.Invoices.All, payload);
  }

  public getInvoices(): Observable<NewBillings> {
    return this.http.get<NewBillings>(this.routes.Invoices.Custom(['new']));
  }

  public downloadInvoice(id: number, format: 'html' | 'xml'): Observable<any> {
    return this.http.get<any>(
      this.routes.Invoices.Custom([`${id}`, `download?format=${format}`]),
    );
  }

  public getInvoiceStatus(id: number): Observable<Invoice> {
    return timer(0, 3500).pipe(
      takeUntil(
        this.invoiceStatus.asObservable().pipe(
          filter(data => {
            const status = R.path(['data', 'invoice', 'status'], data);
            return status === 'SUCCESS' || status === 'ERROR';
          }),
        ),
      ),
      switchMap(() =>
        this.http
          .get<{ data: { data: { invoice: Invoice } } }>(
            this.routes.Invoices.Custom([`${id}`, 'status']),
            {
              headers: { isSilent: 'true' },
            },
          )
          .pipe(
            tap(response => this.invoiceStatus.next(response.data)),
            map(({ data }) => R.path(['data', 'invoice'], data)),
          ),
      ),
    );
  }

  public getBillingInfo(): Observable<BillingInfoResponse> {
    return this.http.get<BillingInfoResponse>(this.routes.FiscalDataGet.All);
  }

  public createBillingInfo(payload: BillingInfoPayload): Observable<void> {
    return this.http.post<void>(this.routes.FiscalDataCreate.All, payload);
  }

  public updateBillingInfo(payload: BillingInfoPayload): Observable<void> {
    return this.http.put<void>(this.routes.FiscalDataUpdate.All, payload);
  }

  public setAutomaticBilling(automatic_invoice: boolean): Observable<void> {
    return this.http.post<void>(
      this.routes.Settings.Custom('automatic_invoice'),
      {
        automatic_invoice: automatic_invoice ? '1' : '0',
      },
    );
  }

  public setExport(query?: {
    q: string;
    type: string;
    from: string;
    to: string;
    page: number;
    user_id: string;
    export: string;
    provider: string;
    all: string;
    data_type_to_export: string;
  }): Observable<any> {
console.log(query);

    if (query) {
      let params = new HttpParams();

      if (query.q) {
        params = params.append('q', query.q);
      }
      if (query.type) {
        params = params.append('type', query.type);
      }

      if (query.page) {
        params = params.append('page', query.page.toString());
      }

      if (query.from) {
        const from_date = moment(query.from).format('YYYY-MM-DD');
        
        params = params.append('from_date', from_date)
      }

      if (query.to) {
        const to_date =   moment(query.to).format('YYYY-MM-DD');
        params = params.append('to_date', to_date)
      }

      if (query.export) {
        params = params.append('export', query.export)
      }

      if (query.provider) {
        params = params.append('provider', query.provider)
      }

      if (query.user_id) {
        params = params.append('user_id', query.user_id)
      }
      if (query.all) {
        params = params.append('all', query.all)
      }
      if (query.data_type_to_export) {
        params = params.append('data_type_to_export', query.data_type_to_export)
      }

      return this.http.post(this.routes.ExportsG.All, {}, {params});
    }
    
    
  }


  public getExport(
    query?: {
      q: string;
      type: string;
      from: string;
      to: string;
      page: number;
      user_id: string;
      export: string;
      provider: string;
      all: string;
      data_type_to_export: string;
    }
  ): Observable<any> {

    if (query) {
      let params = new HttpParams();

      if (query.q) {
        params = params.append('q', query.q);
      }
      if (query.type) {
        params = params.append('type', query.type);
      }

      if (query.page) {
        params = params.append('page', query.page.toString());
      }

      if (query.from) {
        const from_date = moment(query.from).format('YYYY-MM-DD');
        
        params = params.append('from_date', from_date)
      }

      if (query.to) {
        const to_date =   moment(query.to).format('YYYY-MM-DD');
        params = params.append('to_date', to_date)
      }

      if (query.export) {
        params = params.append('export', query.export)
      }

      if (query.provider) {
        params = params.append('provider', query.provider)
      }

      if (query.user_id) {
        params = params.append('user_id', query.user_id)
      }
      if (query.all) {
        params = params.append('all', query.all)
      }
      if (query.data_type_to_export) {
        params = params.append('data_type_to_export', query.data_type_to_export)
      }
    
    return timer(0, 3500).pipe(
      takeUntil(
        this.exportStatus.asObservable().pipe(
          filter(({ status }) => {
            // console.log(status, ' ', status === 'finished');
            
            return status === 'finished';
          }),
        ),
      ),
      switchMap(() =>
        this.http
          .get<any>(
            this.routes.Exports.All,
            { params,
              headers: { isSilent: 'true' },
            },
          )
          .pipe(
            tap(response => this.exportStatus.next(response.data)),
            // map(({ data }) => R.path(['file_url'], data)),
          ),
      ),
    );
          }
  }

  public getReport(query?: {
    q: string,
    from_date: string,
    to_date: string,
    type: string,
  }): Observable<any> {
    if (query) {
      let params: any = {};
      if (query.q) {
        params.q =  query.q;
      }
      if (query.type) {
        params.type =  query.type;
      }

      if (query.from_date) {
        params.from_date = query.from_date
      }

      if (query.to_date) {
        params.to_date = query.to_date
      }
     

      return this.http.post(this.routes.Exports.All,  params);
    }
    return this.http.get(this.routes.Exports.All, );
  }
}
