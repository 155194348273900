import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomErrorStateMatcher, RegexEmail } from '@commons/helpers/index';
import { CommonPickupsService } from '../common-pickups.service';
import {
  CommonPickupsLanguages,
  CommonPickupsTranslation,
} from '../common-pickups.translation';
import {
  PickUpAvailabilityChecked,
  PickupPayload,
  PickupsLabel,
} from '@commons/models/index';
import * as Moment from 'moment';
import { Subscription } from 'rxjs';
import { LocationService } from '@commons/services/location.service';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'commons-pickup-form',
  templateUrl: './pickup-form.component.html',
  styleUrls: ['./pickup-form.component.scss'],
})
export class PickupFormComponent implements OnInit, OnDestroy {
  @Input() availableCouriers: string[] = [];
  @Input() pickupAvailability: PickUpAvailabilityChecked;

  availableLabels: PickupsLabel[];

  public readonly translation: CommonPickupsTranslation =
    CommonPickupsLanguages[this.language];

  public lowerTime = '';
  public upperTime = '';
  public upperDate = '';
  public lowerDate = '';

  /**
   *
   */
  public matcher = new CustomErrorStateMatcher();
  /**
   *
   */
  form: FormGroup;

  public states: string[][] = [];

  public neighborhoods: string[] = [];

  private storeSubscription = Subscription.EMPTY;

  private statesSubscription = Subscription.EMPTY;

  private sectorsSubscription = Subscription.EMPTY;

  constructor(
    @Inject('LANGUAGE')
    private readonly language: keyof typeof CommonPickupsLanguages,
    private readonly pickupsService: CommonPickupsService,
    private readonly locationService: LocationService,
    private readonly router: Router,
    private readonly fb: FormBuilder,
    private readonly snotifyService: SnotifyService,
  ) {}

  ngOnInit(): void {
    const {
      constrains: {
        generic_address: { admin_level_0 = '', zip = '' } = {},
      } = {},
    } = this.pickupAvailability || {};
    this.sectorsSubscription = this.locationService
      .getPostalCodes(zip.toString().padStart(4, '0'))
      .subscribe(({ state_abbreviation, city, neighborhoods }) => {
        this.statesSubscription = this.locationService
          .getStates(admin_level_0)
          .subscribe(states => {
            this.neighborhoods = neighborhoods;
            this.states = states;
            this.buildForm(state_abbreviation, city);
          });
      });
  }

  ngOnDestroy(): void {
    this.storeSubscription.unsubscribe();
    this.statesSubscription.unsubscribe();
    this.sectorsSubscription.unsubscribe();
  }

  public async buildForm(state: string, city: string): Promise<void> {

    const {
      constrains: {
        provider,
        lower_time,
        lower_date,
        upper_time,
        upper_date,
        generic_address: { zip, admin_level_0 },
      },
    } = this.pickupAvailability;

    try {
      const labels = await this.pickupsService.labels(provider).toPromise();
      this.availableLabels = labels.data;
      console.log(this.availableLabels);
      
    } catch (e) {

    }

    this.upperDate = upper_date;
    this.lowerDate = lower_date;
    this.upperTime = Moment(upper_time, 'hh:mm:ss').format('HH:ss');
    this.lowerTime = Moment(lower_time, 'HH:mm:ss').format('HH:ss');

    this.form = this.fb.group({
      // date: [lower_date, [Validators.required]],
      // time: [lower_time, [Validators.required]],
      // weight: ['', [Validators.required]],
      // package_count: ['', [Validators.required]],
      provider: [provider, [Validators.required]],
      tracking_number: ['', [Validators.required]],
      // name: ['', [Validators.required]],
      // company: ['', [Validators.required]],
      // admin_level_0: [admin_level_0, [Validators.required]],
      // admin_level_1: [state, [Validators.required]],
      // admin_level_2: [city, [Validators.required]],
      // admin_level_3: ['', [Validators.required]],
      // num_ext: ['', [Validators.required]],
      // num_int: ['', []],
      // street1: ['', [Validators.required]],
      // street2: [''],
      // zip: [zip.toString(), [Validators.required]],
      // phone: [
      //   '',
      //   [
      //     Validators.required,
      //     Validators.pattern(/^\d{10}$/),
      //     Validators.minLength(10),
      //     Validators.maxLength(10),
      //   ],
      // ],
      // email: ['', [Validators.required, Validators.pattern(RegexEmail)]],
      // references: ['', []],
    });
  }

  public getProviderName(name: string){
    if(name == 'Nnminutos'){
      return '99Minutos'
    } else {
      return name
    }
  }

  public onSubmit(): void {
    if (!this.form.valid) {
      return;
    }
    const {
      date,
      time,
      weight,
      package_count,
      provider,
      tracking_number,
      name,
      company,
      admin_level_0,
      admin_level_1,
      admin_level_2,
      admin_level_3,
      num_ext,
      num_int,
      street1,
      street2,
      zip,
      phone,
      email,
      references,
    } = this.form.value;

    const payload: any = {

      label_id: tracking_number
      // pickup: {
      //   pickup_datetime: Moment(
      //     `${date} ${time}`,
      //     'YYYY-MM-DD HH:00:SS',
      //   ).format('YYYY-MM-DDTHH:MM:SS'),
      //   store_closetime: Moment(
      //     `${date} 17:00:00`,
      //     'YYYY-MM-DD HH:00:SS',
      //   ).format('YYYY-MM-DDTHH:MM:SS'),
      //   weight,
      //   package_count,
      //   provider: provider.toUpperCase(),
      //   tracking_number,
      //   generic_address: {
      //     name,
      //     company,
      //     admin_level_0,
      //     admin_level_1,
      //     admin_level_2,
      //     admin_level_3,
      //     num_ext,
      //     num_int,
      //     street1,
      //     street2,
      //     zip,
      //     phone,
      //     email,
      //   },
      //   extra: {
      //     total_packages_amount: package_count,
      //     total_parcel_weight: weight,
      //     references,
      //   },
      // },
    };

    this.storeSubscription = this.pickupsService
      .store(payload)
      .subscribe(
        () => {
          this.router.navigateByUrl('/pickups');
        },
        (error) => this.snotifyService.error(error?.error?.error ?? this.translation.notifications.error)
      );
  }
}
