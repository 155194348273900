import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIRoutes } from '@commons/models/index';
import {
  AdminAccountCarriersPayload,
  AdminAccountPasswordPayload,
  AdminAccountPayload,
  AdminAccountPermissionsPayload,
  AdminUserBalancePayload,
  AdminUserOrdersItem,
  AdminUsersCompleteData,
  AdminUsersItem,
  DatatableAdminUserOrders,
  DatatableAdminUsers,
} from '@commons/models/users.model';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AdminUsersService {
  private readonly routes = new APIRoutes();
  public updateUserData = new Subject<void>();

  constructor(private readonly http: HttpClient) {}

  public paginate(params: HttpParams): Observable<DatatableAdminUsers> {
    return this.http.get<DatatableAdminUsers>(this.routes.AdminUsers.All, {
      params,
    });
  }

  public paginateOrders(
    id: number,
    params: HttpParams,
  ): Observable<DatatableAdminUserOrders> {
    return this.http.get<DatatableAdminUserOrders>(
      this.routes.AdminOrders.Custom([`${id}`, 'user']),
      {
        params,
      },
    );
  }

  public getCompanyInfo(params: HttpParams) {    
    return this.http.get<any>(this.routes.Companyinfo.All, {params})
    .pipe(map((response) => (response.data[0]) ),
      map(({name, from_send, howmany_per_month, business_type, howcan_we_help,type_service }) => (
        {name, from_send, howmany_per_month, business_type, howcan_we_help, type_service})));
  }

  public get(id: number): Observable<AdminUsersItem> {
    return this.http
      .get<{ data: AdminUsersItem }>(
        this.routes.AdminUsers.Custom([`${id}`, 'edit']),
      )
      .pipe(map((response) => response.data));
  }

  public getOrder(id: number): Observable<AdminUserOrdersItem> {
    return this.http
      .get<{ data: AdminUserOrdersItem }>(this.routes.AdminOrders.Entity(id))
      .pipe(map((response) => (response.data[0] ? response.data[0] : { id })));
  }

  public getComplete(
    id: number,
    accountId: number,
  ): Observable<AdminUsersCompleteData> {
    return this.http.get<AdminUsersCompleteData>(
      this.routes.AdminUsers.Custom([
        `${id}`,
        'accounts',
        `${accountId}`,
        'balance',
      ]),
    );
  }

  public saveBalance(payload: AdminUserBalancePayload): Observable<void> {
    return this.http.put<void>(
      this.routes.AdminAccounts.Custom('update_balance'),
      payload,
    );
  }

  public savePermissions(
    payload: AdminAccountPermissionsPayload,
  ): Observable<void> {
    return this.http.put<void>(
      this.routes.AdminAccounts.Custom(['update_account_user']),
      payload,
    );
  }

  public saveEnabledCouriers(
    payload: AdminAccountCarriersPayload,
  ): Observable<void> {
    return this.http.post<void>(
      this.routes.AdminAccounts.Custom(['update_carriers']),
      payload,
    );
  }

  public saveCancellationCouriers(
    payload: AdminAccountCarriersPayload,
  ): Observable<void> {
    return this.http.put<void>(
      this.routes.AdminAccounts.Custom([
        'update_autocancel_label_request_carriers',
      ]),
      payload,
    );
  }

  public update(payload: AdminAccountPayload): Observable<void> {
    return this.http.put<void>(
      this.routes.AdminUsers.Custom(['update']),
      payload,
    );
  }

  public updatePassword(
    payload: AdminAccountPasswordPayload,
  ): Observable<void> {
    return this.http.put<void>(
      this.routes.AdminAccounts.Custom(['update_password']),
      payload,
    );
  }
}
