import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {
  Router,
  RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoadingBarService {
  private loader = false;
  public loadingStatus$ = new Subject<boolean>();

  constructor(private readonly router: Router) {
    this.startRouteEventHandler();
  }

  public get loading(): boolean {
    return this.loader;
  }

  public set loading(value) {
    this.loader = value;
    this.loadingStatus$.next(value);
  }

  public show(): void {
    this.loading = true;
  }

  public hide(): void {
    this.loading = false;
  }

  private startRouteEventHandler(): void {
    this.router.events.subscribe((event: RouterEvent) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.show();
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.hide();
          break;
        }
      }
    });
  }
}
