import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { DropdownMenuModule } from '@commons/modules/dropdown-menu';
import { SidemodalModule } from '@commons/modules/modals';
import { SlidersModule } from '@commons/modules/sliders';
import { CommonPageModule } from '../common-page/common-page.module';
import { BulkFilledOrderComponent } from './bulk-filled-order/bulk-filled-order.component';
import { BulkFilledShippingComponent } from './bulk-filled-shipping/bulk-filled-shipping.component';
import { BulkPrefillOrderComponent } from './bulk-prefill-order/bulk-prefill-order.component';
import { BulkPrefillShippingComponent } from './bulk-prefill-shipping/bulk-prefill-shipping.component';
import { CommonOrdersComponent } from './common-orders.component';
import { NewOrderModule } from './new-order/new-order.module';
import { NoOrdersComponent } from './no-orders/no-orders.component';
import { ViewOrderComponent } from './view-order/view-order.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {MatIconModule} from '@angular/material/icon';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';

import {
  MatMomentDateModule,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
@NgModule({
  declarations: [
    CommonOrdersComponent,
    NoOrdersComponent,
    BulkPrefillOrderComponent,
    BulkFilledOrderComponent,
    ViewOrderComponent,
    BulkPrefillShippingComponent,
    BulkFilledShippingComponent,
  ],
  imports: [
    CommonModule,
    CommonPageModule,
    SidemodalModule,
    RouterModule,
    SlidersModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    MatCardModule,
    MatCheckboxModule,
    NewOrderModule,
    MatTooltipModule,
    ReactiveFormsModule,
    DropdownMenuModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatIconModule
  ],
  exports: [CommonOrdersComponent],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]
})
export class CommonOrdersModule {}
