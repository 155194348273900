export class Translation {
  buttons: {
    signIn: string;
  };
  errors: {
    emailValid: string;
    emailRequired: string;
    password: string;
    passwordRequired: string;
    passwordPattern: string;
  };
  labels: {
    email: string;
    password: string;
  };
  links: {
    recoverPassword: string;
    signUp: string;
  };
  notifications: {
    invalidEmailOrPassword: string;
    genericError: string;
  };
  title: string;
}

const ES: Translation = {
  buttons: {
    signIn: `Iniciar Sesión`,
  },
  errors: {
    emailValid: `Por favor introduzca una dirección de Correo electrónico válida.`,
    emailRequired: `El campo Correo electrónico es requerido.`,
    password: `El campo Nueva contraseña debe poseer de 8 a 24 caracteres, al menos 1 Mayuscula, 1 Número y 1 Simbolo.`,
    passwordRequired: `El campo Contraseña es requerido.`,
    passwordPattern: `El campo contraseña debe poseer de 8 a 24 caracteres, al menos 1
        Mayuscula, 1 Número y 1 Simbolo.`,
  },
  labels: {
    email: `Correo electrónico`,
    password: `Contraseña`,
  },
  links: {
    recoverPassword: `¿Olvidaste tu contraseña?`,
    signUp: `Regístrate`,
  },
  title: `Bienvenido`,
  notifications: {
    invalidEmailOrPassword:
      'El correo electrónico o contraseña son incorrectos. Intenta de nuevo.',
    genericError: 'Ocurrió un error. Intenta de nuevo.',
  },
};

const EN: Translation = {
  buttons: {
    signIn: `Sign In`,
  },
  errors: {
    emailValid: `Please insert a valid Email address.`,
    emailRequired: `Email field is required.`,
    password: `New password field must have 8 to 24 characters, at least 1 uppercase letter, 1 number and 1 special character.`,
    passwordRequired: `Password field is required.`,
    passwordPattern: `password field must present 8 to 24 characters, at least 1
        Uppercase, 1 Number and 1 Symbol.`,
  },
  labels: {
    email: `Email`,
    password: `Password`,
  },
  links: {
    recoverPassword: `Forgot your password?`,
    signUp: `Sign Up`,
  },
  title: `Welcome`,
  notifications: {
    invalidEmailOrPassword:
      'The e-mail or password are incorrect. Please try again.',
    genericError: 'An error ocurred. Please try again.',
  },
};

export const LANG = {
  ES,
  EN,
};
