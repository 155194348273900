import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { AuthService } from '@commons/modules/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    const isGoingToAuth = state.url.includes('/auth');
    const isGoingToDashboard = !isGoingToAuth;
    const { isLogged } = this.authService;

    if ((isGoingToAuth && !isLogged) || (isGoingToDashboard && isLogged)) {
      return true;
    }

    if (isGoingToDashboard && !isLogged) {
      this.router.navigateByUrl('/auth/login');
      return false;
    }

    if (isGoingToAuth && isLogged) {
      this.router.navigateByUrl('/');
      return false;
    }
    return false;
  }
}
