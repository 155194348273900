import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { PageEvent } from '@angular/material/paginator';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import * as moment from 'moment';
import {
  DataOrder,
  DatatableOrders,
  OrderSearchParams,
  ParseOrderParams,
} from '@commons/models/index';
import { CreditsService } from '@commons/modules/credits';
import { AccountDetailsService } from '@commons/services/account-details.service';
import { SnotifyService } from 'ng-snotify';
import * as R from 'ramda';
import { Subject, Subscription } from 'rxjs';
import {
  filter,
  finalize,
  first,
  map,
  startWith,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { CommonOrdersService } from './common-orders.service';
import {
  CommonOrdersLanguages,
  CommonOrdersTranslation,
} from './common-orders.translation';

@Component({
  selector: 'commons-common-orders',
  templateUrl: './common-orders.component.html',
  styleUrls: ['./common-orders.component.scss'],
})
export class CommonOrdersComponent implements OnInit, OnDestroy {
  public form: FormGroup;

  public selectedOrders: { [key: string]: boolean } = {};

  public readonly translation: CommonOrdersTranslation =
    CommonOrdersLanguages[this.language];

  public shippingOptions = this.translation.list.shippingOptions.map(
    (option, i) => ({ text: option, value: i ? i : '' }),
  );

  public showHidden = false;

  public storeOptions = [
    {
      text: this.translation.list.shippingOptions[0],
      value: '',
    },
    // TODO: REMOVE WHEN MIGRATING TO PAKET
    // TODO: USE APPNAME FROM PACKAGE OR SOMETHING LIKE THAT
    {
      text: 'Speedit',
      value: 'Speedit',
    },
    {
      text: 'Shopify',
      value: 'Shopify',
    },
    {
      text: 'Woocommerce',
      value: 'Woocommerce',
    },
    {
      text: 'Prestashop',
      value: 'Prestashop',
    },
    {
      text: 'Magento',
      value: 'Magento',
    },
    {
      text: 'Mercado Libre',
      value: 'Mercadolibre',
    },
    {
      text: 'Tiendanube',
      value: 'Tiendanube',
    },
  ];

  public tableColumns = [
    'checkbox',
    'id',
    'source',
    'customer_email',
    'address_to',
    // 'type',
    'status',
    'date',
    'actions',
  ];

  minDate: Date;
  maxDate: Date;

  public datatableInfo: DatatableOrders;

  public tableSubscription = Subscription.EMPTY;

  public reportSubscription = Subscription.EMPTY;

  private page = 1;

  private searchValues: OrderSearchParams = null;

  public selectedOrder: number;

  public hasCustomSearch: boolean;

  public isLoading = true;

  public syncInProgress: boolean;
  private destroyed$: Subject<void> = new Subject<void>();
  currentBalance: any;

  public get show(): string {
    if (this.isLoading) {
      return null;
    }

    if (localStorage.getItem('hideOrdersTutorial')) {
      return 'data';
    }

    return 'tutorial';
  }

  public get areAllSelected(): boolean {
    const values = Object.values(this.selectedOrders);
    return values.length > 0 ? R.all(R.equals(true), values) : false;
  }

  public get hasOrders(): boolean {
    const values = Object.values(this.selectedOrders);
    return R.filter(R.equals(true), values).length > 1;
  }

  constructor(
    @Inject('LANGUAGE')
    private readonly language: keyof typeof CommonOrdersLanguages,
    private readonly router: Router,
    private readonly commonOrdersService: CommonOrdersService,
    private readonly fb: FormBuilder,
    private readonly snotifyService: SnotifyService,
    private readonly creditsService: CreditsService,
    private readonly accountDetailsService: AccountDetailsService,
  ) {
    this.maxDate = new Date();
    const mes = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear, mes - 3, (this.maxDate.getDay() + 1));

   }

  ngOnInit(): void {
    this.creditsService.creditsWereUpdated
      .pipe(
        takeUntil(this.destroyed$),
        startWith(0),
      )
      .subscribe(() => {
        this.accountDetailsService
          .get()
          .pipe(map(({ balance }) => Number(balance)))
          .subscribe(balance => (this.currentBalance = balance));
      });

    this.buildForm();
    this.onLoad();
    this.commonOrdersService
      .checkSyncStatus()
      .pipe(
        takeUntil(this.destroyed$),
        first(),
        filter(({ status }) => status === 'queued'),
      )
      .subscribe(() => {
        this.syncInProgress = true;
        this.startSync();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  private buildForm(): void {
    this.form = this.fb.group({
      store: ['', []],
      status: ['', []],
      search: ['', []],
      from_date: ['', []],
      to_date: ['', []],
      hidden_status: [false, []],
    });
    this.form.valueChanges
      .pipe(
        tap(value => {
          this.searchValues = value;
          this.hasCustomSearch = !Object.values(this.searchValues).find(
            searchValue =>{ !!searchValue;
            },
          );
          if (!this.searchValues.search) {
            this.onLoad();
          }
          
         
        }),
      )
      .subscribe();
  }

  searchQuery() {
    this.onLoad();
  }

  downloadCsv(){
    const mes = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    var minDate = new Date(currentYear, mes - 1, (this.maxDate.getDay() + 5));
    var maxDate = new Date(currentYear, mes , (this.maxDate.getDay() + 5));
    const dates = {
      from_date: minDate.toString(),
      to_date: maxDate.toString(),
      exportfile: 'true'
    }

    if (!this.searchValues) {
      
      this.reportSubscription.unsubscribe();
      this.reportSubscription = this.commonOrdersService
      .getReport(ParseOrderParams(this.page, dates || {}))
      .subscribe((response: any) => {
        let info = response.headers.get('Content-Disposition') ?? '';
        const link = document.createElement('a');
        const body = response.body;
        link.href = URL.createObjectURL(body);
        if (info) {
          var aux = info.split('=')[1];
          aux = aux.replaceAll('"','');
          link.download = aux;
        } else {
          link.download = 'file.xls'
        }
        link.click();
        
      });
      
    } else {
      this.searchValues.exportfile = 'true';
      this.reportSubscription.unsubscribe();
      this.reportSubscription = this.commonOrdersService
      .getReport(ParseOrderParams(this.page, this.searchValues || {}))
      .subscribe((response: any) => {
        let info = response.headers.get('Content-Disposition') ?? '';
        const link = document.createElement('a');
        const body = response.body;
        link.href = URL.createObjectURL(body);
        if (info) {
          var aux = info.split('=')[1];
          aux = aux.replaceAll('"','');
          link.download = aux;
        } else {
          link.download = 'file.xls'
        }
        link.click();
        
      });
    }
    
    
    
  }

  private onLoad(): void {
    this.tableSubscription.unsubscribe();
    this.tableSubscription = this.commonOrdersService
      .paginate(ParseOrderParams(this.page, this.searchValues || {}))
      .pipe(takeUntil(this.destroyed$))
      .subscribe(datatableInfo => {
        this.datatableInfo = datatableInfo;
        this.selectedOrders = R.pipe(
          R.propOr([], 'data'),
          R.pluck('id'),
          R.map((value: number) => ({ [value]: false })),
          R.mergeAll,
        )(datatableInfo);
        this.isLoading = false;
      });
  }

  public onCreateOrder(): void {
    if (this.currentBalance <= 0 ) {
      this.snotifyService.error(
        this.translation.notifications.simpleInsufficientFunds,
      );
      return;
    }
    this.router.navigateByUrl('/orders/new');
  }

  public onCreateBulk(): void {
    this.router.navigateByUrl('/orders/bulk');
  }

  public onSend(id: number, items: any, content: any): void {
    console.log(items.length, ' ',content);
    let contents = 'articulo';
    if (content) {
      contents = content;
    } else  if (items.length > 1) {
      contents = 'Articulos varios'
    } else {
      contents = items[0]?.name
    }

    this.router.navigateByUrl('/orders/package?steptwo=true&id=' + id + '&items='+contents);
    // this.router.navigateByUrl('/orders/rate?duplicate=true&id=' + id);
  }

  public onEdit(): void { }

  public onHide(id): void {
    this.commonOrdersService.handleOrderVisibility(id, true).subscribe(() => {
      this.snotifyService.simple(this.translation.notifications.hidden);
      this.onLoad();
    });
  }

  public onShow(id): void {
    this.commonOrdersService.handleOrderVisibility(id, false).subscribe(() => {
      this.snotifyService.simple(this.translation.notifications.visible);
      this.onLoad();
    });
  }

  public onBulkSend(): void {
    const ids = [];
    Object.keys(this.selectedOrders).forEach(key => {
      if (!this.selectedOrders[key]) {
        return;
      }
      ids.push(key);
    });
    this.commonOrdersService
      .createBatchShipment({ order_id: ids })
      .subscribe(({ id }) => {
        this.router.navigateByUrl(`/orders/bulk-prefilled-shipment/${id}`);
      });
  }

  public onBulkHide(): void {
    const ids = [];
    Object.keys(this.selectedOrders).forEach(key => {
      if (!this.selectedOrders[key]) {
        return;
      }
      ids.push(key);
    });

    this.commonOrdersService.handleOrdersVisibility(ids, true).subscribe(() => {
      this.snotifyService.simple(this.translation.notifications.hiddenOrders);
      this.onLoad();
    });
  }

  public onBulkShow(): void {
    const ids = [];
    Object.keys(this.selectedOrders).forEach(key => {
      if (!this.selectedOrders[key]) {
        return;
      }
      ids.push(key);
    });

    this.commonOrdersService
      .handleOrdersVisibility(ids, false)
      .subscribe(() => {
        this.snotifyService.simple(
          this.translation.notifications.visibleOrders,
        );
        this.onLoad();
      });
  }

  public onBulkSync(): void {
    if (this.syncInProgress) {
      this.snotifyService.info(this.translation.notifications.sync.inprogress);
      return;
    }

    this.commonOrdersService.syncWithStores().subscribe(() => {
      this.snotifyService.simple(
        this.translation.notifications.sync.start,
        undefined,
        { timeout: 5000 },
      );
      this.startSync();
    });
  }

  public startSync(): void {
    this.syncInProgress = true;
    this.commonOrdersService
      .checkSyncStatus()
      .pipe(
        finalize(() => {
          this.syncInProgress = false;
          this.snotifyService.success(
            this.translation.notifications.sync.complete,
            undefined,
            { timeout: 5000 },
          );
          this.onLoad();
        }),
      )
      .subscribe();
  }

  public onPageChange({ pageIndex }: PageEvent): void {
    this.page = pageIndex + 1;
    this.selectedOrders = {};
    this.onLoad();
  }

  public onAllSelected({ checked }: MatCheckboxChange): void {
    this.datatableInfo.data.forEach(
      row => (this.selectedOrders[row.id] = checked),
    );
  }

  public onOrderSelected({ checked }: MatCheckboxChange, id: number): void {
    this.selectedOrders[id] = checked;
  }

  public onShowHiddenChanged({ checked }: MatSlideToggleChange): void {
    this.showHidden = checked;
    this.form.controls.hidden_status.setValue(checked);
    this.selectedOrders = {};
    this.onLoad();
  }

  public onSelectOrder(id: number): void {
    this.selectedOrder = id;
  }

  public getType(row: DataOrder): string {
    return R.pathOr(
      'TBD',
      ['shipments', 0, 'chosen_rate', 'service_level_name'],
      row,
    );
  }

  public onClearSearch(): void {
    this.form.controls.search.setValue('');
  }
}
