<div>
  <div class="flex align-items-center mb-8">
    <div>
      <h1>{{ translation.bulk.title }}</h1>
    </div>
  </div>
  <div class="wrap">
    <div class="mb-2">{{ translation.bulk.important.title }}</div>
    <div class="mb-1" *ngFor="let item of translation.bulk.important.items">
      {{ item }}
    </div>
  </div>
  <div class="my-8">
    <div>
      <h2>
        {{ translation.bulk.question }}
      </h2>
    </div>
    <div>
      {{ translation.bulk.questionDescription }}
    </div>
  </div>
  <div class="mb-4">
    <commons-tutorial-sliders [elements]="elements"></commons-tutorial-sliders>
  </div>
  <div class="flex justify-content-center align-items-center mb-8">
    <div class="mr-1">
      {{ translation.bulk.download.text }}
    </div>
    <a class="common" href="/assets/files/Plantilla_Carga_CSV_Speedit.csv" download>{{
      translation.bulk.download.link
    }}</a>
  </div>
  <div class="flex justify-content-center">
    <input
      #fileInput
      class="none"
      type="file"
      accept=".csv,.xls,.ods"
      (change)="onImageChanged($event)"
    />
    <button mat-raised-button class="btn primary" (click)="onFileSelection()">
      <div class="mx-8">{{ translation.bulk.action }}</div>
    </button>
  </div>
</div>
