<form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()">
  <mat-card class="mb-4">
    <div class="p-3">
      <h3>{{ translation.form.packageTitle }}</h3>
    </div>
    <div class="divider-h is-gray"></div>
    <div class="p-3 g">
      <mat-form-field class="g-3 mb-4 px-3">
        <mat-label>
          {{ translation.form.labels.courier }}
        </mat-label>
        <mat-select required [errorStateMatcher]="matcher" formControlName="provider" [disabled]="true">
          <mat-option *ngFor="let option of availableCouriers" [value]="option">
            {{ getProviderName(option) }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="g-3 mb-4 px-3">
        <mat-label>
          {{ translation.form.labels.guide }}
        </mat-label>
        <mat-select required [errorStateMatcher]="matcher" formControlName="tracking_number">
          <mat-option *ngFor="let option of availableLabels" [value]="option.id">
            {{ option.tracking_number }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!-- <mat-form-field class="g-3 mb-4 px-3">
        <mat-label>
          {{ translation.form.labels.date }}
        </mat-label>
        <input matInput required formControlName="date" [errorStateMatcher]="matcher" type="date" name="date"
          [max]="upperDate" [min]="lowerDate" />
      </mat-form-field>
      <mat-form-field class="g-3 mb-4 px-3">
        <mat-label>
          {{ translation.form.labels.time }}
        </mat-label>
        <input matInput required formControlName="time" [errorStateMatcher]="matcher" type="time" name="time"
          [max]="upperTime" [min]="lowerTime" />
      </mat-form-field>
      <mat-form-field class="g-3 mb-4 px-3">
        <mat-label>
          {{ translation.form.labels.weight }}
        </mat-label>
        <input matInput required formControlName="weight" [errorStateMatcher]="matcher" min="0.01" type="number"
          name="weight" />
        <mat-error class="mb-2" *ngIf="
            form.controls.weight.errors && form.controls.weight.errors.required
          ">
          {{ translation.form.errors.weightRequired }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="g-3 mb-5 px-3">
        <mat-label>
          {{ translation.form.labels.packages }}
        </mat-label>
        <input matInput required formControlName="package_count" [errorStateMatcher]="matcher" type="number"
          name="packages" />
        <mat-error class="mb-2" *ngIf="
            form.controls.package_count.errors &&
            form.controls.package_count.errors.required
          ">
          {{ translation.form.errors.packageCountRequired }}
        </mat-error>
      </mat-form-field> -->
    </div>
  </mat-card>
  <!-- <mat-card class="mb-8">
    <div class="p-3">
      <h3>{{ translation.form.infoTitle }}</h3>
    </div>
    <div class="divider-h is-gray"></div>
    <div class="p-3 g">
      <mat-form-field class="g-3 mb-5 pr-3">
        <mat-label>
          {{ translation.form.labels.name }}
        </mat-label>
        <input matInput required formControlName="name" [errorStateMatcher]="matcher" name="name" />
        <mat-error class="mb-2" *ngIf="
            form.controls.name.errors && form.controls.name.errors.required
          ">
          {{ translation.form.errors.nameRequired }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="g-3 mb-5 px-3">
        <mat-label>
          {{ translation.form.labels.email }}
        </mat-label>
        <input matInput required formControlName="email" [errorStateMatcher]="matcher" type="email" name="email" />
        <mat-error class="mb-2" *ngIf="
            form.controls.email.errors && form.controls.email.errors.pattern
          ">
          {{ translation.form.errors.emailValid }}
        </mat-error>
        <mat-error class="mb-2" *ngIf="
            form.controls.email.errors && form.controls.email.errors.required
          ">
          {{ translation.form.errors.emailRequired }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="g-3 mb-5 px-3">
        <mat-label>
          {{ translation.form.labels.phone }}
        </mat-label>
        <input matInput required [errorStateMatcher]="matcher" formControlName="phone" name="phone" />
        <mat-error class="mb-2" *ngIf="
            form.controls.phone.errors && form.controls.phone.errors.required
          ">
          {{ translation.form.errors.phoneRequired }}
        </mat-error>
        <mat-error class="mb-2" *ngIf="
            form.controls.phone.errors && form.controls.phone.errors.pattern
          ">
          {{ translation.form.errors.phonePattern }}
        </mat-error>
        <mat-error class="mb-2" *ngIf="
            form.controls.phone.errors &&
            (form.controls.phone.errors.minLength ||
              form.controls.phone.errors.maxLength)
          ">
          {{ translation.form.errors.phoneLength }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="g-3 mb-5 pl-3">
        <mat-label>
          {{ translation.form.labels.zip }}
        </mat-label>
        <input matInput required formControlName="zip" [errorStateMatcher]="matcher" name="zip" readonly maxlength="6"
          minlength="4" />
      </mat-form-field>
      <mat-form-field class="g-3 mb-5 pr-3">
        <mat-label>
          {{ translation.form.labels.state }}
        </mat-label>
        <mat-select required formControlName="admin_level_1" [errorStateMatcher]="matcher" name="state">
          <mat-option *ngFor="let state of states" [value]="state[1]">
            {{ state[0] }}

          </mat-option>
        </mat-select>
        <mat-error class="mb-2" *ngIf="
            form.controls.admin_level_1.errors &&
            form.controls.admin_level_1.errors.required
          ">
          {{ translation.form.errors.stateRequired }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="g-3 mb-5 px-3">
        <mat-label>
          {{ translation.form.labels.county }}
        </mat-label>
        <input matInput required readonly formControlName="admin_level_2" [errorStateMatcher]="matcher" name="county" />
        <mat-error class="mb-2" *ngIf="
            form.controls.admin_level_2.errors &&
            form.controls.admin_level_2.errors.required
          ">
          {{ translation.form.errors.countyRequired }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="g-3 mb-5 px-3">
        <mat-label>
          {{ translation.form.labels.location }}
        </mat-label>
        <mat-select required formControlName="admin_level_3" [errorStateMatcher]="matcher" name="location">
          <mat-option *ngFor="let neighborhood of neighborhoods" [value]="neighborhood">
            {{ neighborhood }}
          </mat-option>
        </mat-select>
        <mat-error class="mb-2" *ngIf="
            form.controls.admin_level_3.errors &&
            form.controls.admin_level_3.errors.required
          ">
          {{ translation.form.errors.locationRequired }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="g-3 mb-5 pl-3">
        <mat-label>
          {{ translation.form.labels.street }}
        </mat-label>
        <input matInput required formControlName="street1" [errorStateMatcher]="matcher" name="street" />
        <mat-error class="mb-2" *ngIf="
            form.controls.street1.errors &&
            form.controls.street1.errors.required
          ">
          {{ translation.form.errors.streetRequired }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="g-3 mb-5 pr-3">
        <mat-label>
          {{ translation.form.labels.exterior }}
        </mat-label>
        <input matInput required formControlName="num_ext" [errorStateMatcher]="matcher" name="exterior" />
        <mat-error class="mb-2" *ngIf="
            form.controls.num_ext.errors &&
            form.controls.num_ext.errors.required
          ">
          {{ translation.form.errors.exteriorRequired }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="g-3 mb-5 px-3">
        <mat-label>
          {{ translation.form.labels.interior }}
        </mat-label>
        <input matInput formControlName="num_int" [errorStateMatcher]="matcher" name="interior" />
        <mat-error class="mb-2" *ngIf="
            form.controls.num_int.errors &&
            form.controls.num_int.errors.required
          ">
          {{ translation.form.errors.interiorRequired }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="g-3 mb-5 px-3">
        <mat-label>
          {{ translation.form.labels.references }}
        </mat-label>
        <input matInput formControlName="references" [errorStateMatcher]="matcher" name="references" />
      </mat-form-field>
      <mat-form-field class="g-3 mb-5 pl-3">
        <mat-label>
          {{ translation.form.labels.company }}
        </mat-label>
        <input matInput required formControlName="company" [errorStateMatcher]="matcher" name="company" />
        <mat-error class="mb-2" *ngIf="
            form.controls.company.errors &&
            form.controls.company.errors.required
          ">
          {{ translation.form.errors.companyRequired }}
        </mat-error>
      </mat-form-field>
    </div>
  </mat-card> -->
  <div class="g">
    <button mat-raised-button class="width-full g-3 btn primary" type="submit" [disabled]="form.valid ? null : true">
      {{ translation.form.buttons.save }}
    </button>
  </div>
</form>