// 1 capital letter, 1 number, 1 symbol, min length 8, max length 24
export const RegexPassword = /^((?=.*\d)(?=.*[A-Z])(?=.*\W).{8,24})$/;

export const RegexEmail = /^[^\s@]+@[^\s.@]+(\.[^\s.@]+)+$/;

export const RegexPhone = /^\d{10}$/;

export const RegexUrl = /^[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

export const RegexRFC = /([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{2})/i;
