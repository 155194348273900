import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EventHandlerInterceptor } from '@commons/interceptors/index';
import { RequestHandlerInterceptor } from '@commons/interceptors/request-handler.interceptor';
import { getPaginatorI18nIntl } from '@commons/models/index';
import { LoadingBarModule } from '@commons/modules/index';
import { ConfirmationModalModule } from '@commons/modules/modals';
import {
  SnotifyModule,
  SnotifyService,
  SnotifyToastConfig,
  ToastDefaults,
} from 'ng-snotify';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ConfirmationModalModule,
    HttpClientModule,
    LoadingBarModule,
    SnotifyModule,
  ],
  providers: [
    { provide: 'LANGUAGE', useValue: environment.featureFlags.language },
    {
      provide: 'FEATURE_FLAGS',
      useValue: Object.freeze(environment.featureFlags),
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EventHandlerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestHandlerInterceptor,
      multi: true,
    },
    {
      provide: 'SnotifyToastConfig',
      useValue: {
        ...ToastDefaults,
        toast: { ...ToastDefaults.toast, timeout: 5000 } as SnotifyToastConfig,
      },
    },
    {
      provide: MatPaginatorIntl,
      useValue: getPaginatorI18nIntl(environment.featureFlags.language),
    },
    SnotifyService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
