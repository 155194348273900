export interface AccountDetails {
  autocharge_overweight: boolean;
  balance: string;
  created_at: string;
  estimated_shipments: string;
  headquarter_id: number;
  id: number;
  international: any;
  seller_id: number;
  settings: {
    autocancel_label_request_carriers: string[];
    autocharge_out_of_area: string;
    available_carriers: string[];
    minimum_credits_available: string;
    referral_program: string;
    stp_clabe: string;
    type_rates: string;
    verified: string;
    label_format?: string;
    shopify_vendor?: string;
  };
  updated_at: string;
  user_id: number;
}
