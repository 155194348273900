import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from '@commons/models/index';
import { versions } from 'projects/client/src/environments/versions';

@Component({
  selector: 'commons-layout-menu',
  templateUrl: './layout-menu.component.html',
  styleUrls: ['./layout-menu.component.scss']
})
export class LayoutMenuComponent implements OnInit {
  readonly version = versions.toString();
  @Input() items: MenuItem[] = [];

  public isCollapsed = false;

  constructor() {}

  ngOnInit(): void {}

  toggleCollapsed(): void {
    this.isCollapsed = !this.isCollapsed;
  }
}
