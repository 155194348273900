<div class="flex flex-column align-items-center" *ngIf="!bulkOrder">
  <div class="border-radius-2 p-4 bg-yellow color-white mb-8 text-center w-100">
    {{ translation.prefillLoading }}
  </div>
  <div class="loader overflow-hidden">{{ translation.loading }}</div>
  <h4 class="color-yellow">{{ translation.loading }}</h4>
</div>
<div *ngIf="bulkOrder" class="flex flex-column">
  <h3 class="flex align-items-center mb-4">
    <div>{{ translation.prefillBulk.title }}</div>
  </h3>
  <div class="mb-8">{{ translation.prefillBulk.description }}</div>
  <mat-card *ngFor="let shipment of bulkOrder.batch_label.shipments; let idx = index" class="mb-12">
    <div class="card-header flex">
      <div class="flex flex-fill align-items-center">
        <div class="font-weight-700 mr-2">
          {{ translation.prefillBulk.target }}:
        </div>
        <div>
          {{ shipment.order?.address_to?.province }},
          {{ shipment.order?.address_to?.city }},
          {{ shipment.order?.address_to?.zip }}
        </div>
        <div class="p-2">|</div>
        <div class="font-weight-700 mr-2">
          {{ translation.prefillBulk.name }}:
        </div>
        <div>{{ shipment.order?.customer_fullname }}</div>
        <div class="p-2">|</div>
        <div class="font-weight-700 mr-2">
          {{ translation.prefillBulk.content }}:
        </div>
        <div>
          {{ shipment.order?.address_to?.contents }}
        </div>
      </div>
      <div class="mx-2 flex align-items-center" *ngIf="collapsedCard[idx]">
        <small class="postponed-badge">{{
          translation.prefillBulk.postponed
          }}</small>
      </div>
      <div class="flex align-items-center">
        <button mat-button class="btn primary" (click)="onPostponeClicked(shipment, idx)" *ngIf="!collapsedCard[idx]">
          {{ translation.prefillBulk.postpone }}
        </button>
        <button mat-button class="btn secondary" (click)="onSelectClicked(shipment, idx)" *ngIf="collapsedCard[idx]">
          {{ translation.prefillBulk.selectRate }}
        </button>
      </div>
    </div>
    <div *ngIf="!collapsedCard[idx]">
      <table mat-table [dataSource]="shipment.rates">
        <ng-container matColumnDef="checkbox">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <mat-radio-button color="primary" [checked]="ids[row.id]" [name]="shipment.id.toString()"
              (change)="onCheckboxSelected(row.id)"></mat-radio-button>
          </td>
        </ng-container>
        <ng-container matColumnDef="courier">
          <th mat-header-cell *matHeaderCellDef>
            {{ translation.prefillBulk.courier }}
          </th>
          <td mat-cell *matCellDef="let row">
            <img [src]="'./assets/images/companies/' + row.provider + '.png'" [alt]="row.provider" />
          </td>
        </ng-container>
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>
            {{ translation.prefillBulk.type }}
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.service_level_name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="estimate">
          <th mat-header-cell *matHeaderCellDef>
            {{ translation.prefillBulk.estimate }}
          </th>
          <td mat-cell *matCellDef="let row">
            {{ row.days }} {{ translation.prefillBulk.day }}
          </td>
        </ng-container>
        <ng-container matColumnDef="rate">
          <th mat-header-cell *matHeaderCellDef>
            {{ translation.prefillBulk.rate }}
          </th>
          <td mat-cell *matCellDef="let row">
            {{
            row.amount_local
            | currency: row.currency_local:'symbol-narrow':'1.2-2'
            }}
            {{ row.currency_local }}
          </td>
        </ng-container>
        <ng-container matColumnDef="extra">
          <th mat-header-cell *matHeaderCellDef>
            {{ translation.prefillBulk.extra }}
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="flex flex-column">
              <div>
                {{ translation.prefillBulk.insurance }} +
                {{
                row.insurance_amount
                | currency: row.currency_local:'symbol-narrow':'1.2-2'
                }}
              </div>
              <div>
                {{ translation.prefillBulk.outOfArea }} +
                {{
                row.out_of_area_pricing
                | currency: row.currency_local:'symbol-narrow':'1.2-2'
                }}
              </div>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>
      </table>
    </div>
  </mat-card>
  <div class="flex border-radius-2 p-4 bg-gray color-white mb-8 align-items-center">
    <div class="font-weight-700 mr-2 flex-fill flex justify-content-start">
      {{ translation.prefillBulk.total }}:
    </div>
    <div>
      {{ total | currency: translation.currency:'symbol-narrow':'1.2-2' }}
    </div>
  </div>
  <div class="pb-12 flex justify-content-center">
    <button mat-flat-button class="btn primary" (click)="onSave()">
      {{ translation.prefillBulk.save }}
    </button>
  </div>
</div>