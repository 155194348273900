<mat-card class="width-max-content">
  <div class="px-12 px-xs-2 pt-8">
    <div class="flex justify-content-center mb-8">
      <h1>{{ translation.title }}</h1>
    </div>
    <form class="flex flex-column" [formGroup]="form" (ngSubmit)="onSubmit()">
      <mat-form-field class="mb-4">
        <mat-label>{{ translation.labels.newPassword }}</mat-label>
        <input
          matInput
          required
          [type]="showField.password ? 'text' : 'password'"
          formControlName="password"
          name="password"
          [errorStateMatcher]="matcher"
        />
        <div
          matSuffix
          class="input-icon color-grey-light-1 pointer"
          (click)="toggleShowPassword('password')"
          *ngIf="!showField.password"
        >
          <i class="cursor-pointer fas fa-eye pr-2"></i>
        </div>
        <div
          matSuffix
          class="input-icon color-grey-light-1 pointer"
          (click)="toggleShowPassword('password')"
          *ngIf="showField.password"
        >
          <i class="cursor-pointer fas fa-eye-slash pr-2"></i>
        </div>
        <mat-error
          class="mb-2"
          *ngIf="
            form.controls.password.errors &&
            form.controls.password.errors.required
          "
        >
          {{ translation.errors.passwordRequired }}
        </mat-error>
        <mat-hint class="mb-2">
          {{ translation.errors.password }}
        </mat-hint>
      </mat-form-field>
      <mat-form-field class="mt-8">
        <mat-label>
          {{ translation.labels.confirmPassword }}
        </mat-label>
        <input
          matInput
          required
          [type]="showField.confirmPassword ? 'text' : 'password'"
          formControlName="confirmPassword"
          [errorStateMatcher]="matcher"
          name="confirmPassword"
        />
        <div
          matSuffix
          class="input-icon color-grey-light-1 pointer"
          (click)="toggleShowPassword('confirmPassword')"
          *ngIf="!showField.confirmPassword"
        >
          <i class="cursor-pointer fas fa-eye pr-2"></i>
        </div>
        <div
          matSuffix
          class="input-icon color-grey-light-1 pointer"
          (click)="toggleShowPassword('confirmPassword')"
          *ngIf="showField.confirmPassword"
        >
          <i class="cursor-pointer fas fa-eye-slash pr-2"></i>
        </div>
      </mat-form-field>
      <mat-error
        class="custom-error mb-6"
        style="margin-top: 0"
        *ngIf="form.hasError('isPasswordUnmatched')"
      >
        {{ translation.errors.passwordMatch }}
      </mat-error>
      <div class="flex align-items-center mb-8">
        <div class="flex flex-fill justify-content-start">
          <a class="action" routerLink="/auth/login">
            <i class="fas fa-arrow-left"></i>
            <div>
              {{ translation.links.login }}
            </div>
          </a>
        </div>
        <div class="flex flex-fill justify-content-end">
          <button
            mat-raised-button
            class="width-full btn secondary"
            type="submit"
            [disabled]="form.valid ? null : true"
          >
            {{ translation.buttons.reset }}
          </button>
        </div>
      </div>
    </form>
  </div>
</mat-card>
