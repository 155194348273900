<mat-card class="width-max-content">
  <div class="px-12 px-xs-2 pt-8">
    <div class="flex justify-content-center mb-8">
      <h1>{{ translation.title }}</h1>
    </div>
    <form
      class="flex flex-column"
      [formGroup]="form"
      (ngSubmit)="onSubmit($event)"
    >
      <mat-form-field class="mb-12">
        <mat-label>{{ translation.labels.email }}</mat-label>
        <input
          matInput
          required
          formControlName="email"
          [errorStateMatcher]="matcher"
          name="email"
          type="email"
          min="4"
          max="100"
        />
        <mat-error
          class="mb-2"
          *ngIf="
            form.controls.email.errors && form.controls.email.errors.pattern
          "
        >
          {{ translation.errors.emailValid }}
        </mat-error>
        <mat-error
          class="mb-2"
          *ngIf="
            form.controls.email.errors && form.controls.email.errors.required
          "
        >
          {{ translation.errors.emailRequired }}
        </mat-error>
      </mat-form-field>
      <div class="flex align-items-center mb-8">
        <div class="flex flex-fill justify-content-start">
          <a class="action" routerLink="/auth/login">
            <i class="fas fa-arrow-left"></i>
            <div>
              {{ translation.links.login }}
            </div>
          </a>
        </div>
        <div class="flex flex-fill justify-content-end">
          <button
            mat-raised-button
            class="width-full btn secondary"
            type="submit"
            [disabled]="form.valid && !disableButton ? null : true"
          >
            {{ translation.buttons.recover }}
          </button>
        </div>
      </div>
    </form>
  </div>
</mat-card>
