<commons-overlay type="dark" #background>
  <div class="modal" [ngClass]="{ wide: wide }" [ngStyle]="customStyle">
    <div
      class="py-6 bg-white flex flex-column align-items-center border-radius-5 modal-container"
    >
      <i
        class="far fa-times-circle close-button d-print-none"
        *ngIf="showCloseButton"
        (click)="closeButtonClick.next()"
      ></i>
      <div class="header mb-8 flex justify-content-center pb-4 d-print-none" *ngIf="title">
        <h4 class="text-center font-weight-400 font-size-5">{{ title }}</h4>
      </div>
      <div class="mx-12 flex align-items-center flex-column">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</commons-overlay>
