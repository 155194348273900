import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { DataOrder, OrderShipment } from '@commons/models/orders.model';
import { Subscription } from 'rxjs';
import { CommonOrdersService } from '../common-orders.service';
import {
  CommonOrdersLanguages,
  CommonOrdersTranslation,
} from '../common-orders.translation';
import * as R from 'ramda';

@Component({
  selector: 'commons-view-order',
  templateUrl: './view-order.component.html',
  styleUrls: ['./view-order.component.scss'],
})
export class ViewOrderComponent implements OnInit, OnDestroy {
  @Output() closeClicked = new EventEmitter();

  @Input() orderId: number;

  public order: DataOrder;

  public expand: any = {};

  public readonly translation: CommonOrdersTranslation =
    CommonOrdersLanguages[this.language];

  private orderSubscription = Subscription.EMPTY;

  constructor(
    @Inject('LANGUAGE')
    private readonly language: keyof typeof CommonOrdersLanguages,
    private readonly commonOrdersService: CommonOrdersService,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.orderSubscription = this.commonOrdersService
      .getOrder(this.orderId)
      .subscribe((data) => {
        this.order = data;
      });
  }

  ngOnDestroy(): void {
    this.orderSubscription.unsubscribe();
  }

  onClose(): void {
    this.closeClicked.emit();
  }

  toggle(field: string): void {
    this.expand[field] = !this.expand[field];
  }

  saveAddress(address: any): void {}

  copyOrder(order: any): void {
    this.router.navigateByUrl('/orders/rate?duplicate=true&id=' + order.id);
  }

  public getType(row: OrderShipment): string {
    return R.pathOr(
      'TBD',
      ['chosen_rate', 'service_level_name'],
      row,
    );
  }

  public getProvider(row: OrderShipment): string {
    return R.pathOr(
      'TBD',
      ['chosen_rate', 'provider'],
      row,
    );
  }
}
