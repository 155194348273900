import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomErrorStateMatcher } from '@commons/helpers/index';
import { AuthService } from '@commons/modules/auth/auth.service';
import { SnotifyService } from 'ng-snotify';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ClientProfileService } from '../client-profile.service';
import {
  ClientProfileLanguages,
  ClientProfileTranslation,
} from '../client-profile.translation';

@Component({
  selector: 'commons-client-profile-data',
  templateUrl: './client-profile-data.component.html',
  styleUrls: ['./client-profile-data.component.scss'],
})
export class ClientProfileDataComponent implements OnInit, OnDestroy {
  @Input() dataForm: FormGroup;

  @Input() passwordForm: FormGroup;

  @Output() storeFormInfo = new EventEmitter<void>();

  public readonly translation: ClientProfileTranslation =
    ClientProfileLanguages[this.language];

  /**
   * Flag to show or hide password
   */
  public showPassword = false;

  /**
   * Flag to show or hide password
   */
  public showConfirmPassword = false;

  /**
   *
   */
  public matcher = new CustomErrorStateMatcher();

  private dataSubscription = Subscription.EMPTY;

  private passwordSubscription = Subscription.EMPTY;

  private subscription = Subscription.EMPTY;

  constructor(
    @Inject('LANGUAGE')
    private readonly language: keyof typeof ClientProfileLanguages,
    private readonly clientProfileService: ClientProfileService,
    private readonly snotifyService: SnotifyService,
    private readonly authService: AuthService,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.dataSubscription.unsubscribe();
    this.passwordSubscription.unsubscribe();
  }

  /**
   *
   */
  public toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }

  /**
   *
   */
  public toggleShowConfirmPassword(): void {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  onDataSubmit(): void {
    this.dataSubscription = this.clientProfileService
      .updateProfile(this.dataForm.value)
      .pipe(tap(() => this.authService.update()))
      .subscribe(
        () => {
          this.storeFormInfo.emit();
          this.snotifyService.success(this.translation.snotify.updatedData);
        },
        () => {
          this.snotifyService.error(this.translation.snotify.errorUpdatingData);
        },
      );
  }

  onPasswordSubmit(): void {
    const {
      password,
      confirmPassword: confirmation_password,
    } = this.passwordForm.value;
    this.passwordSubscription.unsubscribe();
    this.passwordSubscription = this.clientProfileService
      .updatePassword({ password, confirmation_password })
      .subscribe(
        () => {
          this.dataForm.markAsPristine();
          this.passwordForm.markAsPristine();
          this.snotifyService.success(this.translation.snotify.updatedPassword);
          this.authService.logout();
          this.router.navigateByUrl('/auth/login');
        },
        () => {
          this.snotifyService.error(
            this.translation.snotify.errorUpdatingPassword,
          );
        },
      );
  }
}
