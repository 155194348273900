import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NewOrderComponent } from './new-order.component';

const routes: Routes = [
  { path: '', redirectTo: 'address' },
  {
    path: '',
    component: NewOrderComponent,
    children: [
      {
        path: 'address',
        loadChildren: () =>
          import('./address/address.module').then((m) => m.AddressModule),
      },
      {
        path: 'package',
        loadChildren: () =>
          import('./package/package.module').then((m) => m.PackageModule),
      },
      {
        path: 'rate',
        loadChildren: () =>
          import('./rate/rate.module').then((m) => m.RateModule),
      },
      {
        path: 'completed',
        loadChildren: () =>
          import('./completed/completed.module').then((m) => m.CompletedModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NewOrderRoutingModule {}
