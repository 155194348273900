import { ConfirmationModal } from './confirmation-modal.component';

export class ConfirmationModalTranslation {
  title: string;
  description: string;
  okMessage: string;
  cancelMessage: string;
  understand: string;
}

const ES: ConfirmationModalTranslation = {
  title: '¿Estás seguro?',
  description: 'Una vez realizada esta acción, no podrás deshacerla.',
  okMessage: 'Continuar',
  cancelMessage: 'Cancelar',
  understand: 'Entendido',
};

const EN: ConfirmationModalTranslation = {
  title: 'Are you sure? ',
  description: 'Once you do this, you won\'t be able to undo it.',
  okMessage: 'Continue',
  cancelMessage: 'Cancel',
  understand: 'I understand',
};

export const ConfirmationModalLanguages = {
  EN,
  ES,
};

const ES_Save: ConfirmationModal = {
  title: 'Speedit',
  description: '¿Está seguro de guardar estos cambios?',
  okMessage: 'Aceptar',
  cancelMessage: 'Cancelar'
};

const EN_Save: ConfirmationModal = {
  title: 'Speedit',
  description: 'Are you sure to save these changes?',
  okMessage: 'Acept',
  cancelMessage: 'Cancel'
};

export const ConfirmationModalSaveLanguages = {
  EN: EN_Save,
  ES: ES_Save,
};
