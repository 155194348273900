<div class="flex flex-column">
  <div
    class="flex my-12 align-items-center"
    [ngStyle]="{ 'min-height': minSizeInPixels }"
  >
    <div
      class="action"
      [ngClass]="!selectedIndex ? 'is-disabled' : ''"
      (click)="onChevronAction('back')"
    >
      <i class="fas fa-chevron-left"></i>
    </div>
    <div class="flex-fill">
      <div
        *ngFor="let element of elements; let idx = index"
        class="flex flex-column justify-content-center align-items-center"
      >
        <img
          [src]="element.url"
          alt="image"
          class="mb-4"
          [ngClass]="idx !== selectedIndex ? 'none' : ''"
        />
        <div [ngClass]="idx !== selectedIndex ? 'none' : ''">
          {{ element.text }}
        </div>
      </div>
    </div>
    <div
      class="action"
      [ngClass]="selectedIndex >= elements.length - 1 ? 'is-disabled' : ''"
      (click)="onChevronAction('next')"
    >
      <i class="fas fa-chevron-right"></i>
    </div>
  </div>
  <div class="flex justify-content-center">
    <div
      *ngFor="let element of elements; let idx = index"
      class="step"
      [ngClass]="idx === selectedIndex ? 'is-active' : ''"
      (click)="onStepSelected(idx)"
    ></div>
  </div>
</div>
