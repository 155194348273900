export class AuthRecoverPasswordTranslation {
  buttons: {
    recover: string;
  };
  errors: {
    emailValid: string;
    emailRequired: string;
  };
  labels: {
    email: string;
  };
  links: {
    login: string;
  };
  modal: {
    title: string;
    description: string;
  };
  title: string;
}

const ES: AuthRecoverPasswordTranslation = {
  buttons: {
    recover: `Enviar`,
  },
  errors: {
    emailValid: `Por favor introduzca una dirección de Correo electrónico válida.`,
    emailRequired: `El campo Correo electrónico es requerido.`,
  },
  labels: {
    email: `Correo electrónico`,
  },
  links: {
    login: `Iniciar Sesión`,
  },
  modal: {
    title: 'Recuperación de contraseña',
    description:
      'Se han enviado las instrucciones de recuperación de contraseña a tu correo electrónico.',
  },
  title: `Recuperar Contraseña`,
};

const EN: AuthRecoverPasswordTranslation = {
  buttons: {
    recover: `Send Email`,
  },
  errors: {
    emailValid: `Please insert a valid Email address.`,
    emailRequired: `Email field is required.`,
  },
  labels: {
    email: `Email`,
  },
  links: {
    login: `Sign In`,
  },
  modal: {
    title: 'Password recovery',
    description:
      'The password recovery instructions have been sent to your e-mail address.',
  },
  title: `Recover Password`,
};

export const AuthRecoverPasswordLanguages = {
  ES,
  EN,
};
