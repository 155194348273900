export class ClientProfileTranslation {
  buttons: {
    save: string;
  };
  labels: {
    required: string;
    data: {
      email: string;
      name: string;
      phone: string;
      password: string;
      confirmPassword: string;
    };
    billing: {
      rfc: string;
      name: string;
      paymentMethod: string;      
      automatic: string;
      tax_regime: string;
      street1: string;
      street2: string;
      zip: string;
      city: string;
      province: string;
      country: string;
      phone: string;
      email: string;
    };
  };
  errors: {
    data: {
      emailValid: string;
      emailRequired: string;
      nameRequired: string;
      nameLength: string;
      phoneRequired: string;
      phonePattern: string;
      phoneLength: string;
      passwordRequired: string;
      passwordPattern: string;
      passwordUnmatched: string;
    };
    billing: {
      rfcRequired: string;
      nameRequired: string;
      paymentMethodRequired: string;
      rfcLength: string;
      rfcPattern: string;
      regimenRequired: string;
      street1: string;
      street2: string;
      zip: string;
      zipminlength: string;
      zipPattern: string;
      city: string;
      province: string;
      country: string;
      phone: string;
      phoneMax: string;
      email: string;
      emailError: string;
    };
  };
  title: string;
  form: {
    data: {
      title: string;
      passwordTitle: string;
    };
    billing: {
      title: string;
    };
  };
  tabs: {
    data: string;
    billing: string;
  };
  tax_regime: {
    value: string;
    text: string;
  }[];
  paymentMethods: {
    text: string;
    value: string;
  }[];
  country: {
    text: string;
    value: string;
  }[]
  snotify: {
    errorSubmittingBilling: string;
    errorUpdatingPassword: string;
    errorUpdatingData: string;
    submittedBilling: string;
    updatedPassword: string;
    updatedData: string;
  };
  logout: string;
  confirmTitle: string;
  confirmDescription: string;
}

const EN: ClientProfileTranslation = {
  buttons: {
    save: 'Save changes',
  },
  labels: {
    required: '* Required field',
    data: {
      email: 'Email',
      password: 'Password',
      confirmPassword: 'Confirm password',
      name: 'Full name',
      phone: 'Phone (10 digits)',
    },
    billing: {
      rfc: 'RFC',
      name: 'Reason',
      paymentMethod: 'Payment Method',
      automatic: 'Automatic billing',
      tax_regime: 'Regimen',
      street1: 'Street and number',
      street2: 'Street 2',
      zip: 'ZIP',
      city: 'City',
      province: 'Province',
      country: 'Country',
      phone: 'Phone number',
      email: 'Email'
    },
  },
  errors: {
    data: {
      emailValid: `Please insert a valid Email address.`,
      emailRequired: `Email field is required.`,
      passwordRequired: `Password field is required.`,
      passwordUnmatched: `Password fields doesn't match.`,
      passwordPattern: `password field must present 8 to 24 characters, at least 1
          Uppercase, 1 Number and 1 Symbol.`,
      nameRequired: `Full name field is required.`,
      nameLength: `Full name field must have between 2 and 50 characters.`,
      phoneRequired: `Phone field is required.`,
      phonePattern: `Phone field can only contain numbers.`,
      phoneLength: `Phone field can only have 10 numbers`,
    },
    billing: {
      rfcRequired: 'RFC field is required.',
      nameRequired: 'Reason field is required.',
      paymentMethodRequired: 'Payment Method is required.',
      rfcLength: `RFC Field must have a 12 characters at least`,
      rfcPattern: `RFC Field is invalid`,
      regimenRequired: 'Regimen is required',
      street1: 'Address and number are required',
      street2: 'The field is required',
      zip: 'Zip code is required',
      zipminlength: 'Zip field must contain at least 5 numbers',
      zipPattern: 'Zip field only accepts numbers',
      city: 'City is required',
      province: 'Province is required',
      country: 'Country is required',
      phone: 'Phone is required',
      phoneMax: 'Phone must have at least 10 numbers',
      email: 'Email is required',
      emailError: 'Not valid Email'
    },
  },
  title: 'Profile',
  form: {
    data: {
      title: 'Personal Info',
      passwordTitle: 'Change Password',
    },
    billing: {
      title: 'Billing Info',
    },
  },
  tabs: {
    data: 'Personal Info',
    billing: 'Billing Info',
  },
  tax_regime: [
    { value: 'Moral', text: 'Moral'},
    { value: 'Física', text: 'Física'}
  ],
  paymentMethods: [
    { value: 'CASH', text: 'Cash' },
    { value: 'CHECK', text: 'Check' },
    { value: 'TRANSFER', text: 'Transfer' },
    { value: 'EMONEY', text: 'E-Money' },
    { value: 'CREDITCARD', text: 'Credit Card' },
    { value: 'DEBITCARD', text: 'Debit Card' },
  ],
  country: [
    { value: 'México', text: 'México'}
  ],
  snotify: {
    errorSubmittingBilling: "Couldn't update billing information",
    errorUpdatingPassword: "Couldn't change password",
    errorUpdatingData: "Couldn't update account information",
    submittedBilling: 'Billing info updated successfully',
    updatedPassword: 'Password changed successfully',
    updatedData: 'Account information updated successfully',
  },
  logout: 'Changing your password will log you out',
  confirmTitle: 'Are you sure you wanna leave?',
  confirmDescription: 'All unsaved changes will be lost.',
};

const ES: ClientProfileTranslation = {
  buttons: {
    save: 'Guardar cambios',
  },
  labels: {
    required: '* Campo obligatorio',
    data: {
      email: `Correo electrónico`,
      password: `Contraseña`,
      confirmPassword: `Confirmar contraseña`,
      name: `Nombre completo`,
      phone: `Teléfono (10 dígitos)`,
    },
    billing: {
      rfc: 'RFC',
      name: 'Nombre denominación o Razón social',
      paymentMethod: 'Forma de pago',
      automatic: 'Facturación automática',
      tax_regime: 'Regimen',
      street1: 'Calle y Número',
      street2: 'Colonia',
      zip: 'Código Postal',
      city: 'Ciudad',
      province: 'Delegación',
      country: 'Pais',
      phone: 'Número de Teléfono',
      email: 'Correo electrónico'
    },
  },
  errors: {
    data: {
      emailValid: `Por favor introduzca una dirección de Correo electrónico válida.`,
      emailRequired: `El campo Correo electrónico es requerido.`,
      passwordRequired: `El campo Contraseña es requerido.`,
      passwordUnmatched: `Las contraseñas no coinciden.`,
      passwordPattern: `El campo contraseña debe poseer de 8 a 24 caracteres, al menos 1
          Mayuscula, 1 Número y 1 Simbolo.`,
      nameRequired: `El campo Nombre Completo es requerido.`,
      nameLength: `El campo Nombre debe poseer entre 2 y 50 caracteres.`,
      phoneRequired: `El campo Teléfono es requirido.`,
      phonePattern: `El campo Teléfono acepta solo números.`,
      phoneLength: `El campo Teléfono debe poseer 10 números.`,
    },
    billing: {
      rfcRequired: 'El campo RFC es requerido.',
      nameRequired: 'El campo Razón social es requerido.',
      paymentMethodRequired: 'El campo Forma de pago es requerido.',
      rfcLength: `El campo RFC debe poseer al menos 12 caracteres.`,
      rfcPattern: `Campo RFC Inválido.`,
      regimenRequired: 'El campo Regimen es requerido',
      street1: 'El campo calle y número es requerido',
      street2: 'El campo colonia es requerido',
      zip: 'El campo código postal es requerido',
      zipminlength: 'El campo código postal debe tener al menos 5 números',
      zipPattern: 'El campo código postal solo acepta números',
      city: 'El campo ciudad es requerido',
      province: 'El campo delegación es requerido',
      country: 'El campo país es requerido',
      phone: 'El campo teléfono es requerido',
      phoneMax: 'El campo teléfono debe tener al menos 10 números',
      email: 'El campo correo es requerido',
      emailError: 'Formato de correo no válido'
    },
  },
  title: 'Mi Perfil',
  form: {
    data: {
      title: 'Datos personales',
      passwordTitle: 'Cambiar contraseña',
    },
    billing: {
      title: 'Datos de facturación',
    },
  },
  tabs: {
    data: 'Datos personales',
    billing: 'Datos de facturación',
  },
  tax_regime: [
    { value: 'MORAL', text: 'Moral' },
    { value: 'FISICA', text: 'Física'}
  ],
  paymentMethods: [
    { value: 'CASH', text: 'Efectivo' },
    { value: 'CHECK', text: 'Cheque nominativo' },
    { value: 'TRANSFER', text: 'Transferencia electrónica de fondos' },
    { value: 'EMONEY', text: 'Dinero electrónico' },
    { value: 'CREDITCARD', text: 'Tarjeta de crédito' },
    { value: 'DEBITCARD', text: 'Tarjeta de débito' },
  ],
  country: [
    { value: 'México', text: 'México'}
  ],
  snotify: {
    errorSubmittingBilling: 'Error actualizando información de facturación',
    errorUpdatingPassword: 'Error cambiando contraseña',
    errorUpdatingData: 'Error actualizando información de cuenta',
    submittedBilling: 'Información de facturación actualizada exitosamente',
    updatedPassword: 'Contraseña actualizada exitosamente',
    updatedData: 'Información de cuenta actualizada exitosamente',
  },
  logout: 'Al cambiar contraseña se cerrará su sesión.',
  confirmTitle: '¿Está seguro que desea abandonar?',
  confirmDescription: 'Se perderán todos los cambios sin guardar.',
};

export const ClientProfileLanguages = {
  ES,
  EN,
};
