import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  APIRoutes,
  BatchLabel,
  BulkOrder,
  DataOrder,
  DatatableOrders,
} from '@commons/models/index';
import { Observable, Subject, timer } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CommonOrdersService {
  private readonly routes = new APIRoutes();
  private syncStatus = new Subject<boolean>();
  private syncBatchStatus = new Subject<any>();
  private syncShipmentBatchStatus = new Subject<any>();

  constructor(private readonly http: HttpClient) {}

  public paginate(params: HttpParams): Observable<DatatableOrders> {
    return this.http.get<DatatableOrders>(this.routes.Orders.All, { params });
  }
  public getReport(params: HttpParams): Observable<any> {
    return this.http.get(this.routes.Orders.All,{ params, responseType: 'blob' , observe:'response'});
  }

  public getOrder(id: number): Observable<DataOrder> {
    return this.http
      .get<{ data: DataOrder }>(this.routes.Orders.Entity(id))
      .pipe(map(({ data }) => data));
  }

  public syncWithStores(): Observable<unknown> {
    return this.http.post(this.routes.OrdersSyncing.All, null);
  }

  public checkSyncStatus(): Observable<OrderSyncingStatus> {
    return timer(0, 3500).pipe(
      takeUntil(
        this.syncStatus.asObservable().pipe(filter(status => status === true)),
      ),
      switchMap(() =>
        this.http
          .get<OrderSyncingStatus>(this.routes.OrdersSyncing.All, {
            headers: { isSilent: 'true' },
          })
          .pipe(tap(({ status }) => this.syncStatus.next(status === 'ready'))),
      ),
    );
  }

  public createBatch(form: FormData): Observable<void> {
    return this.http.post<void>(this.routes.UserImports.All, form);
  }

  public createBatchShipment(payload: {
    order_id: number[];
  }): Observable<{ id: number }> {
    return this.http
      .post<{ data: { id: number } }>(this.routes.BatchLabels.All, payload)
      .pipe(map(({ data }) => data));
  }

  public getBatchShipment(id: number): Observable<BatchLabel> {
    return timer(0, 3500).pipe(
      takeUntil(
        this.syncShipmentBatchStatus.asObservable().pipe(
          filter(({ step, status }) => {
            if (step === 'packaging') {
              return status === 'WAITING' || status === 'WITH_ERRORS';
            }
            return status === 'SUCCESS' || status === 'WITH_ERRORS';
          }),
        ),
      ),
      switchMap(() =>
        this.http
          .get<{ data: BatchLabel }>(this.routes.BatchLabels.Entity(id), {
            headers: { isSilent: 'true' },
          })
          .pipe(
            tap(response => this.syncShipmentBatchStatus.next(response.data)),
            map(({ data }) => data),
          ),
      ),
    );
  }

  public saveBatchShipment(
    id: number,
    payload: { rate_id: number[] },
  ): Observable<any> {
    return this.http.post<any>(
      this.routes.BatchLabels.Custom([`${id}`, 'create_labels']),
      payload,
    );
  }

  public createBatchLabels(payload): Observable<void> {
    return this.http.post<void>(
      this.routes.UserImports.Custom(['create_labels']),
      payload,
    );
  }

  public createShipment(id: number): Observable<void> {
    return this.http.post<void>(
      this.routes.Orders.Custom([id.toString(), 'duplicate']),
      {},
    );
  }

  public handleOrderVisibility(
    id: number,
    hidden_status: boolean,
  ): Observable<void> {
    return this.http.post<void>(this.routes.Orders.Custom(['hide_status']), {
      hidden_status,
      order_id: [id],
    });
  }

  public handleOrdersVisibility(
    ids: number[],
    hidden_status: boolean,
  ): Observable<void> {
    return this.http.post<void>(this.routes.Orders.Custom(['hide_status']), {
      hidden_status,
      order_id: ids,
    });
  }

  public checkBatchCreation(): Observable<BulkOrder> {
    return timer(0, 3500).pipe(
      takeUntil(
        this.syncBatchStatus.asObservable().pipe(
          filter(({ batch_label, status }) => {
            const { step, status: labelStatus } = batch_label;
            if (step === 'packaging') {
              return status === 'finished' || status === 'with_errors';
            }
            return labelStatus === 'SUCCESS' || labelStatus === 'WITH_ERRORS';
          }),
        ),
      ),
      switchMap(() =>
        this.http
          .get<{ user_import: BulkOrder }>(
            this.routes.UserImports.Custom(['status']),
            {
              headers: { isSilent: 'true' },
            },
          )
          .pipe(
            tap(({ user_import }) => this.syncBatchStatus.next(user_import)),
            map(({ user_import }) => user_import),
          ),
      ),
    );
  }
}

interface OrderSyncingStatus {
  id: number;
  user_id?: any;
  status: string;
  last_synced_at?: any;
  last_synced_status: 'ready' | 'queued';
  created_at: string;
  updated_at: string;
  headquarter_id: number;
}
