import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BulkOrder } from '@commons/models/orders.model';
import { CreditsService } from '@commons/modules/credits';
import { ConfirmationModalService } from '@commons/modules/modals';
import { AccountDetailsService } from '@commons/services/account-details.service';
import { SnotifyService } from 'ng-snotify';
import * as R from 'ramda';
import { Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CommonOrdersService } from '../common-orders.service';
import {
  CommonOrdersLanguages,
  CommonOrdersTranslation
} from '../common-orders.translation';

@Component({
  selector: 'commons-bulk-prefill-order',
  templateUrl: './bulk-prefill-order.component.html',
  styleUrls: ['./bulk-prefill-order.component.scss'],
})
export class BulkPrefillOrderComponent implements OnInit, OnDestroy {
  public readonly translation: CommonOrdersTranslation =
    CommonOrdersLanguages[this.language];

  public bulkOrder: BulkOrder;

  public collapsedCard: any = {};

  public total = 0;

  public tableColumns = [
    'checkbox',
    'courier',
    'type',
    'estimate',
    'rate',
    'extra',
  ];

  ids: any = {};

  private currentBalance: number;

  private checkBatchSubscription = Subscription.EMPTY;

  private saveBatchSubscription = Subscription.EMPTY;

  private creditsSubscription = Subscription.EMPTY;

  private submitSubscription = Subscription.EMPTY;

  private modalSubscription = Subscription.EMPTY;

  constructor(
    @Inject('LANGUAGE')
    public readonly language: keyof typeof CommonOrdersLanguages,
    private readonly router: Router,
    private readonly commonOrdersService: CommonOrdersService,
    private readonly snotifyService: SnotifyService,
    private readonly accountDetailsService: AccountDetailsService,
    private readonly creditsService: CreditsService,
    private readonly confirmationModalService: ConfirmationModalService,
  ) { }

  ngOnInit(): void {
    this.creditsSubscription = this.creditsService.creditsWereUpdated
      .pipe(startWith(0))
      .subscribe(() => {
        this.accountDetailsService
          .get()
          .pipe(map(({ balance }) => Number(balance)))
          .subscribe(balance => (this.currentBalance = balance));
      });
    this.checkBatchSubscription = this.commonOrdersService
      .checkBatchCreation()
      .subscribe((bulkOrder: any) => {
        console.log(bulkOrder);
        bulkOrder.batch_label.shipments.forEach((shipment: any) => {
          shipment.rates.forEach((s: any) => {
            switch (s.service_level_name) {
              case 'International Economy':
                s.service_level_name = 'International Economy'
                s.days = '5'
                break;
              case 'International Priority':
                s.service_level_name = 'International Priority'
                s.days = '3'
                break;
              case 'Fedex Express Saver':
                s.service_level_name = 'Envío Ecónomico'
                s.days = '3 a 5'
                break;
              case 'Terrestre':
                  s.service_level_name = 'Envío Ecónomico'
                  s.days = '3 a 5'
                  break;
              case 'Standard Overnight':
                s.service_level_name = 'Envío Express'
                s.days = '1 a 3'
                break;
              case 'Ecoexpress':
                s.service_level_name = 'Envío Ecónomico'
                s.days = '3 a 5'
                break;
              case 'UPS Express':
                s.service_level_name = 'Envío Ecónomico'
                s.days = '3 a 5'
                break;
              case 'Servicio Express':
                s.service_level_name = 'Envío Express'
                s.days = '1 a 3'
                break;
              case 'Express':
                s.service_level_name = 'Envío Express'
                s.days = '1 a 3'
                break;
              case '99MINUTOS':
                s.service_level_name = '99Minutos'
                break;
              case 'PROXIMO DIA':
                s.service_level_name = 'Próximo día'
                break;

              default:
                break;
            }
          });
        });
        if (
          !(
            bulkOrder.status === 'finished' ||
            bulkOrder.status === 'with_errors'
          )
        ) {
          return;
        }
        this.bulkOrder = bulkOrder;
        const ids = R.pipe(
          R.pathOr([], ['batch_label', 'shipments']),
          R.pluck('rates'),
          R.map(R.head),
          R.pluck('id'),
        )(bulkOrder);
        ids.forEach((id: any) => this.onCheckboxSelected(id));
      });
  }

  ngOnDestroy(): void {
    this.checkBatchSubscription.unsubscribe();
    this.saveBatchSubscription.unsubscribe();
    this.creditsSubscription.unsubscribe();
    this.submitSubscription.unsubscribe();
    this.modalSubscription.unsubscribe();
  }

  public collapseCard(index: number, value: boolean): void {
    this.collapsedCard[index] = value;
  }

  public onCheckboxSelected(id: number): void {
    const groupedIds = [];
    this.bulkOrder.batch_label.shipments.forEach(element => {
      const rateId = element.rates.map(rate => rate.id.toString());
      const contains = rateId.includes(id.toString());
      if (contains) {
        groupedIds.push(...rateId);
      }
    });
    R.forEach(key => (this.ids[key] = false), groupedIds);
    this.ids[id] = true;
    this.calculatePrice();
  }

  public onPostponeClicked({ rates }: any, radioGroup): void {
    rates.forEach(({ id }) => {
      this.ids[id] = false;
    });
    this.collapseCard(+radioGroup, true);
    this.calculatePrice();
  }

  public onSelectClicked({ rates }: any, radioGroup): void {
    rates.forEach(({ id }, idx) => {
      this.ids[id] = !idx;
    });
    this.collapseCard(+radioGroup, false);
    this.calculatePrice();
  }

  private calculatePrice(): void {
    const ids = [];
    Object.keys(this.ids).forEach(key => {
      if (!this.ids[key]) {
        return;
      }
      ids.push(key);
    });
    this.total = R.pipe(
      R.pathOr([], ['batch_label', 'shipments']),
      R.map(R.propOr([], 'rates')),
      R.flatten,
      R.indexBy(R.prop('id')),
      R.pick(ids),
      R.values,
      R.map(
        R.pipe(
          R.pick(['amount_local', 'insurance_amount', 'out_of_area_pricing']),
          R.values,
        ),
      ),
      R.flatten,
      R.map(Number),
      R.sum,
    )(this.bulkOrder);
  }

  public onSave(): void {
    if (this.total > this.currentBalance) {
      this.snotifyService.error(
        this.translation.notifications.bulkInsufficientFunds,
      );
      return;
    }

    const shipmentsAmt = R.pathOr(
      0,
      ['batch_label', 'shipments', 'length'],
      this.bulkOrder,
    );

    const payload = {
      rate_id: [],
    };

    Object.keys(this.ids).forEach(key => {
      if (!this.ids[key]) {
        return;
      }
      payload.rate_id.push(Number(key));
    });

    if (!payload.rate_id.length) {
      return;
    } else if (payload.rate_id.length !== shipmentsAmt) {
      this.modalSubscription = this.confirmationModalService
        .cancelOrConfirm({
          title: this.translation.modalTitle,
          description: this.translation.modalDescription,
        })
        .subscribe(challengeValue => {
          if (!challengeValue) {
            this.modalSubscription.unsubscribe();
            this.confirmationModalService.close();
          } else {
            this.submit(payload);
          }
        });
    } else {
      this.submit(payload);
    }
  }

  public submit(payload: { rate_id: number[] }): void {
    this.submitSubscription.unsubscribe();
    this.submitSubscription = this.commonOrdersService
      .createBatchLabels(payload)
      .subscribe(() => {
        this.router.navigateByUrl('/orders/bulk-filled');
        if (this.modalSubscription) {
          this.confirmationModalService.close();
        }
      });
  }

  public goBack(): void {
    this.router.navigateByUrl('/orders');
  }
}
