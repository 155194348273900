export interface UpdatePasswordPayload {
  email?: string;
  password: string;
  confirmation_password: string;
}

export interface UpdateProfilePayload {
  email: string;
  contact_number: string;
  full_name: string;
  sap_id: string;
}
export type LabelFormat = 'pdf' | 'thermal' | 'thermal_dhl';

export interface UpdateLabelFormatPayload {
  label_format?: LabelFormat;
  // flabel_format_${carrier}
  [value: string]: LabelFormat;
}

export interface Profile {
  contact_email: string;
  contact_number: string;
  full_name: string;
  sap_id: string;
}
