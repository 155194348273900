export class NewOrderTranslation {
  page: {
    title: string;
  };
  steps: {
    address: string;
    package: string;
    price: string;
  };
  buttons: {
    createLabel: string;
    continue: string;
  };
  unitStrings: {
    distance: (e: string | number) => string;
    mass: (e: string | number) => string;
  };
  units: {
    distance: string;
    mass: string;
  };
  notifications: {
    success: (address: string) => string;
    error: (address: string) => string;
  };
  formNames: {
    origin: string;
    destination: string;
  };
}

const ES: NewOrderTranslation = {
  page: {
    title: 'Mis órdenes',
  },
  steps: {
    address: 'Dirección',
    package: 'Paquete',
    price: 'Tarifa',
  },
  buttons: {
    createLabel: 'Crear etiqueta de envío',
    continue: 'Guardar y continuar',
  },
  unitStrings: {
    distance: (e) => e + 'cm',
    mass: (e) => e + 'Kg',
  },
  units: {
    distance: 'cm',
    mass: 'Kg',
  },
  notifications: {
    success: (address) => `La ${address.toLowerCase()} se guardó con éxito.`,
    error: (address) => `La ${address.toLowerCase()} no pudo guardarse.`,
  },
  formNames: {
    origin: 'Dirección de origen',
    destination: 'Dirección de destino',
  },
};

const EN: NewOrderTranslation = {
  page: {
    title: 'My Orders',
  },
  steps: {
    address: 'Address',
    package: 'Package',
    price: 'Price',
  },
  buttons: {
    createLabel: 'Create shipment label',
    continue: 'Save and continue',
  },
  unitStrings: {
    distance: (e) => e + 'cm',
    mass: (e) => e + 'Kg',
  },
  units: {
    distance: 'cm',
    mass: 'Kg',
  },
  notifications: {
    success: (address) =>
      `The ${address.toLowerCase()} was successfully saved.`,
    error: (address) => `The ${address.toLowerCase()} couldn't be saved.`,
  },
  formNames: {
    origin: 'Origin address',
    destination: 'Destination address',
  },
};

export const NewOrderLanguages = {
  ES,
  EN,
};
