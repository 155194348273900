import { Component, Input, OnInit } from '@angular/core';
import { TutorialSlider } from '@commons/models/index';

@Component({
  selector: 'commons-tutorial-sliders',
  templateUrl: './tutorial-sliders.component.html',
  styleUrls: ['./tutorial-sliders.component.scss']
})
export class TutorialSlidersComponent implements OnInit {
  @Input() minSizeInPixels = '400px';
  @Input() elements: TutorialSlider[] = [];
  public selectedIndex = 0;

  constructor() {}

  ngOnInit(): void {}

  public onChevronAction(action: 'next' | 'back'): void {
    const maxIndex = this.elements.length - 1;
    const minIndex = 0;

    if (action === 'next' && this.selectedIndex < maxIndex) {
      this.selectedIndex++;
    }
    if (action === 'back' && this.selectedIndex > minIndex) {
      this.selectedIndex--;
    }
  }

  public onStepSelected(index: number): void {
    this.selectedIndex = index;
  }
}
