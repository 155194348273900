import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'commons-common-page',
  template: `
    <h2 class="font-weight-500 font-size-5 mb-2">
      <i
        class="cursor-pointer pr-4 fas fa-chevron-left"
        (click)="goBack()"
        *ngIf="canGoBack || goBackUrl"
      ></i>
      {{ title }}
    </h2>
    <h4 class="font-weight-500 font-size-4 mb-1" *ngIf="subtitle">
      {{ subtitle }}
    </h4>
    <h5 class="font-weight-300 font-size-1" *ngIf="description">
      {{ description }}
    </h5>
    <div class="mt-6">
      <ng-content></ng-content>
    </div>
  `,
})
export class CommonPageComponent {
  @Input() public title: string;
  @Input() public subtitle: string;
  @Input() public description: string;
  @Input() public canGoBack: boolean;
  @Input() public goBackUrl?: string;

  constructor(
    private readonly router: Router,
    private readonly location: Location,
  ) { }

  public goBack(): void {
    if (this.goBackUrl) {
      this.router.navigateByUrl(this.goBackUrl);
    } else {
      this.location.back();
    }
  }
}
