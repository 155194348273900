<div>
  <div class="flex mb-4">
    <h3>
      {{ translation.title }}
    </h3>
  </div>
  <mat-card>
    <mat-tab-group #tabGroup [(selectedIndex)]="selectedTabIndex">
      <mat-tab [label]="translation.tabs.data">
        <div class="py-4">
          <commons-client-profile-data [dataForm]="dataForm" [passwordForm]="passwordForm"
            (storeFormInfo)="onDataStored()">
          </commons-client-profile-data>
        </div>
      </mat-tab>
      <mat-tab [label]="translation.tabs.billing">
        <div class="py-4">
          <commons-client-profile-billing [billingForm]="billingForm" [data]="data"
            [automaticInvoiceForm]="automaticInvoiceForm" (storeFormInfo)="onBillingStored()">
          </commons-client-profile-billing>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card>
</div>