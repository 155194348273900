import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BulkOrder } from '@commons/models/orders.model';
import { Subscription } from 'rxjs';
import * as R from 'ramda';
import { CommonOrdersService } from '../common-orders.service';
import {
  CommonOrdersLanguages,
  CommonOrdersTranslation,
} from '../common-orders.translation';
import { CreditsService } from '@commons/modules/credits';

@Component({
  selector: 'commons-bulk-filled-order',
  templateUrl: './bulk-filled-order.component.html',
  styleUrls: ['./bulk-filled-order.component.scss'],
})
export class BulkFilledOrderComponent implements OnInit, OnDestroy {
  public readonly translation: CommonOrdersTranslation =
    CommonOrdersLanguages[this.language];

  public bulkOrder: BulkOrder;

  private checkBatchSubscription = Subscription.EMPTY;

  private saveBatchSubscription = Subscription.EMPTY;

  get shipments(): any {
    if (!this.bulkOrder) {
      return [];
    }
    return R.pipe(
      R.pathOr([], ['batch_label', 'shipments']),
      R.map(
        R.pipe(
          (shipment: { status: string; labels: any[] }) => {
            if (shipment.status === 'WAITING' && shipment.labels.length === 0) {
              shipment.labels.push({ status: 'WAITING' });
            }
            return shipment;
          },
          R.over(R.lensProp('labels'), this.getShipmentLabel),
          R.over(
            R.lensProp('order'),
            R.pipe(
              o =>
                R.assoc('contents', R.path(['address_to', 'contents'], o), o),
              R.over(
                R.lensProp('address_to'),
                ({ province, city, zip }) => `${province}, ${city}, ${zip}`,
              ),
              R.pick(['address_to', 'customer_fullname', 'contents']),
            ),
          ),
          o => R.assoc('id', { id: o.id }, o),
          R.pick(['labels', 'order', 'id']),
          ({
            id: { id },
            labels: { status, label_url },
            order: { address_to, contents, customer_fullname },
          }) => ({
            id,
            status,
            address_to,
            label_url,
            contents,
            customer_fullname,
          }),
        ),
      ),
    )(this.bulkOrder);
  }

  get overallLabel(): string {
    return R.pathOr(null, ['batch_label', 'label_file', 'url'], this.bulkOrder);
  }

  get overallError(): string {
    return R.pathOr(
      null,
      ['batch_label', 'failure_report_file_url', 'url'],
      this.bulkOrder,
    );
  }

  constructor(
    @Inject('LANGUAGE')
    public readonly language: keyof typeof CommonOrdersLanguages,
    private readonly router: Router,
    private readonly commonOrdersService: CommonOrdersService,
    private readonly creditsService: CreditsService,
  ) { }

  ngOnInit(): void {
    this.checkBatchSubscription = this.commonOrdersService
      .checkBatchCreation()
      .subscribe(bulkOrder => {
        const status = R.pathOr(null, ['batch_label', 'status'], bulkOrder);
        if (!R.includes(status.toUpperCase(), ['SUCCESS', 'WITH_ERRORS'])) {
          return;
        }
        this.bulkOrder = bulkOrder;
        // promote refresh of credits
        this.creditsService.creditsWereUpdated.next();
      });
  }

  ngOnDestroy(): void {
    this.checkBatchSubscription.unsubscribe();
    this.saveBatchSubscription.unsubscribe();
  }

  public getError(id: number): string {
    return R.pipe(
      R.pathOr([], ['batch_label', 'messages']),
      R.indexBy(R.prop('shipment_id')),
      R.prop(id),
      R.propOr('', 'message'),
    )(this.bulkOrder);
  }

  public getShipmentLabel(labels = []): { status: string; label_url: string } {
    return R.pipe(
      R.head,
      R.defaultTo({ status: 'ERROR' }),
      R.pick(['status', 'label_url']),
    )(labels);
  }

  public goBack(): void {
    this.router.navigateByUrl('/orders');
  }
}
