import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@commons/modules/auth/auth.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

/**
 * This Interceptor is the second handler in the interception barrel
 *
 * This handles API specifications, such as headers or parsers.
 */
@Injectable({
  providedIn: 'root',
})
export class RequestHandlerInterceptor implements HttpInterceptor {
  private readonly ZENDESK_TOKEN = 'IM1WVskt9S7QPEGkAv8lKESGVWRfA0ZHfVL1Y2ul';

  constructor(private readonly authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return this.appendAuthHeaderToRequest(next, request).pipe(
      map((event) => {
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        return throwError(error);
      }),
    );
  }

  private appendAuthHeaderToRequest(
    next: HttpHandler,
    request: HttpRequest<any>,
  ): Observable<HttpEvent<any>> {
    if (request.headers.has('authorization')) {
      return next.handle(request);
    }

    const { apiKey } = this.authService;
    if (apiKey) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${apiKey}`,
        },
      });
    }

    return next.handle(request);
  }
}
