import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { TutorialSlider } from '@commons/models/index';
import { Subscription } from 'rxjs';
import { CommonOrdersService } from '../common-orders.service';
import {
  CommonOrdersLanguages,
  CommonOrdersTranslation,
} from '../common-orders.translation';

@Component({
  selector: 'commons-new-bulk-order',
  templateUrl: './new-bulk-order.component.html',
  styleUrls: ['./new-bulk-order.component.scss'],
})
export class NewBulkOrderComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput') fileInput: ElementRef;

  public readonly translation: CommonOrdersTranslation =
    CommonOrdersLanguages[this.language];

  public readonly elements: TutorialSlider[] = [
    {
      text: this.translation.bulk.items[0],
      url: 'assets/images/guias_icon_descargar.svg',
    },
    {
      text: this.translation.bulk.items[1],
      url: 'assets/images/guias_icon_excel.svg',
    },
    {
      text: this.translation.bulk.items[2],
      url: 'assets/images/guias_icon_clic.svg',
    },
    {
      text: this.translation.bulk.items[3],
      url: 'assets/images/guias_img_paqueterias.png',
    },
    {
      text: this.translation.bulk.items[4],
      url: 'assets/images/guias_icon_descarga.svg',
    },
  ];

  private subscription = Subscription.EMPTY;

  constructor(
    @Inject('LANGUAGE')
    public readonly language: keyof typeof CommonOrdersLanguages,
    private readonly router: Router,
    private readonly commonOrdersService: CommonOrdersService,
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public goBack(): void {
    this.router.navigateByUrl('/orders');
  }

  public onFileSelection(): void {
    this.fileInput.nativeElement.click();
  }

  public onImageChanged(event: Event): void {
    const files = (event.target as any).files as FileList;

    if (!files.length) {
      return;
    }

    const form = new FormData();
    form.append('file_url', files[0]);

    form.forEach(element => {
      console.log(element);
      
    });

    this.subscription = this.commonOrdersService
      .createBatch(form)
      .subscribe((response) => {
        this.router.navigateByUrl('/orders/bulk-prefill');
        console.log(response);
        
      });
  }
}
