import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonIntegrationsComponent } from './common-integrations.component';

const routes: Routes = [
  { path: '', redirectTo: 'stores' },
  {
    path: '',
    component: CommonIntegrationsComponent,
    children: [
      {
        path: 'stores',
        loadChildren: () =>
          import('./store/store.module').then((m) => m.StoreModule),
      },
      {
        path: 'add/url',
        loadChildren: () =>
          import('./additional-data/additional-data.module').then(
            (m) => m.AdditionalDataModule,
          ),
      },
      {
        path: 'add/finish',
        loadChildren: () =>
          import('./finish/finish.module').then((m) => m.FinishModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CommonIntegrationsRoutingModule {}
