import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CustomErrorStateMatcher, RegexEmail } from '@commons/helpers/index';
import { ConfirmationModalService } from '@commons/modules/modals';
import { first, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  AuthRecoverPasswordTranslation,
  AuthRecoverPasswordLanguages,
} from './auth-recover-password.translation';
import { AuthService } from '../auth.service';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'commons-auth-recover-password',
  templateUrl: './auth-recover-password.component.html',
  styleUrls: ['./auth-recover-password.component.scss'],
})
export class AuthRecoverPasswordComponent implements OnInit, OnDestroy {
  /**
   *
   */
  public matcher = new CustomErrorStateMatcher();

  /**
   *
   */
  public form: FormGroup;

  /**
   * This gets emitted when the component gets destroyed and closes subscriptions
   */
  private destroyed$ = new Subject<void>();

  /**
   * Object for the english/spanish translations
   */
  public translation: AuthRecoverPasswordTranslation =
    AuthRecoverPasswordLanguages[this.language];

  public disableButton: boolean;

  constructor(
    private readonly router: Router,
    private readonly fb: FormBuilder,
    private readonly authService: AuthService,
    private readonly confirmationModalService: ConfirmationModalService,
    @Inject('LANGUAGE')
    private readonly language: keyof typeof AuthRecoverPasswordLanguages,
    private readonly snotifyService: SnotifyService,
  ) {}

  ngOnInit(): void {
    this.buildForm();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  /**
   *
   */
  public buildForm(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(RegexEmail)]],
      redirect_url: [
        `${window.location.origin}/auth/reset-password`,
        [Validators.required],
      ],
    });
  }

  /**
   *
   */
  public onSubmit(e: Event): void {
    e.stopPropagation();
    e.preventDefault();
    if (!this.form.valid) {
      return;
    }
    this.createNoticeModal();
  }

  /**
   *
   */
  private goToLogin(): void {
    this.router.navigateByUrl('/auth/login');
  }

  private createNoticeModal(): void {
    this.disableButton = true;
    this.authService.recoverPassword(this.form.value).subscribe(
      () => {
        this.disableButton = false;

        this.confirmationModalService
          .understandModal(AuthRecoverPasswordLanguages[this.language].modal)
          .pipe(takeUntil(this.destroyed$), first())
          .subscribe(() => {
            this.confirmationModalService.close();
            this.goToLogin();
          });
      },
      ({ error }) => {
        this.disableButton = false;
        // if (error.errors && typeof error.errors.length !== undefined) {
        //   error.errors.forEach((err) => this.snotifyService.error(err));
        // }
        if (error.errors['email o password']) {
          this.snotifyService.error('Correo electrónico no encontrado');
        }
      },
    );
  }
}
