import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AccountDetails, APIRoutes } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AccountDetailsService {
  private readonly routes = new APIRoutes();

  constructor(private readonly http: HttpClient) {}

  public get(): Observable<AccountDetails> {
    return this.http.get<AccountDetails>(this.routes.AccountDetails.All);
  }
}
