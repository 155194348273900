import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { OverlayService } from './overlay.service';

@Component({
  selector: 'commons-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss']
})
export class OverlayComponent implements OnInit, OnDestroy {
  @Input() type: 'dark' | 'light' | 'default' = 'default';

  public loadingStatus: boolean;

  private suscriptionLoading = Subscription.EMPTY;

  constructor(private readonly overlayService: OverlayService) {}

  ngOnInit(): void {
    this.overlayService.loadingStatus$.subscribe(
      value => (this.loadingStatus = value)
    );
  }

  ngOnDestroy(): void {
    this.suscriptionLoading.unsubscribe();
  }
}
