import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ZipResponse } from '@commons/models/locations.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { APIRoutes } from '../models';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private readonly routes = new APIRoutes();

  constructor(private readonly http: HttpClient) {}

  public getCountries(): Observable<string[][]> {
    return this.http.get<string[][]>(this.routes.Addresses.Custom('countries'));
  }

  public getStates(country: string): Observable<string[][]> {
    return this.http
      .get<string[][]>(this.routes.Addresses.Custom('states'))
      .pipe(map(response => response[country] || []));
  }

  public getPostalCodes(zip: string | number): Observable<ZipResponse> {
    return this.http.get<ZipResponse>(
      this.routes.PostalCodes.Custom(zip.toString()),
    );
  }
}
