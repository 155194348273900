import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonIntegrationsComponent } from './common-integrations.component';
import { CommonPageModule } from '../common-page/common-page.module';
import { SlidersModule } from '@commons/modules/sliders';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CommonIntegrationsRoutingModule } from './common-integrations-routing.module';

@NgModule({
  declarations: [CommonIntegrationsComponent],
  imports: [
    CommonModule,
    CommonPageModule,
    RouterModule,
    SlidersModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatCardModule,
    MatCheckboxModule,
    CommonIntegrationsRoutingModule,
  ],
  exports: [CommonIntegrationsComponent],
})
export class CommonIntegrationsModule {}
