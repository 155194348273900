<div class="flex flex-column align-items-center" *ngIf="!bulkOrder">
  <div class="border-radius-2 p-4 bg-yellow color-white mb-8 text-center w-100">
    {{ translation.filledLoading }}
  </div>
  <div class="loader overflow-hidden">{{ translation.loading }}</div>
  <h4 class="color-yellow">{{ translation.loading }}</h4>
</div>
<div *ngIf="bulkOrder" class="flex flex-column">
  <h3 class="flex align-items-center mb-4">
    <div>{{ translation.prefillBulk.shipmentTitle }}</div>
  </h3>
  <div class="mb-8">
    {{ translation.prefillBulk.shipmentDescriptionSuccess }}
  </div>
  <mat-card>
    <div class="card-header flex justify-content-end">
      <div class="flex-fill"></div>
      <a
        class="btn success flex"
        *ngIf="overallLabel"
        [href]="overallLabel"
        target="_blank"
      >
        <i class="fas fa-download mr-2"></i>
        {{ translation.filledBulk.downloadAll }}
      </a>
      <a class="btn success flex" *ngIf="!overallLabel" [href]="overallError">
        {{ translation.filledBulk.downloadErrorAll }}
      </a>
    </div>
    <div class="flex flex-column p-4">
      <div
        class="flex align-items-center py-2"
        *ngFor="let shipment of shipments || []"
      >
        <div class="flex flex-fill align-items-center">
          <div class="font-weight-700 mr-2">
            {{ translation.filledBulk.target }}:
          </div>
          <div>{{ shipment.address_to }}</div>
          <div class="p-2">|</div>
          <div class="font-weight-700 mr-2">
            {{ translation.filledBulk.name }}:
          </div>
          <div>{{ shipment.customer_fullname }}</div>
          <div class="p-2">|</div>
          <div class="font-weight-700 mr-2">
            {{ translation.prefillBulk.content }}:
          </div>
          <div>
            {{ shipment.contents }}
          </div>
        </div>

        <a
          class="btn success mr-4 flex"
          *ngIf="shipment.label_url"
          [href]="shipment.label_url"
          target="_blank"
        >
          <i class="fas fa-download mr-2"></i>
          {{ translation.filledBulk.download }}
        </a>
        <a
          class="btn error mr-4"
          *ngIf="!shipment.label_url && shipment.status === 'ERROR'"
        >
          {{ translation.downloadErrors[getError(shipment.id)] ?
            translation.downloadErrors[getError(shipment.id)] : translation.downloadErrors.system_error }}
        </a>
        <a class="btn warn mr-4" *ngIf="shipment.status === 'WAITING'">
          {{ translation.filledBulk.pending }}
        </a>
      </div>
    </div>
  </mat-card>
  <div class="flex justify-content-center pt-8">
    <button mat-button class="btn primary" (click)="goBack()">
      {{ translation.back }}
    </button>
  </div>
</div>
