export class Translation {
  buttons: {
    signIn: string;
  };
  errors: {
    emailValid: string;
    emailRequired: string;
    password: string;
    passwordRequired: string;
    passwordPattern: string;
    telephoneRequired: string;
    telephoneValid: string;
    codeRequired: string;
    codeValid: string;
  };
  labels: {
    email: string;
    password: string;
    telephone: string;
    code: string;
  };
  links: {
    recoverPassword: string;
    signUp: string;
    resendCode: string;
  };
  notifications: {
    invalidEmailOrPassword: string;
    genericError: string;
  };
  title: string;
  noApprovalModal: {
    title: string;
    description: string;
  };
  resendCode: {
    title: string;
    description: string;
  };
}

const ES: Translation = {
  buttons: {
    signIn: `Validar Código`,
  },
  errors: {
    emailValid: `Por favor introduzca una dirección de Correo electrónico válida.`,
    emailRequired: `El campo Correo electrónico es requerido.`,
    password: `El campo Nueva contraseña debe poseer de 8 a 24 caracteres, al menos 1 Mayuscula, 1 Número y 1 Simbolo.`,
    passwordRequired: `El campo Contraseña es requerido.`,
    passwordPattern: `El campo contraseña debe poseer de 8 a 24 caracteres, al menos 1
        Mayuscula, 1 Número y 1 Simbolo.`,
    telephoneRequired: `El campo Número de teléfono es requerido.`,
    telephoneValid: `Por favor introduzca una número de teléfono válido.`,
    codeRequired: `El campo Código de verificación es requerido.`,
    codeValid: `El código de verificación es inválido.`,
  },
  labels: {
    email: `Correo electrónico`,
    password: `Contraseña`,
    telephone: `Número de teléfono`,
    code: `Código de verificación`
  },
  links: {
    recoverPassword: `¿Olvidaste tu contraseña?`,
    signUp: `Regístrate`,
    resendCode: `Reenviar código`,
  },
  title: `Verificar código`,
  notifications: {
    invalidEmailOrPassword:
      'El correo electrónico o contraseña son incorrectos. Intenta de nuevo.',
    genericError: 'Ocurrió un error. Intenta de nuevo.',
  },
  noApprovalModal: {
    title: 'Registro exitoso',
    description:
      'Gracias por crear tu cuenta en Speedit.<br><br>Tu cuenta ha sido creada.',
  },
  resendCode: {
    title: 'Código reenviado',
    description:
      'Se ha enviado un nuevo código.',
  },
};

const EN: Translation = {
  buttons: {
    signIn: `Sign In`,
  },
  errors: {
    emailValid: `Please insert a valid Email address.`,
    emailRequired: `Email field is required.`,
    password: `New password field must have 8 to 24 characters, at least 1 uppercase letter, 1 number and 1 special character.`,
    passwordRequired: `Password field is required.`,
    passwordPattern: `password field must present 8 to 24 characters, at least 1
        Uppercase, 1 Number and 1 Symbol.`,
        telephoneValid: `Por favor introduzca una número de teléfono válido.`,
    telephoneRequired: `El campo Número de teléfono es requerido.`,
    codeRequired: `El campo Código de verificación.`,
    codeValid: `El código de verificación es inválido.`,
  },
  labels: {
    email: `Email`,
    password: `Password`,
    telephone: `Phone number`,
    code: `Verification code`
  },
  links: {
    recoverPassword: `Forgot your password?`,
    signUp: `Sign Up`,
    resendCode: `Resend code`
  },
  title: `Welcome`,
  notifications: {
    invalidEmailOrPassword:
      'The e-mail or password are incorrect. Please try again.',
    genericError: 'An error ocurred. Please try again.',
  },
  noApprovalModal: {
    title: 'Successful Registration',
    description:
      'Thank you for creating your account with Speedit.<br><br>Your account has been created.',
  },
  resendCode: {
    title: 'Código reenviado',
    description:
      'Se ha enviado un nuevo código.',
  },
};

export const LANG = {
  ES,
  EN,
};
