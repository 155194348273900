<div
  class="header flex flex-row full-width justify-content-end align-items-center d-print-none"
>
  <ng-container *ngIf="showCredit">
    <button mat-button color="primary" class="btn primary credit" (click)="openCreditModal()">
      + {{ translation.buttons.addCredit }}
    </button>
    <p class="ml-6 font-weight-500">
      {{ credits | async | currency }} {{ translation.currency }}
    </p>
    <div class="separator"></div>
  </ng-container>
  <div class="account flex flex-row">
    <div class="text mr-4">
      <div class="greeting">{{ translation.greeting }}</div>
      <div class="name font-weight-700">{{ user?.full_name }}</div>
    </div>
    <div
      class="dropdown-container"
      commonsClickOutside
      (clickOutside)="onClickOutside()"
    >
      <button mat-button (click)="isAccountOpen = !isAccountOpen">
        <i class="fas fa-user mr-4"></i>
        <i class="fas fa-chevron-down"></i>
      </button>
      <div class="dropdown dropdown-right" *ngIf="isAccountOpen">
        <button mat-button (click)="goToProfile()">
          <i class="fas fa-user mr-4"></i>
          {{ translation.buttons.profile }}
        </button>
        <button mat-button (click)="logout()">
          <i class="fas fa-sign-out-alt mr-4"></i>
          {{ translation.buttons.logout }}
        </button>
      </div>
    </div>
  </div>
</div>
<commons-credits
  *ngIf="showCreditModal && showCredit"
  (closeModal)="showCreditModal = false"
></commons-credits>
