<div>
  <div class="card-header">
    <div class="font-weight-700">{{ translation.form.billing.title }}</div>
  </div>
  <div class="card-body">
    <small class="color-red">{{ translation.labels.required }}</small>
    <form [formGroup]="billingForm" *ngIf="billingForm" class="mt-6 g" (ngSubmit)="onSubmit()">
      <mat-form-field class="mb-4 px-4 g-6">
        <mat-label>
          {{ translation.labels.billing.rfc }}
        </mat-label>
        <input matInput required [errorStateMatcher]="matcher" minlength="12" maxlength="13" formControlName="tax_id" />
        <mat-error class="mb-2" *ngIf="
            billingForm.controls.tax_id.errors &&
            billingForm.controls.tax_id.errors.required
          ">
          {{ translation.errors.billing.rfcRequired }}
        </mat-error>
        <mat-error class="mb-2" *ngIf="
            billingForm.controls.tax_id.errors &&
            (billingForm.controls.tax_id.errors.minlength ||
              billingForm.controls.tax_id.errors.maxlength)
          ">
          {{ translation.errors.billing.rfcLength }}
        </mat-error>
        <mat-error class="mb-2" *ngIf="
            billingForm.controls.tax_id.errors &&
            (billingForm.controls.tax_id.errors.pattern ||
              billingForm.controls.tax_id.errors.pattern)
          ">
          {{ translation.errors.billing.rfcPattern }}
        </mat-error>
      </mat-form-field>

      <div class="g-3 pt-4 pl-4 mb-4 justify-content-end">
        <mat-slide-toggle [checked]="automaticInvoiceForm.value.automaticInvoice"
          (change)="onAutomaticBillingChanged($event)">
          <div class="flex align-items-center">
            {{ translation.labels.billing.automatic }}
          </div>
        </mat-slide-toggle>
      </div>

      <mat-form-field class="mb-4 px-4 g-8">
        <mat-label>
          {{ translation.labels.billing.name }}
        </mat-label>
        <input matInput required [errorStateMatcher]="matcher" formControlName="business_name" maxlength="50" />
        <mat-error class="mb-2" *ngIf="
            billingForm.controls.business_name.errors &&
            billingForm.controls.business_name.errors.required
          ">
          {{ translation.errors.billing.nameRequired }}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="mb-4 px-4 g-6">
        <mat-label>
          {{ translation.labels.billing.tax_regime }}
        </mat-label>
        <mat-select matInput required [errorStateMatcher]="matcher" formControlName="tax_regime">
          <mat-option *ngFor="let tax_regime of translation.tax_regime" [value]="tax_regime.value">
            {{ tax_regime.text }}
          </mat-option>
        </mat-select>
        <mat-error class="mb-2" *ngIf="
            billingForm.controls.tax_regime.errors &&
            billingForm.controls.tax_regime.errors.required
          ">
          {{ translation.errors.billing.tax_regime }}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="mb-4 px-4 g-6">
        <mat-label>
          {{ translation.labels.billing.paymentMethod }}
        </mat-label>
        <mat-select matInput required [errorStateMatcher]="matcher" formControlName="payment_way">
          <mat-option *ngFor="let method of translation.paymentMethods" [value]="method.value">
            {{ method.text }}
          </mat-option>
        </mat-select>
        <mat-error class="mb-2" *ngIf="
            billingForm.controls.payment_way.errors &&
            billingForm.controls.payment_way.errors.required
          ">
          {{ translation.errors.billing.paymentMethodRequired }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="px-3 g-6">
        <mat-label>{{translation.labels.billing.street1}}</mat-label>
        <input matInput formControlName="street1" required [errorStateMatcher]="matcher" name="street1" maxlength="50">
        <mat-error class="mb-2" *ngIf="
        billingForm.controls.street1.errors &&
        billingForm.controls.street1.errors.required
      ">
          {{ translation.errors.billing.street1 }}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="mb-4 px-4 g-6">
        <mat-label>
          {{ translation.labels.billing.street2 }}
        </mat-label>
        <input matInput required [errorStateMatcher]="matcher" formControlName="street2" maxlength="30" />
        <mat-error class="mb-2" *ngIf="
            billingForm.controls.street2.errors &&
            billingForm.controls.street2.errors.required
          ">
          {{ translation.errors.billing.street2 }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="mb-4 px-4 g-6">
        <mat-label>
          {{ translation.labels.billing.zip }}
        </mat-label>
        <input matInput required [errorStateMatcher]="matcher" formControlName="zip" maxlength="5" />
        <mat-error class="mb-2" *ngIf="
            billingForm.controls.zip.errors &&
            billingForm.controls.zip.errors.required
          ">
          {{ translation.errors.billing.zip }}
        </mat-error>
        <mat-error class="mb-2" *ngIf="
            billingForm.controls.zip.errors &&
            billingForm.controls.zip.errors.minlength
          ">
          {{ translation.errors.billing.zipminlength }}
        </mat-error>
        <mat-error class="mb-2" *ngIf="
            billingForm.controls.zip.errors &&
            billingForm.controls.zip.errors.pattern
          ">
          {{ translation.errors.billing.zipPattern }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="mb-4 px-4 g-6">
        <mat-label>
          {{ translation.labels.billing.city }}
        </mat-label>
        <input matInput required [errorStateMatcher]="matcher" formControlName="city" maxlength="30" />
        <mat-error class="mb-2" *ngIf="
            billingForm.controls.city.errors &&
            billingForm.controls.city.errors.required
          ">
          {{ translation.errors.billing.city }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="mb-4 px-4 g-6">
        <mat-label>
          {{ translation.labels.billing.province }}
        </mat-label>
        <input matInput required [errorStateMatcher]="matcher" formControlName="province" maxlength="30" />
        <mat-error class="mb-2" *ngIf="
            billingForm.controls.province.errors &&
            billingForm.controls.province.errors.required
          ">
          {{ translation.errors.billing.province }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="mb-4 px-4 g-6">
        <mat-label>
          {{ translation.labels.billing.country }}
        </mat-label>
        <mat-select matInput required [errorStateMatcher]="matcher" formControlName="country">
          <mat-option *ngFor="let country of translation.country" [value]="country.value">
            {{ country.text }}
          </mat-option>
        </mat-select>
        <mat-error class="mb-2" *ngIf="
            billingForm.controls.country.errors &&
            billingForm.controls.country.errors.required
          ">
          {{ translation.errors.billing.country }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="mb-4 px-4 g-6">
        <mat-label>
          {{ translation.labels.billing.phone }}
        </mat-label>
        <input matInput required [errorStateMatcher]="matcher" formControlName="phone" maxlength="10" />
        <mat-error class="mb-2" *ngIf="
            billingForm.controls.phone.errors &&
            billingForm.controls.phone.errors.required
          ">
          {{ translation.errors.billing.phone }}
        </mat-error>
        <mat-error class="mb-2" *ngIf="
        billingForm.controls.phone.errors &&
        billingForm.controls.phone.errors.minlength
      ">
          {{ translation.errors.billing.phoneMax }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="mb-4 px-4 g-6">
        <mat-label>
          {{ translation.labels.billing.email }}
        </mat-label>
        <input matInput required [errorStateMatcher]="matcher" formControlName="email" maxlength="80" />
        <mat-error class="mb-2" *ngIf="
            billingForm.controls.email.errors &&
            billingForm.controls.email.errors.required
          ">
          {{ translation.errors.billing.email }}
        </mat-error>
        <mat-error class="mb-2" *ngIf="
        billingForm.controls.email.errors &&
        billingForm.controls.email.errors.email
      ">
          {{ translation.errors.billing.emailError }}
        </mat-error>
      </mat-form-field>
      <div class="pr-4">
        <button mat-raised-button color="primary" class="width-full btn primary" type="submit"
          [disabled]="billingForm.valid ? null : true">
          {{ translation.buttons.save }}
        </button>
      </div>
    </form>
  </div>
</div>