export class AuthResetPasswordTranslation {
  buttons: {
    reset: string;
  };
  errors: {
    password: string;
    passwordMatch: string;
    passwordRequired: string;
  };
  labels: {
    newPassword: string;
    confirmPassword: string;
  };
  links: {
    login: string;
  };
  title: string;
}

const ES: AuthResetPasswordTranslation = {
  buttons: {
    reset: `Enviar`,
  },
  errors: {
    password: `El campo nueva contraseña debe poseer de 8 a 24 caracteres, al menos 1 mayúscula, 1 número y 1 símbolo.`,
    passwordMatch: `Las contraseñas no coinciden.`,
    passwordRequired: 'Este campo es requerido.',
  },
  labels: {
    newPassword: `Nueva contraseña`,
    confirmPassword: `Confirmar contraseña`,
  },
  links: {
    login: `Iniciar Sesión`,
  },
  title: `Reiniciar Contraseña`,
};

const EN: AuthResetPasswordTranslation = {
  buttons: {
    reset: `Confirm`,
  },
  errors: {
    password: `New password field must have 8 to 24 characters, at least 1 uppercase letter, 1 number and 1 special character.`,
    passwordMatch: `Password unmatch.`,
    passwordRequired: 'This field is required.',
  },
  labels: {
    newPassword: `New password`,
    confirmPassword: `Confirm password`,
  },
  links: {
    login: `Sign In`,
  },
  title: `Reset Password`,
};

export const AuthResetPasswordLanguages = {
  ES,
  EN,
};
