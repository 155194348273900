export interface CreditsTranslation {
  modalTitle: string;
  paymentMethod: string;
  payWith: string;
  minimumPayment: string;
  bankDepositButton: string;
  bankDeposit: {
    bankInformation: {
      title: string;
      fields: { fieldName: string; value: string }[];
    };
    paymentProof: {
      title: string;
      fields: { fieldName: string; value: string }[];
      notices: string[];
    };
    printButton: string;
    dontPrintButton: string;
  };
  errors: {
    amountRequired: string;
  };
  print: string;
}

const ES: CreditsTranslation = {
  modalTitle: 'Agregar saldo',
  paymentMethod: 'Método de pago',
  payWith: 'Pagar con:',
  minimumPayment: 'El mínimo de recarga es',
  bankDepositButton: 'Depósito',
  bankDeposit: {
    bankInformation: {
      title: 'Haz tu pago en:',
      fields: [
        { fieldName: 'Banco', value: 'BanBajio' },
        { fieldName: 'CLABE', value: '030320900033146325' },
        { fieldName: 'Beneficiario', value: 'GRUPO REFLOR SA de CV' },
      ],
    },
    paymentProof: {
      title:
        'Envía el comprobante del depósito realizado por WhatsApp al siguiente número:',
      fields: [
        // { fieldName: 'Email', value: '' },
        // { fieldName: 'Asunto', value: '' },
        { fieldName: 'Teléfono', value: '33 2822 3003' },
      ],
      notices: [
        'Al enviar la imagen, menciona el correo electrónico y el nombre con los que estás registrado. Se realizará la carga en las siguientes 24 horas.',
      ],
    },
    printButton: 'Imprimir',
    dontPrintButton: 'Continuar sin imprimir',
  },
  errors: {
    amountRequired: 'Este campo es requerido.',
  },
  print: 'Imprimir',
};

const EN: CreditsTranslation = {
  modalTitle: 'Add credit',
  paymentMethod: 'Payment Method',
  payWith: 'Pay with:',
  minimumPayment: 'The minimum amount is',
  bankDepositButton: 'Bank Deposit',
  bankDeposit: {
    bankInformation: {
      title: 'Make your payment in',
      fields: [
        { fieldName: 'Bank', value: '' },
        { fieldName: 'Account', value: '' },
        { fieldName: 'Beneficiary', value: '' },
        { fieldName: 'RFC', value: '' },
      ],
    },
    paymentProof: {
      title: 'Send us a payment proof',
      fields: [
        { fieldName: 'To', value: '' },
        { fieldName: 'Subject', value: '' },
      ],
      notices: [
        'Include your Speedit account e-mail',
        'Include a picture of your payment proof',
      ],
    },
    printButton: 'Print',
    dontPrintButton: 'Continue without printing',
  },
  errors: {
    amountRequired: 'This field is required.',
  },
  print: 'Print',
};

export const CreditsLanguages = {
  EN,
  ES,
};
