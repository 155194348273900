import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '@commons/modules/pipes';
import { LayoutMenuComponent } from './layout-menu.component';
import { LayoutMenuItemComponent } from './layout-menu-item/layout-menu-item.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [LayoutMenuComponent, LayoutMenuItemComponent],
  imports: [CommonModule, PipesModule, RouterModule],
  exports: [LayoutMenuComponent, LayoutMenuItemComponent]
})
export class LayoutMenuModule {}
