import { Inject, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ConfirmationModal } from './confirmation-modal.component';
import { ConfirmationModalLanguages } from './confirmation-modal.translation';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationModalService {
  public readonly createModal = new Subject<ConfirmationModal>();
  private readonly response = new Subject<boolean>();

  constructor(
    @Inject('LANGUAGE')
    private readonly language: keyof typeof ConfirmationModalLanguages,
  ) {}

  public defaultModal(): Observable<boolean> {
    this.createModal.next(DefaultConfirmationModal[this.language]);
    return this.response.asObservable();
  }

  public understandModal({
    title,
    description,
  }: Partial<ConfirmationModal>): Observable<boolean> {
    this.createModal.next({
      title,
      description,
      okMessage: ConfirmationModalLanguages[this.language].understand,
    });
    return this.response.asObservable();
  }

  public confirmOnly({
    title,
    description,
  }: Partial<ConfirmationModal>): Observable<boolean> {
    this.createModal.next({
      title,
      description,
      okMessage: ConfirmationModalLanguages[this.language].okMessage,
    });
    return this.response.asObservable();
  }

  public cancelOnly({
    title,
    description,
  }: Partial<ConfirmationModal>): Observable<boolean> {
    this.createModal.next({
      title,
      description,
      cancelMessage: ConfirmationModalLanguages[this.language].cancelMessage,
    });
    return this.response.asObservable();
  }

  public cancelOrConfirm({
    title,
    description,
    showCancelButton,
  }: Partial<ConfirmationModal>): Observable<boolean> {
    this.createModal.next({
      ...DefaultConfirmationModal[this.language],
      title,
      description,
      showCancelButton,
    });
    return this.response.asObservable();
  }

  public customModal(
    confirmationModal: ConfirmationModal,
  ): Observable<boolean> {
    this.createModal.next(confirmationModal);
    return this.response.asObservable();
  }

  public setResponse(isOk: boolean): void {
    this.response.next(isOk);
  }

  public close(): void {
    this.response.next(false);
    this.createModal.next(null);
  }
}

const DefaultConfirmationModal: {
  [key: string]: ConfirmationModal;
} = {
  ES: {
    title: ConfirmationModalLanguages.ES.title,
    description: ConfirmationModalLanguages.ES.description,
    okMessage: ConfirmationModalLanguages.ES.okMessage,
    cancelMessage: ConfirmationModalLanguages.ES.cancelMessage,
  },
  EN: {
    title: ConfirmationModalLanguages.EN.title,
    description: ConfirmationModalLanguages.EN.description,
    okMessage: ConfirmationModalLanguages.EN.okMessage,
    cancelMessage: ConfirmationModalLanguages.EN.cancelMessage,
  },
};
