<commons-modal-with-content
  [title]="translation.modalTitle"
  [wide]="true"
  (backgroundClick)="closeModal.next()"
  (closeButtonClick)="closeModal.next()"
  [showCloseButton]="true"
>
  <div
    class="modal-content"
    [ngClass]="{ 'modal-content--wide': paymentMethod === 'bankDeposit' }"
  >
    <span class="d-print-none">{{ translation.paymentMethod }}</span>
    <div class="buttons-container d-print-none">
      <button
        mat-raised-button
        (click)="paymentMethod = 'paypal'"
        class="paypal-button color-white"
        [ngClass]="{ active: paymentMethod === 'paypal' }"
      >
        <img src="/assets/images/PayPal.svg" />
      </button>
      <button
        mat-raised-button
        (click)="paymentMethod = 'bankDeposit'"
        class="bank-button color-lighter-black"
        [ngClass]="{ active: paymentMethod === 'bankDeposit' }"
      >
        <i class="fas fa-landmark mr-2"></i> {{ translation.bankDepositButton }}
      </button>
      <button
        mat-raised-button
        (click)="paymentMethod = 'mercadoPago'"
        class="color-white"
        [ngClass]="{ active: paymentMethod === 'mercadoPago' }"
      >
        <img src="/assets/images/mercado-pago.png" />
      </button>
      <a [href]="billpocketUrl" target="=_blank">
        <button
          mat-raised-button
          class="color-white"
          [ngClass]="{ active: paymentMethod === 'billpocket' }"
        >
          <img src="/assets/images/billpocket.png" />
        </button>
      </a>
    </div>

    <div class="separator d-print-none" *ngIf="paymentMethod"></div>

    <div
      *ngIf="paymentMethod === 'bankDeposit'"
      class="bank-deposit-information"
    >
      <div
        *ngIf="translation.bankDeposit.bankInformation as copies"
        class="section"
      >
        <img
        class="auth-logo d-print-only mb-4"
        height="70"
        src="assets/images/logo-blue.png"
        alt="logo"
      />
        <div class="title">
          {{ copies.title }}
        </div>
        <div *ngFor="let field of copies.fields" class="field">
          <span class="name"> {{ field.fieldName }}: </span>
          <span class="value">{{ field.value }}</span>
        </div>
      </div>

      <div
        *ngIf="translation.bankDeposit.paymentProof as copies"
        class="section"
      >
        <div class="title">
          {{ copies.title }}
        </div>
        <div *ngFor="let field of copies.fields" class="field">
          <span class="name"> {{ field.fieldName }}: </span>
          <span class="value">{{ field.value }}</span>
        </div>

        <div class="notices">
          <p *ngFor="let notice of copies.notices">
            <span class="color-red">*</span>{{ notice }}
          </p>
        </div>
        <div class="d-print-none">
          <button mat-raised-button
          color="primary"
          class="btn primary" role="button" (click)="print()">{{ translation.print }}</button>
        </div>
      </div>
    </div>

    <div *ngIf="paymentMethod === 'paypal'" class="width-full">
      <p>{{ translation.minimumPayment }} $500.00</p>
      <form [formGroup]="form" class="mt-4">
        <mat-form-field class="width-full">
          <mat-label> Monto </mat-label>
          <span matPrefix>$&nbsp;</span>
          <input
            matInput
            required
            formControlName="amount"
            name="amount"
            type="number"
          />
        </mat-form-field>
        <mat-error
          class="mb-2 custom-error-size"
          *ngIf="
            form.controls.amount.errors && form.controls.amount.errors.min
          "
        >
          {{ translation.minimumPayment }} $500.00
        </mat-error>

        <mat-error
          class="mb-2 custom-error-size"
          *ngIf="
            form.controls.amount.errors && form.controls.amount.errors.required
          "
        >{{ translation.errors.amountRequired }}</mat-error>

        <div class="flex align-items-center">
          <div class="mr-2">Pagar con:</div>
          <ngx-paypal
            [config]="payPalConfig"
            [class.disabled-paypal]="form.invalid"
          ></ngx-paypal>
        </div>
      </form>
    </div>

    <div *ngIf="paymentMethod === 'mercadoPago'" class="width-full">
      <p>{{ translation.minimumPayment }} $500.00</p>
      <form [formGroup]="form" class="mt-4">
        <mat-form-field class="width-full">
          <mat-label> Monto </mat-label>
          <span matPrefix>$&nbsp;</span>
          <input
            matInput
            required
            formControlName="amount"
            name="amount"
            type="number"
          />
        </mat-form-field>
        <mat-error
          class="mb-2 custom-error-size"
          *ngIf="
            form.controls.amount.errors && form.controls.amount.errors.min
          "
        >
          {{ translation.minimumPayment }} $500.00
        </mat-error>

        <mat-error
          class="mb-2 custom-error-size"
          *ngIf="
            form.controls.amount.errors && form.controls.amount.errors.required
          "
        >{{ translation.errors.amountRequired }}</mat-error>

        <div class="flex align-items-center">
          <div class="mr-2">Pagar con:</div>
          <button
            mat-raised-button
            (click)="doMercadoPago()"
            [disabled]="form.invalid"
            class="color-white active width-full"
          >
            <img src="/assets/images/mercado-pago.png" />
          </button>
        </div>
      </form>
    </div>
    <div *ngIf="paymentMethod === 'billpocket'" class="width-full">
      <p>{{ translation.minimumPayment }} $500.00</p>
      <form [formGroup]="form" class="mt-4">
        <mat-form-field class="width-full">
          <mat-label> Monto </mat-label>
          <span matPrefix>$&nbsp;</span>
          <input
            matInput
            required
            formControlName="amount"
            name="amount"
            type="number"
          />
        </mat-form-field>

        <div class="flex align-items-center">
          <div class="mr-2">Pagar con:</div>
          <button
            mat-raised-button
            (click)="paymentMethod = 'billpocket'"
            class="color-white active width-full"
          >
            <img src="/assets/images/billpocket.png" />
          </button>
        </div>
      </form>
    </div>
  </div>
</commons-modal-with-content>
