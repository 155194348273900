import { HttpParams } from '@angular/common/http';
import { BaseEntityFields } from './common.model';
import { DatatableMeta, DatatableResponse } from './datatable.model';

export interface Quote {
    zip_to: string;
    zip_from: string;
    parcel: {
        weight: number;
        distance_unit: string;
        mass_unit: string;
        height: number;
        width: number;
        length: number;
    };
}

export interface QuoteAttributes {
    amount_local: string;
    currency_local: string;
    provider: string;
    service_level_name: string;
    service_level_code: string;
    days: number;
    insurable: boolean;
    out_of_area_service: boolean;
    out_of_area_pricing: string;
    total_pricing: string;
}
