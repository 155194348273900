import { Component, Inject, OnInit } from '@angular/core';
import { TutorialSlider } from '@commons/models/index';
import {
  CommonPickupsTranslation,
  CommonPickupsLanguages,
} from '../common-pickups.translation';

@Component({
  selector: 'commons-no-pickups',
  templateUrl: './no-pickups.component.html',
  styleUrls: ['./no-pickups.component.scss'],
})
export class NoPickupsComponent implements OnInit {
  public readonly translation: CommonPickupsTranslation =
    CommonPickupsLanguages[this.language];

  public emptyValues: TutorialSlider[] = [
    {
      text: this.translation.emptyElements[0],
      url: 'assets/images/ordenes_icon_direccion.svg',
    },
    {
      text: this.translation.emptyElements[1],
      url: 'assets/images/guias_icon_click.svg',
    },
    {
      text: this.translation.emptyElements[2],
      url: 'assets/images/ordenes_icon_datos.svg',
    },
  ];

  constructor(
    @Inject('LANGUAGE')
    private readonly language: keyof typeof CommonPickupsLanguages,
  ) {}

  ngOnInit(): void {}
}
