import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  APIRoutes,
  UpdatePasswordPayload,
  UpdateProfilePayload,
  UpdateLabelFormatPayload,
  Profile,
  // PickUpAvailabilityCheck,
  // PickUpAvailabilityChecked,
  // PickupPayload,
} from '@commons/models/index';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ClientProfileService {
  private readonly routes = new APIRoutes();

  constructor(private readonly http: HttpClient) {}

  public getProfile(): Observable<UpdateProfilePayload> {
    return this.http.get<any>(this.routes.Profile.All).pipe(
      map((res: any) => res.data),
      map(({ full_name, contact_number, contact_email, sap_id }) => ({
        full_name,
        contact_number,
        email: contact_email,
        sap_id,
      })),
    );
  }

  public updateProfile(payload: UpdateProfilePayload): Observable<void> {
    return this.http.patch<void>(this.routes.ProfileUpdate.All, payload);
  }

  public updateLabelFormat(payload: UpdateLabelFormatPayload): Observable<void> {
    return this.http.post<void>(this.routes.labelFormatUpdate.All, payload);
  }

  public updatePassword(payload: UpdatePasswordPayload): Observable<void> {
    return this.http.put<void>(this.routes.ProfileUserPassword.All, payload);
  }
}
