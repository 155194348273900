import { BaseEntityFields } from './common.model';
import { DatatableMeta, DatatableResponse } from './datatable.model';

interface ParcelsFormType {
  length: number;
  width: number;
  height: number;
  weight: number;
  quantity: number;
}

interface Parcel {
  weight: number;
  distance_unit: string;
  mass_unit: string;
  height: number;
  width: number;
  length: number;
}

type LabelState = 'loading' | 'success' | 'error';

interface AddressFrom {
  province: string;
  city: string;
  name: string;
  zip: string;
  country: string;
  address1: string;
  company: string;
  address2: string;
  phone: string;
  email: string;
}

type AddressTo = AddressFrom & {
  reference: string;
  contents: string;
};

interface LabelsResponse {
  data: Data;
  included: Included[];
}

interface Included {
  id: string;
  type: 'rates' | 'addresses';
  attributes: AddressAttributes | RateAttributes;
}

interface Data {
  id: string;
  type: string;
  attributes: Attributes;
  relationships: Relationships;
}

interface Relationships {
  parcels: Parcels;
  rates: Parcels;
  address_to: Addressto;
  address_from: Addressto;
  integration: Integration;
  chosen_rate: Integration;
  batch_label: Integration;
  labels: Labels;
}

interface Labels {
  data: any[];
}

export interface Integration {
  data?: any;
}

interface Addressto {
  data: Datum;
}

interface Parcels {
  data: Datum[];
}

interface Datum {
  id: string;
  type: string;
}

interface Attributes {
  status: string;
  created_at: string;
  updated_at: string;
  declared_cost?: any;
}

interface AddressAttributes {
  name: string;
  company: string;
  address1: string;
  address2: string;
  city: string;
  province: string;
  zip: string;
  country: string;
  phone: string;
  email: string;
  created_at: string;
  updated_at: string;
  reference: string;
  province_code: string;
  contents: string;
}

interface RateAttributes {
  created_at: string;
  updated_at: string;
  amount_local: string;
  currency_local: string;
  provider: string;
  service_level_name: string;
  service_level_code: string;
  service_level_terms?: any;
  days: number;
  duration_terms?: any;
  zone?: any;
  arrives_by?: any;
  out_of_area: boolean;
  out_of_area_pricing: string;
  total_pricing: string;
}

interface PlaceShipmentResponse {
  data: PlaceShipmentResponseData;
}

interface PlaceShipmentResponseData {
  id: string;
  type: string;
  attributes: ReadyShipmentLabel;
}

interface ReadyShipmentLabel {
  created_at: string;
  updated_at: string;
  status?: any;
  tracking_number: string;
  tracking_status?: any;
  label_url: string;
  tracking_url_provider: string;
  rate_id: number;
}

interface IntegrationAddress extends BaseEntityFields {
  address1: string;
  address2: string;
  city: string;
  company: string;
  contents: string;
  country: string;
  created_at: string;
  email: string;
  name: string;
  phone: string;
  province: string;
  province_code: string;
  reference: string;
  updated_at: string;
  zip: string;
}

export interface IntegrationShipment extends BaseEntityFields {
  created_at: string;
  updated_at: string;
  declared_cost: number;
  is_international: boolean;
  status: string;
  address_from: IntegrationAddress;
  address_to: IntegrationAddress;
}

export interface DataIntegration extends BaseEntityFields {
  updated_at: string;
  created_at: string;
  customer_email: string;
  customer_fullname: string;
  fulfillment_status: string;
  hidden: boolean;
  shipping: boolean;
  source: string;
  address_from?: IntegrationAddress;
  address_to?: IntegrationAddress;
  shipments?: IntegrationShipment[];
  /*relationships: {
    address_from: IntegrationAddress;
    address_to: IntegrationAddress;
    items: any[];
    shipments: IntegrationShipment;
  };*/
}

export class DatatableIntegrations implements DatatableResponse {
  meta: DatatableMeta;
  data: DataIntegration[];
}
