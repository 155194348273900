import { IPayPalConfig } from 'ngx-paypal';

export const PAYPAL_CONFIG: IPayPalConfig = {
  // todo use env instead
  currency: 'MXN',
  // TODO: Use the environment value
  clientId:
    'AVYe789uaMT3vDzlIi3cu4jRlvgIptaB6OA9h-xpMSL4VSg704JCpNAr1y_wNMZ-RMnQwSLdgrIM-Zjy',
  advanced: {
    // don't be editable the final amount
    commit: 'true'
  },

  style: {
    shape: 'rect',
    color: 'gold',
    layout: 'horizontal',
  },
};
