import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { ClientProfileComponent } from './client-profile.component';
import { MatCardModule } from '@angular/material/card';
import { ClientProfileDataComponent } from './client-profile-data/client-profile-data.component';
import { ClientProfileBillingComponent } from './client-profile-billing/client-profile-billing.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ClientProfileRoutingModule } from './client-profile-routing.module';

@NgModule({
  declarations: [
    ClientProfileComponent,
    ClientProfileDataComponent,
    ClientProfileBillingComponent,
  ],
  exports: [ClientProfileComponent],
  imports: [
    ClientProfileRoutingModule,
    CommonModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatButtonModule,
    MatSelectModule,
    MatInputModule,
    MatSlideToggleModule,
  ],
})
export class ClientProfileModule {}
