import { environment } from './../../../../admin/src/environments/environment.es';
import { Observable } from 'rxjs';

export interface RoutesGroup {
  All?: string;
  Entity?: (id?: number) => string;
  Custom?: (path: string[] | string) => string;
}

const BuildRoutesGroup = (root: string): RoutesGroup => {
  return {
    All: `${root}`,
    Entity: (id: number): string => `${root}/${id}`,
    Custom: (path: string | string[]) =>
      typeof path === 'string'
        ? `${root}/${path}`
        : `${root}/${path.join('/')}`,
  };
};

export class APIRoutes {
  private readonly root =environment.baseUrl + '/api/v1';

  public get ValidateCode(): RoutesGroup {
    return BuildRoutesGroup(`${this.root}/validate_code`);
  }

  public get Companyinfo(): RoutesGroup {
    return BuildRoutesGroup(`${this.root}/companies`);
  }
  public get ResendCode(): RoutesGroup {
    return BuildRoutesGroup(`${this.root}/resend_code`);
  }

  public get AccountDetails(): RoutesGroup {
    return BuildRoutesGroup(`${this.root}/account_detail`);
  }

  public get Addresses(): RoutesGroup {
    return BuildRoutesGroup(`${this.root}/addresses`);
  }

  public get AdminAccounts(): RoutesGroup {
    return BuildRoutesGroup(`${this.root}/admin/account`);
  }

  public get AdminUsers(): RoutesGroup {
    return BuildRoutesGroup(`${this.root}/admin/users`);
  }

  public get AdminOrders(): RoutesGroup {
    return BuildRoutesGroup(`${this.root}/admin/orders`);
  }

  public get Auth(): RoutesGroup {
    return BuildRoutesGroup(`${this.root}/auth`);
  }

  public get BatchLabels(): RoutesGroup {
    return BuildRoutesGroup(`${this.root}/batch_labels`);
  }

  public get ResetPassword(): string {
    return this.root + '/update_password';
  }

  public get RecoverPassword(): string {
    return this.root + '/reset_password';
  }

  public get Credits(): RoutesGroup {
    return BuildRoutesGroup(`${this.root}/credits`);
  }

  public get FiscalDataGet(): RoutesGroup {
    return BuildRoutesGroup(`${this.root}/get_fiscal_data`);
  }

  public get FiscalDataCreate(): RoutesGroup {
    return BuildRoutesGroup(`${this.root}/create_fiscal_data`);
  }

  public get FiscalDataUpdate(): RoutesGroup {
    return BuildRoutesGroup(`${this.root}/update_fiscal_data`);
  }

  public get Orders(): RoutesGroup {
    return BuildRoutesGroup(`${this.root}/orders`);
  }

  public get OrdersSyncing(): RoutesGroup {
    return BuildRoutesGroup(`${this.root}/order_syncing`);
  }

  public get PickupAvailability(): RoutesGroup {
    return BuildRoutesGroup(`${this.root}/pickup_availability`);
  }

  public get Pickups(): RoutesGroup {
    return BuildRoutesGroup(`${this.root}/pickups`);
  }

  public get Exports(): RoutesGroup {
    return BuildRoutesGroup(`${this.root}/exports`);
  }
  public get ExportsG(): RoutesGroup {
    return BuildRoutesGroup(`${this.root}/exports`);
  }

  public get Profile(): RoutesGroup {
    return BuildRoutesGroup(`${this.root}/profile`);
  }

  public get ProfileUpdate(): RoutesGroup {
    return BuildRoutesGroup(`${this.root}/settings/update_profile`);
  }
  public get labelFormatUpdate(): RoutesGroup {
    return BuildRoutesGroup(`${this.root}/settings/update_label_format`);
  }

  public get ProfilePassword(): RoutesGroup {
    return BuildRoutesGroup(`${this.root}/update_password`);
  }

  public get ProfileUserPassword(): RoutesGroup {
    return BuildRoutesGroup(`${this.root}/update_password_user`);
  }

  public get PostalCodes(): RoutesGroup {
    return BuildRoutesGroup(`${this.root}/postal_codes`);
  }

  public get Settings(): RoutesGroup {
    return BuildRoutesGroup(`${this.root}/settings`);
  }

  public get Shipments(): RoutesGroup {
    return BuildRoutesGroup(`${this.root}/shipments`);
  }
  public get ShipmentsAdmin(): RoutesGroup {
    return BuildRoutesGroup(`${this.root}/admin/shipments`);
  }

  public get UserImports(): RoutesGroup {
    return BuildRoutesGroup(`${this.root}/user_imports`);
  }

  public get Invoices(): RoutesGroup {
    return BuildRoutesGroup(`${this.root}/invoice`);
  }

  public get Integrations(): RoutesGroup {
    return BuildRoutesGroup(`${this.root}/store_connections`);
  }

  public get AddressBooks(): RoutesGroup {
    return BuildRoutesGroup(`${this.root}/address_books`);
  }

  public get Templates(): RoutesGroup {
    return BuildRoutesGroup(`${this.root}/package_templates`);
  }

  public get UserRates(): RoutesGroup {
    return BuildRoutesGroup(
      `${this.root}/admin/users/:userid/custom_pricing/:providerid`,
    );
  }

  public get UpdateUserRates(): string {
    return `${this.root}/admin/users/custom_pricing/update_pricings`;
  }

  public get AdminGeneralCustomPricings(): string {
    return `${this.root}/admin/custom_pricing/costs_update_pricings`;
  }

  public get GeneralRates(): RoutesGroup {
    return BuildRoutesGroup(`${this.root}/admin/settings/get_templates`);
  }

  public get UpdateGeneralRates(): string {
    return `${this.root}/admin/settings/update_general_template`;
  }

  public get Cancellations(): RoutesGroup {
    return BuildRoutesGroup(`${this.root}/admin/cancel_requests`);
  }

  public get CancelRequest(): string {
    return `${this.root}/cancel_request`;
  }

  public get Carriers(): string {
    return `${this.root}/settings/get_carriers`;
  }

  public get OtherZones(): string {
    return `${this.root}/other_zones`;
  }

  public get AdminCancelRequest(): string {
    return `${this.root}/admin/cancel_request`;
  }

  public get UpdateCancellations(): string {
    return `${this.root}/admin/update_cancel_request`;
  }

  public get Formats(): string {
    return `${this.root}/formats`;
  }

  public get AdminSettings(): RoutesGroup {
    return BuildRoutesGroup(`${this.root}/admin/settings`);
  }

  public get Labels(): RoutesGroup {
    return BuildRoutesGroup(`${this.root}/labels`);
  }

  public get Quote(): RoutesGroup {
    return BuildRoutesGroup(`${this.root}/quote`);
  }

  public get Memberships(): string {
    
    return  `${this.root}/admin/settings/memberships`;
  
  }

  constructor() {}
}
