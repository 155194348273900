import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APIRoutes } from '@commons/models/index';
import {
  CleanIntegrations,
  IntegrationsResponse,
  StoreForm,
} from '@commons/models/store.model';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CommonIntegrationsService {
  private readonly routes = new APIRoutes();

  public storeToConnect: string;

  /* -------------------------- Workflow of the steps ------------------------- */
  public readonly continueButton$ = new Subject<any>();
  public readonly click$ = new Subject<any>();
  public readonly goToNextStep$ = new Subject<void>();

  constructor(
    private readonly http: HttpClient,
    @Inject('LANGUAGE')
    private readonly language: 'ES' | 'EN',
  ) {}

  /* ----------------------------------- API ---------------------------------- */

  public getStores(): Observable<CleanIntegrations> {
    return this.http
      .get<IntegrationsResponse>(this.routes.Integrations.All)
      .pipe(
        map(({ load_connections, available_connections }) => ({
          synced: load_connections.map((store) => {
            if (!store.last_synced_at) {
              return store;
            }
            const lastSynced = new Date(store.last_synced_at);
            return {
              ...store,
              last_synced_at: `${lastSynced.toLocaleDateString(
                [this.language],
                {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                },
              )}`,
            };
          }),
          available: available_connections.map((store: string) => ({
            id: store,
            name: store,
            disabled: load_connections.some(
              (connection) => store === connection.provider,
            ),
          })),
        })),
      );
  }

  public activateStore(data: StoreForm): Observable<any> {
    return this.http.post<any>(
      this.routes.Integrations.Custom(
        `activate?source_type=${this.storeToConnect}`,
      ),
      data,
    );
  }

  public confirmStore({
    code,
    source_type,
  }: {
    code: string;
    source_type: string;
  }): Observable<void> {
    const params = new HttpParams()
      .append('code', code)
      .append('source_type', source_type);
    return this.http.get<void>(this.routes.Integrations.Custom('confirm'), {
      params,
    });
  }

  public deactivateStore(index: number): Observable<any> {
    return this.http.delete(this.routes.Integrations.Custom(index.toString()));
  }

  /* -------------------------------------------------------------------------- */

  public initializeStoreConnection(storeId: string): void {
    this.storeToConnect = storeId;

    this.goToNextStep$.next();
  }

  /**
   * Handler for when the main form button is clicked
   */
  public clickButton(): void {
    this.click$.next(null);
  }
}
