import { Component, Input, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MenuItem } from '@commons/models/index';

@Component({
  selector: 'commons-layout-menu-item',
  templateUrl: './layout-menu-item.component.html',
  styleUrls: ['./layout-menu-item.component.scss'],
})
export class LayoutMenuItemComponent implements OnInit {
  @Input() item: MenuItem;
  @Input() small: boolean;

  public isOpen = false;
  currentUl = '';

  public get hasChildren(): boolean {
    return !!(this.item.children && this.item.children.length);
  }

  constructor(
    private readonly router: Router,
  ) {
    this.currentUl = router.url;  
    }
  
  ngOnInit(): void {
    this.router.events
          .subscribe((event: any) => 
           {
              // this.currentUl = event.value;          
              this.currentUl = event.urlAfterRedirects;
              // console.log(event.urlAfterRedirects);
           });
  }

  public showChildren(show: boolean): void {
    this.isOpen = show;
  }

  public clickAction(item: MenuItem): void {
    if (item.url) {
      // this.router.navigate([item.url]);
    } else {
      this.isOpen = !this.isOpen;
    }
  }
}
