import { HttpParams } from '@angular/common/http';
import { BaseEntityFields } from './common.model';
import { DatatableResponse, DatatableMeta } from './datatable.model';
import * as moment from 'moment';

export interface BalanceListItem extends BaseEntityFields {
  amount: string;
  comment: string;
  created_at: string;
  invoiceable: boolean;
  source: string;
  updated_at: string;
}

export interface NewBillings {
  data: {
    amount: string;
    category: string;
    comment: string;
    created_at: string;
    id: number;
    invoiceable: boolean;
    source: string;
    updated_at: string;
  }[];
  message: string;
  succes: boolean;
}

export interface Invoice {
  amount: string;
  business_name: string;
  messages: string;
  payment_way: string;
  status: string;
  tax_id: string;
}

export class DatatableBalances implements DatatableResponse {
  meta: DatatableMeta;
  data: BalanceListItem[];
}
export class DatatableInvoices implements DatatableResponse {
  meta: DatatableMeta;
  data: DatatableBillingItem[];
}

export interface BillingTransaction {
  account_id: number;
  amount: string;
  category: string;
  code: string;
  comment: string;
  created_at: string;
  extra: { account: string; mercadopago: string };
  fired_by: { identifier: string };
  id: number;
  invoice_id: number;
  invoiceable: true;
  token: string;
  updated_at: string;
}

export interface DatatableBillingItem {
  account_transactions: BillingTransaction;
  accounted_at: string;
  amount: string;
  created_at: string;
  external_reference: number;
  fiscal_data_id: number;
  headquarter_id: number;
  id: number;
  invoice_file: string;
  messages: string;
  status: string;
  updated_at: string;
}

export interface BillingInfoPayload {
  business_name: string;
  tax_id: string;
  tax_regime: string;
  payment_way: string;
  street1: string;
  street2: string;
  zip: string;
  city: string;
  province: string;
  country: string;
  phone: string;
  email: string;
  // headquarter_id: string;
}

export interface BillingInfoResponse {
  automatic_invoice: number | string;
  data: BillingInfoPayload;
}

export interface BalanceBillingSearchParams {
  q: string;
  type: string;
  from: string;
  to: string;
}

export const ParseBalanceParams = (
  page: number,
  { q, from, to, type }: BalanceBillingSearchParams,
): HttpParams => {
  let params = new HttpParams();

  if (q) {
    params = params.set('q', q);
  }
  if (type) {
    params = params.set('type', type);
  }

  if (from ) {
    params = params.set('from', moment(from).format('DD/MM/YYYY'));
    
  }
  if (to) {
    
    params = params.set('to', moment(to).format('DD/MM/YYYY'));
  }

  if (page && page !== 1) {
    params = params.set('page', page.toString());
  }

  return params;
};

export const ParseInvoiceParams = (
  page: number,
  { q, from, to, type }: BalanceBillingSearchParams,
): HttpParams => {
  let params = new HttpParams();

  if (q) {
    params = params.set('q', q);
  }
  if (type) {
    params = params.set('type', type);
  }

  if (from && to) {
    params = params.set('from', moment(from).format('DD/MM/YYYY'));
    params = params.set('to', moment(to).format('DD/MM/YYYY'));
  }
  if (page && page !== 1) {
    params = params.set('page', page.toString());
  }

  return params;
};
