import { Component } from '@angular/core';
import { ConfirmationModalService } from './confirmation-modal.service';

@Component({
  selector: 'commons-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent {
  public data: ConfirmationModal;

  constructor(
    private readonly confirmationModalService: ConfirmationModalService,
  ) {
    this.confirmationModalService.createModal
      .asObservable()
      .subscribe((data) => {
        this.data = data;
      });
  }

  public setResponse(isOk: boolean): void {
    this.confirmationModalService.setResponse(isOk);
  }

  public handleBackgroundClick(): void {
    this.confirmationModalService.close();
  }
}

export type ConfirmationModal = {
  title: string;
  description: string;
  okMessage?: string;
  cancelMessage?: string;
  showCancelButton?: boolean;
};
