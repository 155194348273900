import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'commons-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss'],
})
export class DropdownMenuComponent implements OnInit {
  public isOpen = false;

  constructor() {}

  ngOnInit(): void {}

  public onClickOutside(): void {
    this.isOpen = false;
  }

  public onToggle(): void {
    this.isOpen = !this.isOpen;
  }
}
